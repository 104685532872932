import * as Yup from "yup";

export const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      // .min(6, "Password is Too Short! it must be between 8 characters")
      // .max(16, "Password is Too Long! it must be between 8 characters")
      //  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase and one lowercase.')
      // .matches(/^[a-zA-Z0-9\W\S]{6,16}$/, 'atleast contain six charachters.')
        .matches(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,"Password must contain at least 8 characters, one uppercase, one lower case, and one number")
      .required("Password Required"),
  
      confirm_password: Yup.mixed().oneOf(
      [Yup.ref('password')], 'Password do not match!'
      )
      .required("Confirm Password Required"),
  })