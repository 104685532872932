import { put, takeLatest, cancelled } from "redux-saga/effects";

import {
  SEND_REPORT_REQUEST,
  CHART_OF_ACCOUNTS_REQUEST,
  BANK_ACCOUNT_LEDGER_REQUEST,
  TRIAL_BALANCE_SUMMARY_REQUEST,
  PROFIT_AND_LOSS_REQUEST,
  TRANSFER_JOURNAL_LEDGER_REQUEST,
  RECONCILIATION_FOR_LAWYERS_REQUEST,
  CHART_REPORT_REQUEST,
} from "../configurations/Types";

import {
  REPORTS_ENDPOINT,
  CHARTS_ENDPOINT,
  SEND_REPORT_ENDPOINT,
  CHART_OF_ACCOUNTS_ENDPOINT,
  BANK_ACCOUNT_LEDGER_ENDPOINT,
  TRIAL_BALANCE_SUMMARY_ENDPOINT,
  PROFIT_AND_LOSS_ENDPOINT,
  TRANSFER_JOURNAL_LEDGER_ENDPOINT,
  RECONICILIATION_FOR_LAWYERS_ENDPOINT,
  CHART_REPORT_ENDPOINT,
} from "../configurations/Constants";

import {
  sendReportSuccess,
  sendReportError,
  chartOfAccountsSuccess,
  chartOfAccountsError,
  bankAccountLedgerSuccess,
  bankAccountLedgerError,
  trialBalanceSummarySuccess,
  trialBalanceSummaryError,
  profitAndLossSuccess,
  profitAndLossError,
  transferJournalLedgerSuccess,
  transferJournalLedgerError,
  reconciliationForLawyersSuccess,
  reconciliationForLawyersError,
  chartReportSuccess,
  chartReportError,
} from "../actions/Reports";

import moment from "moment";
import info from "../components/message/index";

import { privateAgent, CancelToken } from "../configurations/AxiosAgent";

// -- Send Report
let sendReportToken;

const sendReportAPI = (body, token) =>
  privateAgent.post(`${REPORTS_ENDPOINT}${SEND_REPORT_ENDPOINT}`, body);

function* sendReport(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (sendReportToken) {
      sendReportToken.cancel();
    }
    sendReportToken = CancelToken.source();

    let { file, ...body } = action.body;

    res = yield sendReportAPI(body, sendReportToken);

    console.log("USER API GET", res.data);

    yield put(sendReportSuccess(res.data.data));
    info("success", `${res.data.data.message}`);

    if (action.cb) {
      action.cb(res.data.data);
    }
  } catch (e) {
    console.log("ERROR", e);

    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(sendReportError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

// -- Get Chart of Accounts
let chartOfAccountsToken;

const chartOfAccountsAPI = (params, token) =>
  privateAgent.get(`${REPORTS_ENDPOINT}${CHART_OF_ACCOUNTS_ENDPOINT}`, {
    cancelToken: token.token,
    params: params,
  });

function* chartOfAccounts(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (chartOfAccountsToken) {
      chartOfAccountsToken.cancel();
    }
    chartOfAccountsToken = CancelToken.source();

    res = yield chartOfAccountsAPI(action.params, chartOfAccountsToken);

    console.log("USER API GET", res.data);

    yield put(chartOfAccountsSuccess(res.data.data));

    if (action.cb) {
      action.cb(res.data.data);
    }
  } catch (e) {
    console.log("ERROR", e.response);

    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(chartOfAccountsError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

// -- Get Bank Account Ledger
let bankAccountLedgerToken;

const bankAccountLedgerAPI = (params, token) =>
  privateAgent.get(`${REPORTS_ENDPOINT}${BANK_ACCOUNT_LEDGER_ENDPOINT}`, {
    cancelToken: token.token,
    params: params,
  });

function* bankAccountLedger(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (bankAccountLedgerToken) {
      bankAccountLedgerToken.cancel();
    }
    bankAccountLedgerToken = CancelToken.source();

    let { quarter, start_date, end_date, ...params } = action.params;

    res = yield bankAccountLedgerAPI(params, bankAccountLedgerToken);

    console.log("USER API GET", res.data);

    yield put(bankAccountLedgerSuccess(res.data.data));

    if (action.cb) {
      action.cb(res.data.data);
    }
  } catch (e) {
    console.log("ERROR", e.response);

    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(bankAccountLedgerError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

// -- Get Trial Balance Summary
let trialBalanceSummaryToken;

const trialBalanceSummaryAPI = (params, token) =>
  privateAgent.get(`${REPORTS_ENDPOINT}${TRIAL_BALANCE_SUMMARY_ENDPOINT}`, {
    cancelToken: token.token,
    params: params,
  });

function* trialBalanceSummary(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (trialBalanceSummaryToken) {
      trialBalanceSummaryToken.cancel();
    }
    trialBalanceSummaryToken = CancelToken.source();

    res = yield trialBalanceSummaryAPI(action.params, trialBalanceSummaryToken);

    console.log("USER API GET", res.data);

    yield put(trialBalanceSummarySuccess(res.data.data));

    if (action.cb) {
      action.cb(res.data.data);
    }
  } catch (e) {
    console.log("ERROR", e.response);

    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(trialBalanceSummaryError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

// -- Get Profit and Loss
let profitAndLossToken;

const profitAndLossAPI = (params, token) =>
  privateAgent.get(`${REPORTS_ENDPOINT}${PROFIT_AND_LOSS_ENDPOINT}`, {
    cancelToken: token.token,
    params: params,
  });

function* profitAndLoss(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (profitAndLossToken) {
      profitAndLossToken.cancel();
    }
    profitAndLossToken = CancelToken.source();

    res = yield profitAndLossAPI(action.params, profitAndLossToken);

    console.log("USER API GET", res.data);

    yield put(profitAndLossSuccess(res.data.data));

    if (action.cb) {
      action.cb(res.data.data);
    }
  } catch (e) {
    console.log("ERROR", e.response);

    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(profitAndLossError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

// -- Get Transfer Journal Ledger
let transferJournalLedgerToken;

const transferJournalLedgerAPI = (params, token) =>
  privateAgent.get(`${REPORTS_ENDPOINT}${TRANSFER_JOURNAL_LEDGER_ENDPOINT}`, {
    cancelToken: token.token,
    params: params,
  });

function* transferJournalLedger(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (transferJournalLedgerToken) {
      transferJournalLedgerToken.cancel();
    }
    transferJournalLedgerToken = CancelToken.source();

    res = yield transferJournalLedgerAPI(
      action.params,
      transferJournalLedgerToken
    );

    console.log("USER API GET", res.data);

    yield put(transferJournalLedgerSuccess(res.data.data));

    if (action.cb) {
      action.cb(res.data.data);
    }
  } catch (e) {
    console.log("ERROR", e.response);

    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(transferJournalLedgerError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

// -- Get Reconciliation for Lawyers
let reconciliationForLawyersToken;

const reconciliationForLawyersAPI = (params, token) =>
  privateAgent.get(
    `${REPORTS_ENDPOINT}${RECONICILIATION_FOR_LAWYERS_ENDPOINT}`,
    {
      cancelToken: token.token,
      params: params,
    }
  );

function* reconciliationForLawyers(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (reconciliationForLawyersToken) {
      reconciliationForLawyersToken.cancel();
    }
    reconciliationForLawyersToken = CancelToken.source();

    res = yield reconciliationForLawyersAPI(
      action.params,
      reconciliationForLawyersToken
    );

    console.log("USER API GET", res.data);

    yield put(reconciliationForLawyersSuccess(res.data.data));

    if (action.cb) {
      action.cb(res.data.data);
    }
  } catch (e) {
    console.log("ERROR", e.response);

    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(reconciliationForLawyersError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

// -- Get Chart Report
let chartReportToken;

const chartReportAPI = (params, token) =>
  privateAgent.get(`${CHARTS_ENDPOINT}${CHART_REPORT_ENDPOINT}`, {
    cancelToken: token.token,
    params: params,
  });

function* chartReport(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (chartReportToken) {
      chartReportToken.cancel();
    }
    chartReportToken = CancelToken.source();

    res = yield chartReportAPI(action.params, chartReportToken);

    console.log("USER API GET", res.data);

    yield put(chartReportSuccess(res.data.data));

    if (action.cb) {
      action.cb(res.data.data);
    }
  } catch (e) {
    console.log("ERROR", e.response);

    if (e.response) {
      //info("error", `${e.response.data.error.message}`);
    }

    yield put(chartReportError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

// Our watcher Saga: watches for report related actions
export function* watchReports() {
  yield takeLatest(SEND_REPORT_REQUEST, sendReport);
  yield takeLatest(CHART_OF_ACCOUNTS_REQUEST, chartOfAccounts);
  yield takeLatest(BANK_ACCOUNT_LEDGER_REQUEST, bankAccountLedger);
  yield takeLatest(TRIAL_BALANCE_SUMMARY_REQUEST, trialBalanceSummary);
  yield takeLatest(PROFIT_AND_LOSS_REQUEST, profitAndLoss);
  yield takeLatest(TRANSFER_JOURNAL_LEDGER_REQUEST, transferJournalLedger);
  yield takeLatest(
    RECONCILIATION_FOR_LAWYERS_REQUEST,
    reconciliationForLawyers
  );
  yield takeLatest(CHART_REPORT_REQUEST, chartReport);
}
