import React from "react";
import { connect } from "react-redux";

//import { Modal } from 'antd';
import { StyledContent } from "../maincontent/index";
import BasicModal from "./Basic";
import styled from "styled-components";

class RootModal extends React.Component {
  modalComponent = {
    basic_modal: BasicModal,
  };

  render() {
    if (!this.props.modals.length > 0) {
      return null;
    }
    const ModalsToRender = this.props.modals.map((val, ind) => {
      let ModalToRender = this.modalComponent[val.modalType];
      return (
        <ModalToRender
          key={ind}
          className={this.props.className}
          {...val.modalProps}
        />
      );
    });
    return <div>{ModalsToRender.map((val) => val)}</div>;
  }
}

const StyledModal = styled(RootModal)`
  ${StyledContent}
  width: 35vw !important;
  margin: 2.5vw 0;
  padding: 0;
  min-height: auto;
  border-radius: 10px;
  color: ${(props) => props.theme[props.theme.mode].textColor.primary};

  @media screen and (max-width: 991px) {
    & {
      width: 65vw !important;
      margin: 0;
    }
  }
  @media screen and (max-width: 767px) {
    & {
      background-color: transparent;
      width: 100% !important;
    }
  }

  & .ant-modal-content i {
    color: ${(props) => props.theme[props.theme.mode].textColor.primary};
  }
  & .ant-modal-content button.button i {
    color: ${(props) => props.theme[props.theme.mode].common.white};
  }
  & .ant-modal-header {
    background: ${(props) => props.theme[props.theme.mode].background.tertiary};
    color: ${(props) => props.theme[props.theme.mode].textColor.primary};
    border-color: ${(props) => props.theme[props.theme.mode].border};
  }

  & .ant-modal-title {
    color: ${(props) => props.theme[props.theme.mode].textColor.primary};
  }

  & .ant-modal-body {
    padding: 5%;
    border-radius: 5px;
  }

  & .ant-modal-body,
  .ant-modal-footer {
    background: ${(props) => props.theme[props.theme.mode].background.primary};
    border-color: ${(props) => props.theme[props.theme.mode].border};
  }
`;

const ModalWrapper = (props) => {
  return <StyledModal {...props}></StyledModal>;
};

export default connect((state) => state.Modal)(ModalWrapper);
