import React from "react";
import { DatePicker } from "antd";
import styled, { css } from "styled-components";

const { MonthPicker } = DatePicker;

const MonthPickerStyles =
  //styled(MonthPicker).attrs(() => ({ size: "large" }))
  css`
    //   color: palevioletred;
    //   font-weight: bold;
    //   box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);

    &:hover input {
      border: 1px solid ${(props) => props.theme[props.theme.mode].primary.main};
      border-color: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
    }

    input {
      background-color: ${(props) =>
        props.theme[props.theme.mode].background.secondary};
      color: ${(props) => props.theme[props.theme.mode].textColor.primary};
      border: 1px solid
        ${(props) => props.theme[props.theme.mode].primary.main}66;
      //${(props) => (props.appStyles ? props.appStyles : null)};
      // padding-top: 18px;
      // padding-bottom: 18px;

      padding: calc(0.7vw + 1px) 0.8vw;
    }

    input:hover {
      border: 1px solid ${(props) => props.theme[props.theme.mode].primary.main};
      border-color: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
    }

    input:focus {
      border: 1px solid ${(props) => props.theme[props.theme.mode].primary.main};
      border-color: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
      box-shadow: 0 0 0 2px
        ${(props) => props.theme[props.theme.mode].primary.main}66;
    }

    .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
      color: ${(props) =>
        props.theme[props.theme.mode].textColor.inverted} !important;
      background: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
    }

    .ant-calendar-month-panel-cell[class="ant-calendar-month-panel-cell"]
      .ant-calendar-month-panel-month:hover,
    .ant-calendar-month-panel-current-cell:not(.ant-calendar-month-panel-selected-cell)
      .ant-calendar-month-panel-month:hover {
      color: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
      background: ${(props) =>
        props.theme[props.theme.mode].primary.light} !important;
    }

    .ant-calendar-month-panel-year-select:hover {
      color: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
    }

    .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
      color: ${(props) =>
        props.theme[props.theme.mode].textColor.inverted} !important;
      background: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
    }

    .ant-calendar-year-panel-cell:not(.ant-calendar-year-panel-selected-cell)
      .ant-calendar-year-panel-year:hover {
      color: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
      background: ${(props) =>
        props.theme[props.theme.mode].primary.light} !important;
    }

    .ant-calendar-year-panel-decade-select:hover {
      color: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
    }

    .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
      color: ${(props) =>
        props.theme[props.theme.mode].textColor.inverted} !important;
      background: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
    }

    .ant-calendar-decade-panel-cell:not(.ant-calendar-decade-panel-selected-cell)
      .ant-calendar-decade-panel-decade:hover {
      color: ${(props) =>
        props.theme[props.theme.mode].primary.main} !important;
      background: ${(props) =>
        props.theme[props.theme.mode].primary.light} !important;
    }

    svg {
      color: ${(props) => props.theme[props.theme.mode].primary.main};
    }

    @media screen and (max-width: 991px) {
      input {
        padding: 0.5rem;
      }
    }
  `;

// input {
//   padding: calc(0.7vw + 1px) 0.8vw;
// }

// @media screen and (max-width: 991px) {
//   input {
//     padding: calc(.5rem + 1px);
//   }
// }

const MonthPickerWrapper = (props) => {
  return <MonthPicker dropdownClassName={props.className} {...props} />;
};
export default styled(MonthPickerWrapper).attrs({
  className: "inputField",
})`
  ${MonthPickerStyles}
`;

// cb funtion recieverd from props to get the selected date
// function onChange(date, dateString) {
//   console.log(date, dateString);
// }
