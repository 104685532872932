import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import moment from "moment";
import ResponsiveContainerWrapper from "../../../components/charts/ResponsiveContainer";
import LineChart from "../../../components/charts/LineChart";
import PieChart from "../../../components/charts/PieChart";
import ComposedChart from "../../../components/charts/ComposedChart";
import CardWrapper from "../../../components/card";
import MonthPickerWrapper from "../../../components/datepickers/MonthPicker";
import RangePickerWrapper from "../../../components/datepickers/RangePicker";
import UserStatCard from "../../../appcomponents/shares/Dashboard/UserStatCard";
import HorizontalStatCard from "../../../appcomponents/shares/Dashboard/HorizontalStatCard";
import UsersIcon from "../../../assets/icons/dashboard/user-line2.svg";
import TravellersIcon from "../../../assets/icons/dashboard/user-star-line.svg";
import UserUnfllowLine from "../../../assets/icons/dashboard/user-unfollow-line.svg";
import Dollar from "../../../assets/icons/dashboard/dollar-symbol.svg";
import PendingOrdersIcon from "../../../assets/icons/dashboard/checkbox-multiple-blank.svg";
import CompletedOrdersIcon from "../../../assets/icons/dashboard/checkbox-multiple.svg";
import ExpiredUsersIcon from "../../../assets/icons/dashboard/checkbox-indeterminate.svg";
//import UserBannedIcon from "../../../assets/icons/user-banned-line.svg";

import {
  userStatsResquest,
  gameStatsResquest,
  earningStatsResquest,
  wagersStatsResquest,
  monthlyUserStatsRequest,
  appDownloadsRequest,
  getSubscribedUsers
} from "../../../actions/Dashboard";

class Dashboard extends React.Component {
  state = {
    currentMonth: moment(),
    // selectedMonth: moment(),
    // monthPickerOpen: false,
    selectedMonthRange: [moment(), moment()],
    // dateHasChanged: false,
    userStatsLoading: false,
    // gameStatsLoading: false,
    // monthlyUserStatsLoading: false,
    appDownloadsLoading: false,
    // earningStatsLoading: false,
    // wagersStatsLoading: false,
    // earningFilter: "daily",
    // wagersFilter: "daily",
    ScreenWidth: window.innerWidth,
  };
  componentDidMount() {
    this.props.dispatch(
      getSubscribedUsers()
    )
    this.setState({
      userStatsLoading: true,
      // gameStatsLoading: true,
      // monthlyUserStatsLoading: true,
      appDownloadsLoading: true,
      // earningStatsLoading: true,
      // wagersStatsLoading: true,
    });
    this.props.dispatch(
      userStatsResquest((res) => {
        this.setState({ userStatsLoading: false });
      })
    );
    // this.props.dispatch(
    //   gameStatsResquest((res) => {
    //     this.setState({ gameStatsLoading: false });
    //   })
    // );
    // this.props.dispatch(
    //   earningStatsResquest(this.state.earningFilter, (res) => {
    //     this.setState({ earningStatsLoading: false });
    //   })
    // );
    // this.props.dispatch(
    //   wagersStatsResquest(this.state.wagersFilter, (res) => {
    //     this.setState({ wagersStatsLoading: false });
    //   })
    // );
    // this.props.dispatch(
    //   monthlyUserStatsRequest(
    //     this.state.selectedMonth.month() + 1,
    //     this.state.selectedMonth.year(),
    //     (res) => {
    //       this.setState({ monthlyUserStatsLoading: false });
    //     }
    //   )
    // );
    // let startMonth = this.state.selectedMonthRange[0].month() + 1;
    // let startYear = this.state.selectedMonthRange[0].year();
    // let endMonth = this.state.selectedMonthRange[1].month() + 1;
    // let endYear = this.state.selectedMonthRange[1].year();
    this.props.dispatch(
      appDownloadsRequest("", "", (res) => {
        this.setState({ appDownloadsLoading: false,  });
      })
    );
  }
  
  handleWidthChange = () => {
    this.setState({
      ScreenWidth: window.innerWidth,
    });
  };

  handlePanelChange = (value, mode) => {
    let startDate = moment(value[0]._d).format("YYYY-MM-DD");
    let endDate = moment(value[1]._d).format("YYYY-MM-DD");
    //Starting month was selected
    this.props.dispatch(
      appDownloadsRequest(startDate, endDate, (res) => {
        this.setState({ appDownloadsLoading: false, selectedMonthRange:value });
      })
    );
  };

  // handleChange = (value) => {
  //   console.log("handleChange-CHANGE", value);
  //   this.setState({ value });
  // };

  renderFunction = () => {
    let size = this.state.ScreenWidth;
    console.log('dashboard_props', this.props);
    const {dashboardStats,appDownloads} = this.props?.dashboardStats
    
    const data = [
      {
        name: "Subscribed Users",
        value: dashboardStats?.totalSubscribedUsers || 0,
      },
      { name: "Free Users", value: dashboardStats?.totalFreeUsers || 0 },
      { name: "Banned Users", value: dashboardStats?.totalBannedUsers || 0 },
    ];

    const lineChartData = [
      { name: "Page A", uv: 3000, pv: 2400, amt: 2400 },
      { name: "Page B", uv: 2800, pv: 1398, amt: 2210 },
      { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
      { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
    ];
    // console.log("Pie State", dashboardStats.monthlyUserStats);
  
    return (
      <React.Fragment>
        <h3>
          <b>Dashboard</b>
        </h3>
        <Row
          // type="flex"
          // // align="top"
          // justify="start"

          gutter={[
            { xs: 8, sm: 14, md: 14, lg: 14 },
            { xs: 8, sm: 14, md: 14, lg: 14 },
          ]}
          style={{ textAlign: "left", marginTop: "10px" }}
        >
          <Col span={24}>
            <Row
              type="flex"
              // align="top"
              justify="start"
              gutter={[
                { xs: 8, sm: 14, md: 14, lg: 14 },
                { xs: 8, sm: 14, md: 14, lg: 14 },
              ]}
              style={{ textAlign: "left" }}
            >
              <Col className="divForDash" xs={24} sm={24} md={12} lg={6}>
                <HorizontalStatCard
                  loading={this.state.userStatsLoading}
                  icon={UsersIcon}
                  title="Subscribed Users"
                  amount={
                    dashboardStats?.totalSubscribedUsers || 0
                  }
                />
              </Col>
              <Col className="divForDash" xs={24} sm={24} md={12} lg={6}>
                <HorizontalStatCard
                  loading={this.state.userStatsLoading}
                  icon={TravellersIcon}
                  title="Free Users"
                  amount={
                    dashboardStats?.totalFreeUsers || 0
                  }
                />
              </Col>
              <Col className="divForDash" xs={24} sm={24} md={12} lg={6}>
                <HorizontalStatCard
                  loading={this.state.userStatsLoading}
                  icon={UserUnfllowLine}
                  title="Banned Users"
                  amount={
                    dashboardStats?.totalBannedUsers || 0
                  }
                />
              </Col>
              <Col className="divForDash" xs={24} sm={24} md={12} lg={6}>
                <HorizontalStatCard
                  loading={this.state.userStatsLoading}
                  icon={Dollar}
                  title="Total conversions"
                  amount={
                    dashboardStats?.totalConversions || 0
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "33px",
            }}
            // className="mainContentDiv mr-t-md"
            xs={24}
            sm={24}
            md={12}
            lg={12}
          >
            <Row type="flex" align="middle" gutter={20}>
              <Col className="mr-b">
                <h3
                  style={{ display: "inline-block", fontWeight: "bold" }}
                  className="no-mr"
                >
                  Users
                </h3>
              </Col>
              <Col className="mr-b">
                {/* <MonthPickerWrapper
                  value={this.state.selectedMonth}
                  style={{ width: "100%" }}
                  allowClear={false}
                  className="no-mr"
                  mode="month"
                  disabledDate={(currentDate) =>
                    currentDate.isAfter(this.state.currentMonth, "month")
                  }
                  format={"MMM - YYYY"}
                  onChange={(val) => {
                    if (val && !this.state.selectedMonth.isSame(val, "month")) {
                      this.props.dispatch(
                        monthlyUserStatsRequest(
                          val.month() + 1,
                          val.year(),
                          (res) => {
                            this.setState({ monthlyUserStatsLoading: false });
                          }
                        )
                      );
                      this.setState({
                        selectedMonth: val,
                        monthlyUserStatsLoading: true,
                      });
                    }
                  }}
                /> */}
              </Col>
            </Row>
            <CardWrapper>
              {/* <div style={{ position: "absolute", top: 14, left: 14 }}>
                Total Users:{" "}
                <span className="appColor">
                  {this.props.dashboardStats.userCount}
                </span>
              </div> */}
              <ResponsiveContainerWrapper
                style={{ margin: "27px 0px" }}
                height={size >= 992 ? 500 : 380}
              >
                {dashboardStats ? (
                  // .map((val) => val.value)
                  // .reduce((total, value) => total + value, 0) > 0 ?
                  <PieChart
                    // width={600}
                    // height={600}
                    // pieColor={["#AD1E23", "#E54B55", "#F8604E"]}
                    pieColor={["#B4D862", "#C3F64D", "#151D30"]}
                    // data={dashboardStats.monthlyUserStats}
                    // data={dashboardStats.monthlyUserStats}
                    data={data}
                    //loading={this.state.monthlyUserStatsLoading}
                    //data={pieCharts}
                    legendType="circle"
                    isLegend={true}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    No data available for this month!
                  </h4>
                )}
              </ResponsiveContainerWrapper>
            </CardWrapper>
          </Col>
          <Col
            style={{ display: "flex", flexDirection: "column" }}
            className="mainContentDiv mr-t-md"
            xs={24}
            sm={24}
            md={12}
            lg={12}
          >
            <Row type="flex" align="middle" gutter={20}>
              <Col className="mr-b">
                <h3
                  style={{ display: "inline-block", fontWeight: "bold" }}
                  className="no-mr"
                >
                  App Downloads
                </h3>
              </Col>
              <Col
                className="mr-b rangePickerWidth"
                // xs={24}
                // md={24}
                // lg={12}
                // xl={9}
                // xxl={7}
              >
                <RangePickerWrapper
                  borderRadius={`9px`}
                  border={`1px solid #AD1E23`}
                  allowClear={false}
                  style={{ minWidth: "15rem", maxWidth: "15rem" }}
                  className="no-mr"
                  mode={["month", "month"]}
                  format={"MMM - YYYY"}
                  // renderExtraFooter={
                    // this.state.rangePickerError
                    //   ? () => (
                    //       <span className="dangerColor">
                    //         {this.state.rangePickerError}
                    //       </span>
                    //     )
                    //   : null
                    // false
                  // }
                  value={this.state.selectedMonthRange}
                  placeholder={["Select Month", "Select Month"]}
                  onPanelChange={(e)=>this.handlePanelChange(e)}
                //   onOpenChange={
                //     (open) => {
                //     this.setState({ rangePickerError: null });
                //     if (!open && this.state.dateHasChanged) {
                //       let startMonth =
                //         this.state.selectedMonthRange[0].month() + 1;
                //       let startYear = this.state.selectedMonthRange[0].year();
                //       let endMonth =
                //         this.state.selectedMonthRange[1].month() + 1;
                //       let endYear = this.state.selectedMonthRange[1].year();
                //       this.props.dispatch(
                //         appDownloadsRequest(
                //           startMonth,
                //           startYear,
                //           endMonth,
                //           endYear,
                //           (res) => {
                //             this.setState({ appDownloadsLoading: false });
                //           }
                //         )
                //       );
                //       this.setState({
                //         dateHasChanged: false,
                //         appDownloadsLoading: true,
                //       });
                //     }
                //   }
                // }
                  // onChange={(e)=>this.handleChange(e)}
                />
              </Col>
            </Row>
            <CardWrapper>
              {/* <div style={{ position: "absolute", top: 14, left: 14 }}>
                Total Downloads:{" "}
                <span className="appColor">
                  {dashboardStats.appDownloads.reduce(
                    (total, val) => total + val.Downloads,
                    0
                  )}
                </span>
              </div> */}
              <ResponsiveContainerWrapper
                style={{ margin: "27px 0px" }}
                height={size >= 992 ? 500 : 380}
              >
                {appDownloads ? (
                  // .map((val) => val.Downloads)
                  // .reduce((total, value) => total + value, 0) > 0 ?
                  <ComposedChart
                    data={
                      appDownloads||{}
                    }
                    lineColor={["green", "blue", "orange"]}
                    loading={this.state.appDownloadsLoading}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    No data available for this month range!
                  </h4>
                )}
              </ResponsiveContainerWrapper>
            </CardWrapper>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  render() {
    return this.renderFunction();
  }
}

export default connect((storeState) => (
  {
  dashboardStats: storeState.Dashboard,
  userStats: storeState.Dashboard.userStats,
}))(Dashboard);
