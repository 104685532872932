import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector  } from "react-redux";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { loginValidations } from "../../configurations/Schemas/index";

import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import { Row, Col } from "antd";
import { loginRequest } from "../../actions/Auth";

const LoginScreen = (props) => {

  const  [state, setState ] = useState({
    email: "",
    password: "",
  });
  
  const history = useHistory();

  const data = useSelector((state) => {
    return console.log('LoginScreen_Selector_state',state);
  });

  useEffect(() => {
    if (localStorage.getItem("revicebeat_adminauth")) {
      history.push("/");
    }
  }, []);


  const onHandleChanged = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  
  return (
    <AuthContainer withImage>
      <Row type="flex" justify="center" align="middle" gutter={[10, 30]}>
        <Col span={24}>
          <h3 className="mr-b mr-t">Welcome</h3>
          <p style={{ fontWeight: "600" }}>Please login to continue</p>
        </Col>
        <Col xs={24} sm={22}>
          <RootForm
            schemaName={loginValidations}
            initialValues={state}
            apiCall={loginRequest}
            extras={(email) =>
              history.push({
                pathname: "/signup",
                state: { fromVerify: true, email },
              })
            }
            loading={data?.Auth?.loginLoading}
            cb={() => {
              history.push("/");
            }}
            controls={[
              {
                span: 24,
                name: "email",
                value: state.email,
                handleChanged: onHandleChanged,
                placeholder: "Email",
                Type: Input,
                border: "1px solid #383083",
              },
              {
                span: 24,
                name: "password",
                value: state.password,
                handleChanged: onHandleChanged,
                placeholder: "Password",
                Type: Input,
                border: "1px solid #383083",
              },
            ]}
            isLink={{
              status: "true",
              style: { color: "#E42F36" },
              linktext: "Forgot Password?",
              url: "forgot-password",
              className: "link-color caption mr-b-md textLeft",
              align: "start",
            }}
            submitButton={{
              span: 14,
              buttonText: "Login",
              className: "mr-b-md bg-gradient",
            }}
          />
        </Col>
      </Row>
    </AuthContainer>
  );
          }
export default LoginScreen
