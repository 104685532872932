import React, { useState, useEffect } from "react";
import ContentHeader from "../../../appcomponents/shares/contentheader";
import moment from "moment";
import { Redirect, Route, Switch } from "react-router-dom";
// import Blip from "../../appcomponents/shares/blip";
import TableWrapper from "../../../components/react-table";
import TagWrapper from "../../../components/tag";
import SwitchWrapper from "../../../components/switch";
import AvatarWrapper from "../../../components/avatar/index";
//import AvatarWrapper from "../../../components/avatar/index";
import EmptyProfilePic from "../../../assets/images/empty-profile.png";
import { Row, Col, Pagination } from "antd";
import { connect } from "react-redux";
import { usersRequest, changeUserStatusRequest } from "../../../actions/Users";
import Loader from "../../../components/loading/index";
import { useSelector } from "react-redux";
// // import ProfileDetails from "./ProfileDetails";
import CardWrapper from "../../../components/card/index";
import { current_sprint } from "../../../configurations/Constants";
import PopconfirmWrapper from "../../../components/pop-confirm";
import UserFloatingCard from "../../../appcomponents/general/FloatingCard";
import { FiAlertTriangle } from "react-icons/fi";
import Blip from "../../../appcomponents/shares/blip";

let map = {
  1: "primary",
  2: "secondary",
};

const Users = (props) => {
  const [profileOpen, setProfileOpen] = useState(false);
  const [tableChanged, setTableChanged] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [toggle, setToggle] = useState(false);
  const [current, setCurrent] = useState(1);

  const [filters, setFilters] = useState({
    filter: "all",
    // active: undefined,
    limit: 10,
    offset: 0,
    search: "",
  });

  useEffect(() => {
    state.apiCall({ ...filters });
  }, []);

  const [state] = useState({
    apiCall: (params) => {
      console.log(params, "STATEapi");
      props.usersRequest({ ...filters });
    },
  });

  const searchChange = (e) => {
    if (e) {
      filters.search = e.trim();
      setFilters({ ...filters });
      filters.offset = 0;
      props.usersRequest({ ...filters });
    } else {
      filters.search = e.trim();
      props.usersRequest({ ...filters });
      setFilters({ ...filters });
    }
  };

  const filterChange = (e) => {
    if (e) {
      props.usersRequest({ ...filters, filter: e });
      setFilters({ ...filters, filter: e });
      setCurrent(1);
    }
  };

  const handleToggleUserStatus = (id, status) => {
    props.changeUserStatusRequest(
      {
        user_id: id,
        status,
      },
      (res) => {
        if (res?.status == 200) {
          props.usersRequest({ ...filters });
        }
        //setTableChanged(!tableChanged);
      }
    );
  };

  const handlePages = (pageNumber) => {
    const offset = (pageNumber - 1) * 10;
    const objPaginationData = {
      ...filters,
      offset,
    };
    props.usersRequest(objPaginationData);
    setCurrent(pageNumber); 
    setFilters({...filters,offset})
  };

  //   useEffect(() => {
  //     props.history.push("./users/")
  // });

  console.log(filters, "dataFilter");
  console.log(props, "userDATAAAA");
  console.log(props.userData, "props.userData");

  return (
    <Switch>
      <Row type="flex" gutter={[15, 20]}>
        <Col span={24}>
          <ContentHeader
            title="Users"
            count={props.userData?.totalCount}
            searchChange={searchChange}
            filterChange={filterChange}
            options={[
              { label: "View All", value: "all" },

              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#CEC4C3" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Organization</Col>
                  </Row>
                ),
                value: "ORGANIZATION",
              },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#151D30" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Individual</Col>
                  </Row>
                ),
                value: "INDIVIDUAL",
              },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#717070" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Org member</Col>
                  </Row>
                ),
                value: "ORG-MEMBER",
              },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#B4D862" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Int Org</Col>
                  </Row>
                ),
                value: "ORG-INTERESTED",
              },

              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#C3F64D" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Active user</Col>
                  </Row>
                ),
                value: "ACTIVE",
              },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#717070" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Inactive user</Col>
                  </Row>
                ),
                value: "IN-ACTIVE",
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <TableWrapper
            tableData={props.userData.users}
            // getData={state.apiCall}
            // search={search}
            filters={filters}
            pageSize={10}
            noDataText="No users found"
            loading={props.loading}
            dataCount={props.userData?.users?.count}
            getTrProps={(state, rowInfo, column) => ({
              onClick: () =>
                props.history.push({
                  pathname: `/users/${rowInfo.original.id}`,
                }),
              style: { cursor: "pointer" },
            })}
            getTdProps={(state, rowInfo, column, c, d) => {
              return column.className === "clickable"
                ? {
                    onClick: (e) => e.stopPropagation(),
                    style: { cursor: "default", overFlow: "hidden" },
                  }
                : {};
            }}
            //enableReInit={true}
            tableChanged={tableChanged}
            content={[
              {
                name: "",
                id: "thumbnail_picture",
                centered: true,
                small: true,
                sortable: false,
                render: (r) => {
                  return (
                    <AvatarWrapper
                      shape="circle"
                      size="large"
                      src={r.original["thumbnail_picture"] || EmptyProfilePic}
                    ></AvatarWrapper>
                  );
                },
              },
              {
                name: "Full Name",
                id: "userName",
                render: (r) => {
                  return (
                    <span
                      style={{ cursor: "pointer", textTransform: "capitalize" }}
                      onClick={() => {
                        setSelectedUserId(r.original.id);
                      }}
                    >
                      {r.original.fullName}
                    </span>
                  );
                },
              },
              { name: "Email", id: "email", sortable: false, centered: true },
              {
                name: "User Type",
                id: "usertype",
                centered: true,
                sortable: false,
                render: (r) => {
                  return (
                    <span
                      style={{ cursor: "pointer", textTransform: "capitalize" }}
                      onClick={() => {
                        setSelectedUserId(r.original.type);
                      }}
                    >
                      {r.original.type}
                    </span>
                  );
                },
              },
              {
                name: "Status",
                id: "status",
                className: "clickable",
                sortable: false,
                centered: true,
                render: (r) => (
                  <TagWrapper type={r.original.status}>
                    {
                    r?.original?.status == 4 
                    ? 'Deleted'
                    :r?.original?.freeTrial == 1
                    ?'Free'
                    :r?.original?.isSubscribed == true 
                    ? 'Subscribed'
                    :r?.original?.subscriptions?.length == 0
                    ? 'Pending'
                    :r?.original?.subscriptions?.length !== 0 
                    ? 'Expired'
                    :''
                    }
                  </TagWrapper>
                ),
              },

              {
                name: "Actions",
                id: "actions",
                sortable: false,
                className: "clickable",
                render: (r) => {
                  return (
                    <Row type="flex" style={{ width: "100%" }} align="middle">
                      {r?.original?.status !== 4 &&
                      <>
                      <Col>
                        <PopconfirmWrapper
                          title={
                            <h4>
                              Are you sure you want to
                              {r.original["status"] === 3
                                ? " activate "
                                : " deactivate "}
                              this user?
                            </h4>
                          }
                          icon={
                            <FiAlertTriangle
                              style={{
                                position: "absolute",
                                color: "#f44336",
                                left: 0,
                                top: 0,
                                marginTop: 6,
                              }}
                            />
                          }
                          cancelText="No"
                          okText="Yes"
                          onConfirm={() =>
                            handleToggleUserStatus(
                              r?.original["id"],
                              r?.original["status"] === 1 ? 3 : 1
                            )
                          }
                        >
                          <SwitchWrapper
                            checked={r.original && r.original.status === 1}
                            style={{ marginRight: 10 }}
                            //onClick={handleSwitch}
                          />
                        </PopconfirmWrapper>
                      </Col>
                      <Col>
                        <p style={{ fontWeight: 600 }}>
                          User is{" "}
                          {r.original["status"] === 1
                            ? "Active"
                            : r.original["status"] === 3
                            ? "In-Active"
                            : ""}
                        </p>
                      </Col>
                      </>
                      }
                    </Row>
                  );
                },
              },
            ]}
          />
        </Col>
        <Pagination
          responsive="true"
          showLessItems="true"
          className="tbl-pagination"
          current={current}
          pageSize={10}
          total={props.userData.totalCount}
          onChange={(e) => {
            console.log("Page number is:", e);
            handlePages(e);
          }}
        />
      </Row>
    </Switch>
  );
};

export default connect(
  (storeState) => ({
    userData: storeState.Users,
    loading: storeState.Users.profileLoading,
  }),
  {
    usersRequest,
    changeUserStatusRequest,
  }
)(Users);
