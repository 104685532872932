import {
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_UPDATE_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case PROFILE_UPDATE_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }

    case PROFILE_UPDATE_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    default: {
      return state;
    }
  }
};
