import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_ERROR,
  USER_REQUEST,
  USER_SUCCESS,
  USER_ERROR,
  CLEAR_USER,
  CHANGE_USER_STATUS_REQUEST,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_ERROR,
  GET_USER_EMAIL_REQUEST,
  GET_USER_EMAIL_SUCCESS,
  GET_USER_EMAIL_ERROR
} from "../configurations/Types";
//import { DEFAULT_TABLE_PAGE_SIZE } from "../configurations/Constants";
//import rootModal from '../commons/modals/rootModal';

export function usersRequest(params, cb, clearFlag, ofPage) {
  console.log("actionParamsa", params);
  return {
    type: USERS_REQUEST,
    // params:params={ limit: limit || DEFAULT_TABLE_PAGE_SIZE,
    //           offset: offset || 0,
    //           like: search || "",
    //           status: filter || "" },
    params,
    callback: cb,
    //clearFlag,
    ////ofPage
  };
}

export function getUserEmailRequest(email) {
  return {
    type: GET_USER_EMAIL_REQUEST,
    email,
  };
}

export function getUserEmailSuccess(payload) {
  return {
    type: GET_USER_EMAIL_SUCCESS,
    payload,
  };
}

export function getUserEmailError(payload) {
  return {
    type: GET_USER_EMAIL_ERROR,
    payload,
  };
}

export function receivedUsers(
  userData,
) {
  return {
    type: USERS_SUCCESS,
    userData
    // payload: userData,
    // clearFlag,
    // ofPage,
    // typeOfMatches,
    // count,
  };
}

export function usersError(error) {
  return {
    type: USERS_ERROR,
    error,
  };
}

export function userRequest(id, cb) {
  return {
    type: USER_REQUEST,
    id,
    callback: cb,
  };
}

export function receivedUser(userData) {
  return {
    type: USER_SUCCESS,
    payload: userData,
  };
}

export function userError(error) {
  return {
    type: USER_ERROR,
    error,
  };
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  };
}

export function changeUserStatusRequest(params, cb) {
  //let newData = {...values, email:extras}
  console.log("changeUserStatusRequest", params);
  return {
    type: CHANGE_USER_STATUS_REQUEST,
    params,
    cb,
  };
}

export function changeUserStatusSuccess(payload) {
  //let newData = {...values, email:extras}
  return {
    type: CHANGE_USER_STATUS_SUCCESS,
    payload,
  };
}

export function changeUserStatusError(error) {
  //let newData = {...values, email:extras}
  return {
    type: CHANGE_USER_STATUS_ERROR,
    error,
  };
}
