import React from "react";
import { Row, Col, Skeleton } from "antd";
import CardWrapper from "../../../components/card";
import Image from "../../../components/image";

import styled from "styled-components";

const StyledCard = styled(CardWrapper)`
  & > .ant-card-body {
    // padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
`;

const RowCard = (props) => (
  <StyledCard className={props.styledClass}>
    <Skeleton loading={props.loading} avatar active>
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        style={{ textAlign: "left", height: "100%" }}
      >
        <Col>
          <h4 style={{ color: "black", fontWeight: "500" }}>{props.title}</h4>
        </Col>
        <Col>
          <h4 style={{ color: "black", fontWeight: "500" }}>
            {props.organizationName}
          </h4>
          <h4 style={{ color: "black", fontWeight: "500",textTransform:'capitalize' }}>{props.value}</h4>
        </Col>
      </Row>
    </Skeleton>
  </StyledCard>
);

export default RowCard;
