import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { menuShow } from "../../configurations/Config";
import { useSelector } from "react-redux";

import { Row, Col, Popconfirm, Popover, Divider } from "antd";
import { FiPower } from "react-icons/fi";
import { RiLockLine } from "react-icons/ri";

import EmptyProfilePic from "../../assets/images/empty-profile.png";
import PopoverWrapper from "../popover/index";
import Avatar from "../avatar/index";
import Icon from "../Icon/index";
import Button from "../button/index";
import { logout } from "../../utils/index";
import BackButton from "../../appcomponents/shares/header/BackButton";
import "./style.css";

const HeaderContent = (props) => {
  const [state, setState] = useState({
    userData: "",
    popoverOpen: false,
  });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("revicebeat_adminauth")) {
      const userData = JSON.parse(
        window.localStorage.getItem("revicebeat_adminauth")
      );
      setState({ ...state, userData });
    }
  }, []);

  const data = useSelector((state) => {
    return state;
  });

  const account = (history, props) => {
    return (
      <React.Fragment>
        <div
          className="profileDropdownItem"
          onClick={() => {
            history.push("/change-password");
            setState({ ...state, popoverOpen: false });
          }}
        >
          <RiLockLine style={{ color: "#C3F64D" }} />
          &nbsp;&nbsp;Change Password
        </div>
        {/* <Divider style={{margin:'12px 0'}}/> */}
        <div
          onClick={() => {
            logout();
          }}
          className="profileDropdownItem logout"
        >
          <FiPower />
          &nbsp;&nbsp;Logout
        </div>
      </React.Fragment>
    );
  };

  const profileDetails = data.Auth.profileDetails?.user?.fullName;

  const { externalscreen } = data;
  const handleOpenChange = () => {
    setState({ ...state, popoverOpen: false });
  };
  return (
    <Row
      style={{ height: "100%" }}
      type="flex"
      align="middle"
      justify="space-between"
    >
      <Col className="zeroLineHeight" span={externalscreen ? 8 : undefined}>
        {externalscreen ? (
          <BackButton
            title={props.App.backButtonLabel}
            action={() =>
              history.push(
                location.state
                  ? location.state.referrer
                    ? location.state.referrerState
                      ? {
                          pathname: location.state.referrer,
                          state: location.state.referrerState,
                        }
                      : location.state.referrer
                    : "/"
                  : "/"
              )
            }
          />
        ) : null}
      </Col>
      <Col span={externalscreen ? 16 : undefined}>
        <Row
          className="zeroLineHeight"
          type="flex"
          align="middle"
          justify="end"
        >
          {/* <Col style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={EmptyProfilePic}
              shape="circle"
              size="small"
              style={{
                color: "white",
                cursor: "pointer",
                width: "3.2rem",
                height: "3.2rem",
              }}
              onClick={() =>
                setState({ ...state, popoverOpen: !state.popoverOpen })
              }
            />
            {menuShow.rightMenu === true ? (
              <Button
                type="primary"
                onClick={() => props.toggleCollapsed("collapsedRight")}
                style={{ zIndex: 10 }}
              >
                <Icon
                  type={
                    props.state.collapsedRight ? "menu-fold" : "menu-unfold"
                  }
                />
              </Button>
            ) : null}
          </Col> */}

          <Col>
            {/* <PopoverWrapper
              fixed={!externalscreen}
              visible={state.popoverOpen}
              className="profilePopover"
              onOpenChange={handleOpenChange}
              title={
                <Row
                  type="flex"
                  align="middle"
                  justify="space-between"
                  style={{ flexWrap: "nowrap" }}
                >
                  <Col span={20} style={{ marginRight: "0.5vw" }}>
                    <div className="name">
                      {profileDetails ? `${profileDetails}` : `-`}
                    </div>
                    <div className="role">Admin</div>
                  </Col>
                </Row>
              }
              trigger="hover"
              content={account(history, props)}
              
            ></PopoverWrapper> */}
          </Col>
          {/* ===================================== */}
          <Col>
            <Popover
              content={account(history, props)}
              className="profilePopover"
              placement="bottomRight"
              title={
                <Row
                  type="flex"
                  align="middle"
                  justify="space-between"
                  style={{ flexWrap: "nowrap" }}
                  className="popoverTitle"
                >
                  <Col span={20} style={{ marginRight: "0.5vw" }}>
                    <div className="name">
                    {profileDetails ? `${profileDetails}` : `-`}
                    </div>
                    <div className="roleTxt">Admin</div>
                  </Col>
                </Row>
              }
              trigger="click"
              open={state.popoverOpen}
              onOpenChange={handleOpenChange}
            >
              <Avatar
                src={EmptyProfilePic}
                shape="circle"
                size="small"
                style={{
                  color: "white",
                  cursor: "pointer",
                  width: "3.2rem",
                  height: "3.2rem",
                }}
                onClick={() =>
                  setState({ ...state, popoverOpen: !state.popoverOpen })
                }
              />
            </Popover>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HeaderContent;
