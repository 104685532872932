import * as Yup from "yup";

const emojiRegex = /\p{Extended_Pictographic}/u;
export const addOrganizationSchema = Yup.object().shape({
     name: Yup.string()
    // .matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    .matches(/^[a-zA-Z ]*$/, "Only alphabets are allowed.")
    .required("Please provide name")
    .max(40, "Name is too long, It must be less than 40 characters"),

    email: Yup.string()
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Email is not valid!"
    )
    .required("Email Required")
    .max(100, "Email length must be less than 26 characters"),

    subscriptionPackage: Yup.string()
    .required("Please provide subscription plan"),

    ownerName: Yup.string()
    .matches(/^[a-zA-Z ]*$/, "Only alphabets are allowed.")
    .required("Please provide owner name")
    .max(40, "Name is too long, It must be less than 40 characters"),

    


    orgName: Yup.string()
    // .matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    // .matches(/^[a-zA-Z ]*$/, "Only alphabets are allowed.")
    .required("Please provide organization name")
    .max(40, "Name is too long, It must be less than 40 characters"),



    pricePerUser: Yup.string()
    .matches(
    /(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\d*\.\d*$)/,
    "Please provide a valid amount"
     )
    .required("Please provide price"),
//   .notOneOf(["0"], "Please provide an amount"),


    usersLimit: Yup.string()
    .required("Please provide allowed users")
    .matches(/^[1-9]\d*$/,"Enter valid allowed users"),
  

    location: Yup.string()
    .test(
      'Emoji',
      'Emojies are not allowed',
      (value) => !emojiRegex.test(value))
    .required("Please provide location")
    .max(40, "Location is too long, It must be less than 40 characters"),


    phone: Yup.string()
    .min(7, "Phone Number is too Short! it must be between 7 and 16 characters")
    .max(16, "Phone Number is too Long! it must be between 6 and 16 characters")
    .matches(
        /^(([\+]([\d]{2,}))([0-9]{7,})|([0-9\/\s]{7,}))*$/,
        "Please provide a valid Phone Number"
      )
    .required("Please provide phone number"),

});


export const updateOrganizationSchema = Yup.object().shape({
 subscriptionPlanId: Yup.string()
 .required("Please provide subscription plan"),

 pricePerUser: Yup.string()
 .matches(
 /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/,
 "Please provide a valid amount"
  )
 .required("Please provide price"),
//   .notOneOf(["0"], "Please provide an amount"),


allowedUsers: Yup.string()
 .matches(/^\d+$/, "Enter valid number of users")
 .required("Please provide allowed users"),
});

