import React from "react";
import { Row, Col, Skeleton } from "antd";
import CardWrapper from "../../../components/card";
import Image from "../../../components/image";

import styled from "styled-components";
import "./HorizontalStatCard.css";

const StyledCard = styled(CardWrapper)`
  & > .ant-card-body {
    padding: 2.2rem 1rem;
  }
`;

const UserStatCard = (props) => (
  <>
    {console.log("UserStatCardprops: ", props)}

    <StyledCard className={props.styledClass}>
      <Skeleton loading={props.loading} avatar active>
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          style={{ textAlign: "left" }}
        >
          <Col>
            <div
              className="card-icon-background bg-gradient"
            >
              <Image width={20} src={props.icon} />
            </div>
            <h4 style={{ margin: "14px 0px 0px 0px", fontWeight: "bold" }}>
              {props.title}
            </h4>
            <p className="hintColor" style={{ marginTop: 10 }}>
              {props.amount}
            </p>
          </Col>
          {/* <Col>
          <h3 className="hintColor" style={{ fontWeight: "bold" }}>
            {props.amount}
          </h3>
        </Col> */}
        </Row>
      </Skeleton>
    </StyledCard>
  </>
);

export default UserStatCard;
