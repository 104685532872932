import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Pagination } from "antd";
import { openModal } from "../../../actions/Modal";
import ContentHeader from "../../../appcomponents/shares/contentheader";
import {
  organizationRequest,
  updateOrganizationRequest,
} from "../../../actions/Organization";
import { changeUserStatusRequest } from "../../../actions/Users";

import PopconfirmWrapper from "../../../components/pop-confirm";
import { FiAlertTriangle } from "react-icons/fi";
import Blip from "../../../appcomponents/shares/blip";
import TableWrapper from "../../../components/react-table";
import TagWrapper from "../../../components/tag";
import SwitchWrapper from "../../../components/switch";
import AvatarWrapper from "../../../components/avatar/index";

import EmptyProfilePic from "../../../assets/images/empty-profile.png";

const Organization = (props) => {
  const [offSet, setOffSet] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [state, setState] = useState({
    offset: 0,
    limit: 10,
    search: "",
    isActive: "",
  });
  const [current, setCurrent] = useState(1);
  const [init, setInit] = useState(false);
  const [mount, setMount] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [tableChanged, setTableChanged] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => {
    return state;
  });
  console.log("Organization_data: ", data);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (!mount) {
      const { limit, offset, search, isActive } = state;
      const objOrg = {
        limit,
        offset,
        search,
        isActive,
      };
      dispatch(organizationRequest(objOrg));
    }
    setMount(true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (mount || init) {
      setToggle(!toggle);
    }
  }, [state.search, state.active, offSet, init]);

  useEffect(() => {
    if (mount) {
      if (
        !loading &&
        !props.organizationState.loading &&
        props.organizationState.totalCount > props.organizationState.length
      ) {
        setLoading(true);
      }
    }
  }, [hasScrolled]);

  useEffect(() => {
    if (mount) {
      if (!props.organizationState.loading) {
        setLoading(false);
      }
    }
  }, [props.organizationState.loading]);

  const handleScroll = () => {
    if (
      window.innerHeight + window.pageYOffset >=
      document.getElementById("root").clientHeight - 5
    ) {
      setHasScrolled((prev) => !prev);
    }
  };
  const searchChange = (e) => {
    e
      ? dispatch(organizationRequest({ ...state, search: e }))
      : dispatch(organizationRequest({ ...state, search: e }));
    setState({ ...state, search: e });
  };

  const filterChange = (e) => {
    dispatch(organizationRequest({ ...state, isActive: e, offset: 0 }));
    setState({ ...state, isActive: e });
  };

  const [fil] = useState({
    apiCall: (...params) => props.organizationRequest({ ...params[0] }),
  });
  const changeOffset = (abc) => {
    setState({
      ...state,
      offset: state.offset,
    });
  };

  const handlePages = (pageNumber) => {
    // const { isActive, search, limit } = state
    // const limit = 10;
    const offset = (pageNumber - 1) * 10;
    // const objOrg={
    // isActive,search,limit,offset
    // }
    dispatch(organizationRequest({ ...state, offset }));
    setState({ ...state, offset });
    setCurrent(pageNumber);
  };

  const addBtnStyle = {
    background: "#C3F64D",
    color: "#151D30",
    boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)",
  };
  return (
    <React.Fragment>
      <Row type="flex" gutter={[15, 20]} justify="space-between">
        <Col span={24}>
          <ContentHeader
            showAddButton
            buttonStyle={addBtnStyle}
            onClick={() => props.history.push("/addOrganization")}
            searchChange={searchChange}
            filterChange={filterChange}
            title="Organization"
            count={props.organizationState.totalCount}
            options={[
              { label: "View All", value: "" },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#C3F64D" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Active user</Col>
                  </Row>
                ),
                value: 1,
              },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#151D30" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Inactive user</Col>
                  </Row>
                ),
                value: 0,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <TableWrapper
            tableData={data?.Organization?.organization}
            search={state.search}
            filters={state}
            pageSize={10}
            dataCount={data?.Organization?.totalCount}
            loading={data?.Organization?.loading}
            changeOffset={changeOffset}
            getTrProps={(state, rowInfo, column) => ({
              onClick: () =>
                history.push({
                  pathname: `/organization/${rowInfo.original.useIid}`,
                  state: {
                    referrer: "/users",
                    organizationId: rowInfo.original.id,
                  },
                }),
              style: { cursor: "pointer", overflowX: "hidden" },
            })}
            getTdProps={(state, rowInfo, column, c, d) => {
              return column.className === "notClickable"
                ? {
                    onClick: (e) => e.stopPropagation(),
                    style: { cursor: "default", overFlow: "hidden" },
                  }
                : {};
            }}
            tableChanged={tableChanged}
            content={[
              {
                name: "",
                id: "thumbnail_picture",
                centered: true,
                small: true,
                sortable: false,
                render: (r) => {
                  return (
                    <AvatarWrapper
                      shape="circle"
                      size="large"
                      src={r.original["thumbnail_picture"] || EmptyProfilePic}
                    ></AvatarWrapper>
                  );
                },
              },
              {
                name: `Organization`,
                subName: "Owner Name",
                id: "fullName",
                leftAligned: true,
                render: (r) => {
                  return (
                    <Col style={{ width: "100%" }}>
                      <p
                        style={{
                          justifySelf: "left",
                          cursor: "pointer",
                          textAlign: "left",
                          alignSelf: "left",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          setSelectedUserId(r?.original?.id);
                        }}
                      >
                        {r?.original?.orgName}
                      </p>
                      <p
                        style={{
                          cursor: "pointer",
                          textAlign: "left",
                          color: "grey",
                        }}
                        onClick={() => {
                          setSelectedUserId(r?.original?.id);
                        }}
                      >
                        {r?.original?.ownerName}
                      </p>
                    </Col>
                  );
                },
              },
              {
                name: "Email",
                centered: true,
                id: "email",
                render: (r) => {
                  return (
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {r?.original?.email}
                    </p>
                  );
                },
              },
              {
                name: "Price per User",
                id: "usertype",
                centered: true,
                className: "notClickable",
                sortable: false,
                render: (r) => {
                  return (
                    <span
                      onClick={() => {
                        setSelectedUserId(r.original.type);
                      }}
                    >
                      {`${r?.original?.pricePerUser} £`}
                    </span>
                  );
                },
              },
              {
                name: "Allowed User",
                id: "usertype",
                centered: true,
                className: "notClickable",
                sortable: false,
                render: (r) => {
                  return (
                    <span
                      onClick={() => {
                        setSelectedUserId(r?.original?.type);
                      }}
                    >
                      {r?.original?.usersLimit}
                    </span>
                  );
                },
              },
              {
                name: "Subscription",
                subName: "Status",
                id: "status",
                className: "notClickable",
                centered: true,
                sortable: false,
                render: (r) => (
                  <TagWrapper type={r.original.status}>
                    {r?.original?.status}
                    
                  </TagWrapper>
                ),
              },

              {
                name: "Actions",
                id: "actions",
                sortable: false,
                className: "notClickable",
                // centered: true,
                render: (r) => {
                  return (
                    <Row type="flex" style={{ width: "100%" }} justify='center' align="middle">
                      <Col>
                        <PopconfirmWrapper
                          title={
                            <h4>
                              Are you sure you want to
                              {r.original["isActive"] === true
                                ? " deactivate "
                                : " activate "}
                              this user?
                            </h4>
                          }
                          icon={
                            <FiAlertTriangle
                              style={{
                                position: "absolute",
                                color: "#f44336",
                                left: 0,
                                top: 0,
                                marginTop: 6,
                              }}
                            />
                          }
                          cancelText="No"
                          okText="Yes"
                          onConfirm={function () {
                            dispatch(
                              updateOrganizationRequest(
                                {
                                  ...state,
                                  userId: r.original["id"],
                                  isActive:
                                    r.original["isActive"] === false
                                      ? true
                                      : false,
                                },
                                (res) => {
                                  dispatch(
                                    organizationRequest(
                                      state,
                                    )
                                  );
                                  setTableChanged(!tableChanged);
                                }
                              )
                            );
                          }}
                        >
                          <Col>
                          <SwitchWrapper
                            checked={
                              r.original && r.original.isActive !== false
                            }
                            style={{ marginRight: 10 }}
                          />
                          </Col>
                        </PopconfirmWrapper>
                      </Col>
                    </Row>
                  );
                },
              },
              {
                name: "",
                id: "action_res",
                sortable: false,
                className: "notClickable",
                // centered: true,
                render: (r) => {
                  return (
                    <Col>
                      <p style={{ fontWeight: 600 }}>
                        User is{" "}
                        {r.original["isActive"] !== true
                          ? "In-Active"
                          : "Active"}
                      </p>
                    </Col>
                  );
                },
              },
            ]}
          />
        </Col>
        <Pagination
          responsive="true"
          showLessItems="true"
          current={current}
          className="tbl-pagination"
          pageSize={10}
          total={data?.Organization.totalCount}
          onChange={(e) => {
            handlePages(e);
          }}
        />
      </Row>
    </React.Fragment>
  );
};

export default connect((state) => ({ organizationState: state.Organization }), {
  openModal,
  organizationRequest,
  changeUserStatusRequest,
})(Organization);
