import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import { Row, Col, Pagination } from "antd";
import { FaTimes } from "react-icons/fa";
import ContentHeader from "../../../appcomponents/shares/contentheader";
import PopconfirmWrapper from "../../../components/pop-confirm";
import { FiAlertTriangle } from "react-icons/fi";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import { FaTrash, FaCheck } from "react-icons/fa";
import Blip from "../../../appcomponents/shares/blip";
import TableWrapper from "../../../components/react-table";
import TagWrapper from "../../../components/tag";
import SwitchWrapper from "../../../components/switch";
import InputWrapper from "../../../components/input";
import Button from "../../../components/button";
import {
  addPromoCodeRequest,
  getPromosRequest,
  deletePromoRequest,
  toggleActivatePromoRequest,
} from "../../../actions/PromoCode";
import EditPromoCode from "./editPromoCode";

const Promocode = (props) => {
  const [offSet, setOffSet] = useState(0);
  const [state, setState] = useState({
    offset: 0,
    limit: 10,
    search: "",
    status: "all",
  });
  const [tableChanged, setTableChanged] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState(null);
  const [modal, setModal] = useState(false);
  const [discountDropdown, setDiscountDropdown] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [promoCodeError, setPromoCodeError] = useState(true);
  const [mount, setMount] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [init, setInit] = useState(false);
  const [name, setName] = useState("");
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promocode, setPromocode] = useState("");
  const [discount, setDiscount] = useState("");
  const [discounts] = useState(["10", "20", "30"]);
  const [packages, setPackages] = useState([]);
  const [current, setCurrent] = useState(1);
  const [discountPackages] = useState([
    {
      name: "Standard Package",
      price: "£ 39.99",
      duration: "12 months",
      discounts: {
        10: "£ 35.99",
        20: "£ 31.99",
        30: "£ 27.99",
      },
    },
    {
      name: "Premium Package",
      price: "£ 49.99",
      duration: "12 months",
      discounts: {
        10: "£ 44.99",
        20: "£ 39.99",
        30: "£ 34.99",
      },
    },
  ]);

  const history = useHistory()

  const dispatch = useDispatch()
  const data = useSelector(state => {
    return state
  })

  const objGetPromos = {
    limit: state.limit,
    offset: state.offset,
    search: state.search,
    status: state.status,
  };

  useEffect(() => {
    // window.addEventListener("scroll", handleScroll);
    //document.body.addEventListener("touchmove", handleScroll);
    if (!mount) {
      dispatch(getPromosRequest(objGetPromos))
    }
    setMount(true);
    // return () => {
    // window.removeEventListener("scroll", handleScroll);
    //document.body.removeEventListener("touchmove", handleScroll);
    // };
  }, []);
  const { PromoCodeReducer } = data
  const { totalCount, promoCodes } = data?.PromoCodeReducer

  // Temporary Comment this code
  // useEffect(() => {
  //   if (mount || init) {
  //     setToggle(!toggle);
  //   }
  // }, [state.search, state.active, offSet, init]);

  useEffect(() => {
    if (mount) {
      if (
        !loading &&
        !PromoCodeReducer?.loading &&
        totalCount > props.promocodeState.length
      ) {
        setLoading(true);
        // setOffSet((prev) => prev + 8);
      }
    }
  }, [hasScrolled]);

  useEffect(() => {
    if (mount) {
      if (!PromoCodeReducer?.loading) {
        setLoading(false);
      }
    }
  }, [PromoCodeReducer?.loading, selectedPromoCode]);

  const handleScroll = () => {
    if (
      window.innerHeight + window.pageYOffset >=
      document.getElementById("root").clientHeight - 5
    ) {
      setHasScrolled((prev) => !prev);
    }
  };

  const searchChange = (e) => {
    const objSearchPromo = {
      ...objGetPromos, search: e
    }
    dispatch(getPromosRequest(objSearchPromo))
    setState({ ...state, search: e });
  };

  const filterChange = (e) => {
    const objFilterPromo = {
      ...objGetPromos, status: e
    }
    dispatch(getPromosRequest(objFilterPromo))
    setState({ ...state, status: e });
  };

  const handleAddPromo = () => {
    setModal(true);
    setName("");
    setEmail("");
    setPromocode("");
    setDiscount("");
    setPackages([]);
  };

  const validateEmail = (e) => {
    setEmail(e);
    const re =
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emailValid = re.test(String(e).toLowerCase());
    setEmailError(emailValid ? "" : "Invalid Email");
  };

  const validatePromoCode = (code) => {
    setPromocode(code);
    if (code.length < 3)
      setPromoCodeError("Sorry! Promocode cannot be less than 3 characters.");
    else if (code.includes(" ")) setPromoCodeError("Spaces are not allowed.");
    else setPromoCodeError("");
  };

  const selectPackage = (item) => {
    let packagesArr = [...packages];
    let packageInd = packagesArr.findIndex((val) => val.name == item.name);
    if (packageInd > -1) packagesArr.splice(packageInd, 1);
    else packagesArr.push({ name: item.name });

    setPackages(packagesArr);
  };

  const submitForm = () => {
    let payload = {
      name,
      email,
      discountPercentage: discount,
      promoCode: promocode,
      discountPackage:
        packages.length > 1
          ? "both"
          : packages[0].name.split(" ")[0].toLowerCase(),
    };
    //API call here
    dispatch(addPromoCodeRequest(payload, (res) => {
      if (res?.data?.status == 200) {
        setModal(false);
        dispatch(getPromosRequest(state.limit, state.offSet, "", state.status))
      }
    }))
  };

  const handlePages = (pageNumber) => {
    const offset = (pageNumber - 1) * 10;
    const objChangePagePromos = {
      ...objGetPromos, offset
    }
    dispatch(getPromosRequest(objChangePagePromos))
    setState({ ...state, offset });
    setCurrent(pageNumber);
  };

  const handleDeletePromo = (id) => {
    dispatch(deletePromoRequest(id, (res) => {
      if (res?.status == 200) {
        dispatch(getPromosRequest(state.limit, state.offSet, "", state.status))
      }
    }))
  };

  const handleToggleActivatePromoCode = (id, status) => {
    dispatch(toggleActivatePromoRequest(id, status, (res) => {
      if (res?.status == 200) {
        dispatch(getPromosRequest(state.limit, state.offSet, "", state.status))
      }
    }))
  };

  const backEditScreen = () => {
    setSelectedPromoCode(null);
    dispatch(getPromosRequest(state.limit, state.offSet, "", state.status))
  };

  const addBtnStyle = {
    background: "#C3F64D",
    color: "#151D30",
    boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)",
  };

  const onCloseModal = () => {
    setModal(false);
    setEmail("");
    setEmailError("");
    setName("");
    setPromoCodeError(false);
  };
  return (
    <React.Fragment>
      {selectedPromoCode !== null ? (
        <EditPromoCode
          promoCode={selectedPromoCode}
          handleBackEdit={backEditScreen}
        />
      ) : (
        <>
          <Row type="flex" gutter={[15, 20]} justify="space-between">
            <Col span={24}>
              <ContentHeader
                showPlusButton
                buttonStyle={addBtnStyle}
                showAddButton
                onClick={handleAddPromo}
                addButtonText="Add Promo"
                count={totalCount && totalCount}
                searchChange={searchChange}
                filterChange={filterChange}
                title="Promo Code"
                options={[
                  { label: "View All", value: "all" },
                  {
                    label: (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={[5, 0]}
                      >
                        <Col>
                          <Blip color="#C3F64D" margin={"0 0.3vw 0 0"} />
                        </Col>

                        <Col>Active</Col>
                      </Row>
                    ),
                    value: 1,
                  },
                  {
                    label: (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={[5, 0]}
                      >
                        <Col>
                          <Blip color="#151D30" margin={"0 0.3vw 0 0"} />
                        </Col>

                        <Col>In-Active</Col>
                      </Row>
                    ),
                    value: 0,
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <TableWrapper
                tableData={promoCodes}
                // getData={fil.apiCall}
                search={state.search}
                filters={state}
                pageSize={10}
                current={current}
                dataCount={totalCount}
                loading={PromoCodeReducer?.loading}
                getTrProps={(state, rowInfo, column) => ({
                  onClick: () => history.push({
                    pathname: `/promocode/${rowInfo.original.id}`,
                    state: {
                      referrer: "/users",
                      organizationId: rowInfo.original.id,
                    },
                  }),
                  style: { cursor: "pointer", overflowX: "hidden" },
                })}
                getTdProps={(state, rowInfo, column, c, d) => {
                  return column.className === "clickable"
                    ? {
                      onClick: (e) => e.stopPropagation(),
                      style: { cursor: "default", overFlow: "hidden" },
                    }
                    : {};
                }}
                tableChanged={tableChanged}
                noDataText="No record found"
                content={[
                  {
                    name: `Name`,
                    id: "name",
                    leftAligned: true,
                    style: { marginLeft: 20 },
                    render: (r) => {
                      return (
                        <div style={{ marginLeft: 20 }}>
                          <p
                            onClick={() => {
                              setSelectedPromoCode(r?.original);
                            }}
                            style={{
                              justifySelf: "left",
                              textAlign: "left",
                              alignSelf: "left",
                            }}
                          >
                            {r?.original?.name || "-"}
                          </p>
                        </div>
                      );
                    },
                  },
                  {
                    name: "PromoCode",
                    centered: true,
                    id: "promocode",
                    render: (r) => {
                      return (
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedPromoCode(r?.original);
                          }}
                        >
                          {r?.original?.promoCode || "-"}
                        </p>
                      );
                    },
                  },

                  {
                    name: "Consumed",
                    id: "consumed",
                    className: "clickable",
                    centered: true,
                    render: (r) => {
                      return (
                        <span>
                          {
                            r?.original?.subscriptions.length
                          }
                        </span>
                      );
                    },
                  },
                  {
                    name: "Discount",
                    id: "discount",
                    className: "clickable",
                    centered: true,
                    render: (r) => {
                      return (
                        <span>
                          {
                            r?.original?.discountPercentage
                              ? `${r?.original?.discountPercentage}%`
                              : "-"
                          }
                        </span>
                      );
                    },
                  },
                  {
                    name: "Status",
                    id: "status",
                    className: "clickable",
                    centered: true,
                    render: (r) => (
                      <TagWrapper type={r.original.isActive}>
                        {r.original && r.original.isActive === "Active"
                          ? "Active"
                          : "In-Active"}
                      </TagWrapper>
                    ),
                  },
                  {
                    name: "Actions",
                    id: "actions",
                    className: "clickable",
                    centered: true,
                    render: (r) => {
                      return (
                        <Row
                          type="flex"
                          style={{ width: "100%", marginLeft: 10 }}
                          align="middle"
                        >
                          <Col>
                            <PopconfirmWrapper
                              title={
                                <h4>
                                  Are you sure you want to
                                  {r.original["status"] === false
                                    ? " activate "
                                    : " deactivate "}
                                  this promo code?
                                </h4>
                              }
                              icon={
                                <FiAlertTriangle
                                  style={{
                                    position: "absolute",
                                    color: "#f44336",
                                    left: 0,
                                    top: 0,
                                    marginTop: 6,
                                  }}
                                />
                              }
                              cancelText="No"
                              okText="Yes"
                              onConfirm={() =>
                                handleToggleActivatePromoCode(
                                  r?.original?.id,
                                  r?.original?.isActive === "Active"
                                    ? false
                                    : true
                                )
                              }
                            >
                              <SwitchWrapper
                                checked={
                                  r.original && r.original.status !== false
                                }
                                style={{ marginRight: 10 }}
                              />
                            </PopconfirmWrapper>
                          </Col>
                          <Col>
                            <p style={{ fontWeight: 600, width: 115 }}>
                              Code is{" "}
                              {r.original["status"] == true
                                ? "Active"
                                : "In-Active"}
                            </p>
                          </Col>
                        </Row>
                      );
                    },
                  },
                  {
                    name: "",
                    id: "delete",
                    className: "clickable",
                    centered: true,
                    render: (r) => {
                      return (
                        <Row
                          type="flex"
                          style={{ width: "100%", marginLeft: 10 }}
                          align="middle"
                        >
                          <Col>
                            <PopconfirmWrapper
                              title={
                                <h4>
                                  Are you sure you want to delete this
                                  promocode?
                                </h4>
                              }
                              icon={
                                <FiAlertTriangle
                                  style={{
                                    position: "absolute",
                                    color: "#f44336",
                                    left: 0,
                                    top: 0,
                                    marginTop: 6,
                                  }}
                                />
                              }
                              cancelText="No"
                              okText="Yes"
                              onConfirm={(e) =>
                                handleDeletePromo(r?.original?.id)
                              }
                            >
                              <span style={{ cursor: "pointer" }}>
                                <FaTrash
                                  style={{ color: "#000000", fontSize: "20px" }}
                                />
                              </span>
                            </PopconfirmWrapper>
                          </Col>
                        </Row>
                      );
                    },
                  },
                ]}
              />
            </Col>
            <Pagination
              responsive="true"
              showLessItems="true"
              className="tbl-pagination"
              defaultCurrent={1}
              pageSize={10}
              current={current}
              total={totalCount}
              onChange={(e) => {
                handlePages(e);
              }}
            />
          </Row>
          <div style={{ ...styles.modal, display: modal ? "flex" : "none" }}>
            <div style={styles.modalContainer}>
              <h2 style={styles.modalHeading}>
                Add Promo Code
                <div
                  onClick={() => onCloseModal()}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: -10,
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: 25,
                  }}
                >
                  <FaTimes />
                </div>
              </h2>
              <div style={styles.modalInputContainer}>
                <div style={styles.modalInputView}>
                  <p style={styles.modalInputLabel}>Email</p>
                  <InputWrapper
                    backgroundColor="transparent"
                    width="250"
                    height="40px !important"
                    type="email"
                    maxLength={50}
                    value={email}
                    onChange={(e) => {
                      validateEmail(e.target.value);
                    }}
                  />
                  {emailError ? (
                    <div className="dangerColor small">{emailError}</div>
                  ) : null}
                </div>
                <div style={styles.modalInputView}>
                  <p style={styles.modalInputLabel}>Name</p>
                  <InputWrapper
                    backgroundColor="transparent"
                    width="250px"
                    height="40px !important"
                    type="text"
                    maxLength={50}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      validatePromoCode(
                        e.target.value.replace(/ /g, "") + discount
                      );
                    }}
                  />
                </div>
                <div style={styles.modalInputView}>

                  <p style={styles.modalInputLabel}>Discount Percent</p>
                  <div
                    onClick={() => setDiscountDropdown(!discountDropdown)}
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={styles.dropdownArrow}
                    >
                      {console.log('styles.dropdownValue', styles.dropdownArrow)}
                      {discountDropdown ? (
                        <MdKeyboardArrowUp
                          style={{ color: "#151D30", fontSize: "16px" }}
                        />
                      ) : (
                        <MdKeyboardArrowDown
                          style={{ color: "#151D30", fontSize: "16px" }}
                        />
                      )}
                    </div>
                    {/* <div 
                    style={styles.dropdownValue}
                    >
                      {discount ? `${discount}%` : ""}
                    </div> */}
                    <InputWrapper
                      backgroundColor="transparent"
                      width="250px"
                      height="40px !important"
                      type="text"
                      maxLength={15}
                      disabled={false}
                      value={discount + '%'}
                      cursor={"pointer"}
                      placeholder='select discount %'
                    />
                    {discountDropdown ? (
                      <div style={styles.dropdown}>
                        {discounts.map((val, ind) => (
                          <div
                            onClick={() => {
                              setDiscountDropdown(false);
                              setDiscount(val);
                              validatePromoCode(name.replace(/ /g, "") + val);
                            }}
                            key={ind}
                            style={styles.dropdownItem}
                          >
                            <div>{val}%</div>
                            {val == discount ? (
                              <BsCheck
                                style={{ color: "#E2608E", fontSize: "16px" }}
                              />
                            ) : null}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div style={styles.modalInputView}>
                  <p style={styles.modalInputLabel}>Promo Code</p>
                  <InputWrapper
                    backgroundColor="transparent"
                    width="250px"
                    height="40px !important"
                    type="text"
                    maxLength={15}
                    value={promocode}
                    onChange={(e) => {
                      validatePromoCode(e.target.value.replace(/ /g, ""));
                    }}
                  />
                  {promoCodeError ? (
                    <div className="dangerColor small">{promoCodeError}</div>
                  ) : null}
                </div>
              </div>

              <p style={styles.modalSubHeading}>Discount Packages</p>
              <div style={styles.modalPackagesContainer}>
                {discountPackages.map((val, ind) => {
                  let isSelected = packages.find((pkg) => pkg.name == val.name);
                  return (
                    <div style={styles.modalPackagesView} key={ind}>
                      <div style={styles.modalPackagesSelect}>
                        <div
                          onClick={() => selectPackage(val)}
                          style={styles.modalPackagesCheckbox}
                        >
                          {isSelected ? (
                            <FaCheck
                              style={{ color: "#151D30", fontSize: "8px" }}
                            />
                          ) : null}
                        </div>
                        <p
                          style={{
                            ...styles.modalInputLabel,
                            fontWeight: "500",
                            marginBottom: 0,
                          }}
                        >
                          {val.name}
                        </p>
                      </div>
                      <p style={styles.modalPackagePrice}>
                        {val.price} ({val.duration})
                      </p>
                      {discount ? (
                        <p style={styles.modalPackagePrice}>
                          After discount ({val.discounts[discount]})
                        </p>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              <div style={{ margin: "20px auto 0 auto" }}>
                <Button
                  style={{
                    width: 200,
                    boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)",
                  }}
                  sharpBorder
                  isGreen={true}
                  size={"large"}
                  block
                  disabled={
                    PromoCodeReducer?.loading ||
                      !email ||
                      emailError ||
                      !name ||
                      !discount ||
                      promoCodeError ||
                      !packages.length
                      ? true
                      : false
                  }
                  onClick={() => submitForm()}
                >
                  {PromoCodeReducer?.loading ? "Loading.." : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

const styles = {
  modal: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,0.8)",
    alignItems: "center",
    justifyContent: "center",
    overFlow: "auto",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: 650,
    borderRadius: 20,
    backgroundColor: "white",
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 50,
    paddingBottom: 50,
    minWidth: 350,
    overflow: "auto",
    maxHeight: 650,
    zIndex: 1000,
    border: "1px solid #d9d9d9",
  },
  modalHeading: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    position: "relative",
  },
  modalInputContainer: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 20,
  },
  modalInputView: {
    flexDirection: "column",
    width: 250,
    marginBottom: 20,
  },
  modalInputLabel: {
    fontFamily: "Montserrat",
    fontWeight: "lighter",
    fontSize: 14,
    marginBottom: 10,
  },
  modalSubHeading: {
    marginTop: 30,
    marginBottom: 30,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 14,
  },
  dropdownArrow: {
    display: "flex",
    position: "absolute",
    top: 0,
    right: 0,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdownValue: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 5,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontSize: 12,
    color: "#151D30",
  },
  dropdown: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: 40,
    width: "245px",
    boxShadow: "0px 5px 20px #1E1E220C",
    zIndex: 1,
  },
  dropdownItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 40,
    width: "100%",
    borderBottom: "1px solid #FBEFF5",
    backgroundColor: "white",
    paddingLeft: 20,
    paddingRight: 10,
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontSize: 12,
  },
  modalPackagesContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  modalPackagesView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 200,
    paddingBottom: 20,
  },
  modalPackagePrice: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: 10,
    color: "#B4D862",
    marginLeft: 20,
  },
  modalPackagesSelect: {
    display: "flex",
  },
  modalPackagesCheckbox: {
    height: 15,
    width: 15,
    borderRadius: 2,
    border: "1px solid #151D30",
    marginRight: 10,
    marginTop: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default Promocode
