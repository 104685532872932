import * as Yup from "yup";

export const TrialBalanceSummarySchema = Yup.object().shape({
  bank_account_id: Yup.string()
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    //.required("Please provide your bank account name"),
    .nullable(),

  lawyer_id: Yup.string()
    // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
    // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    //.required("Please provide your lawyer name"),
    .nullable(),

  end_date: Yup.string()
  .nullable().required("Please provide ending date"),
});
