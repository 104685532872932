import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { FiTrash } from "react-icons/fi";
import Avatar from "../../../components/avatar/index";
import Badge from "../../../components/badge";
import Button from "../../../components/button/index";
import PopconfirmWrapper from "../../../components/pop-confirm";
import { FiAlertTriangle } from "react-icons/fi";
import { MessageDelRequest } from "../../../actions/Messages";

const MessageBox = (props) => {
  return (
    <React.Fragment>
      <Row
        type="flex"
        style={{
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <Col span={24}>
          <Row>
            <Col span={24} className="mr-b">
              <Row
                type="flex"
                align="top"
                gutter={10}
                style={{ flexWrap: "nowrap" }}
              >
                <Col>
                  {props.is_replied ? (
                    <Badge status={"error"}>
                      <Avatar
                        shape="circle"
                        style={{ border: "1.2px solid #FF4235" }}
                        src={props.profileImage}
                      />
                    </Badge>
                  ) : (
                    <Avatar
                      shape="circle"
                      style={{ border: "1.5px solid #72C91C" }}
                      src={props.profileImage}
                    />
                  )}
                </Col>
                <Col style={{ flexGrow: 1 }}>
                  <Row type="flex" align="middle">
                    <Col span={24}>
                      <h4
                        style={{ margin: 0, marginBottom: "0.2em" }}
                      >{`${props.name}`}
                      </h4>
                    </Col>
                    <Col span={24}>{
                      console.log('InnerCard===>',props)
                    }
                      <span className="placeholders">{`${props?.date}`}</span>
                    </Col>
                    <Col span={24}>
                      <span className="placeholders">{`${props?.email}`}</span>
                    </Col>

                  </Row>
                </Col>
              </Row>
            </Col>
            {/* <Col span={4} className="textRight">
            <PopoverWrapper content={props.actions()} trigger="hover"> 
              <FiMoreHorizontal className='appColor' />
            </PopoverWrapper>
          </Col> */}
            <Col
              span={24}
              style={{ cursor: "pointer" }}
              onClick={() =>
                typeof props.openDesc === "function"
                  ? props.openDesc(null, props.render, null, null, {
                      footer: null,
                    })
                  : null
              }
            >
              <p className="mr-b-md wordWrap">{`${props.description}`}</p>
            </Col>
          </Row>
        </Col>
        <Col span={typeof props.openDesc === "function" ? 24 : 18}>
          <Row gutter={14} type="flex" justify="center">
            {/* {!props.is_replied ? ( */}
              <Col span={18} style={{ textAlign: "center" }}>
                <Button
                  style={{ boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)" }}
                  sharpBorder
                  size={"large"}
                  isGreen={props?.is_replied === true ? true: false }
                  isDark={props?.is_replied === true ? false: true }
                  block
                  onClick={() => props.openModal()}
                >
                  {props.is_replied == true? 'Reply':'Reply Again'}
                </Button>
              </Col>
            {/* ) : null} */}
            <Col span={6}
            // span={!props.is_replied ? 6 : 20}
            >
              <PopconfirmWrapper
                title={<h4>Are you sure you want to delete this message?</h4>}
                // placement={"right"}
                icon={
                  <FiAlertTriangle
                    style={{
                      position: "absolute",
                      color: "#f44336",
                      left: 0,
                      top: 0,
                      marginTop: 6,
                    }}
                  />
                }
                cancelText="No"
                okText="Yes"
                onConfirm={() => {
                  props.MessageDelRequest(props.message_id, () => {
                    props.handleDelete();
                  });
                }}
              >
                <Button
                  sharpBorder
                  outlined
                  size="large"
                  block
                  className="dangerBtn"
                  style={
                    !props.is_replied
                      ? null
                      : { boxShadow: "0px 2px 5px 1px rgba(0,0,0,0.15)" }
                  }
                >
                  <FiTrash className="textCenter" />
                </Button>
              </PopconfirmWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default connect((state) => ({ messageState: state.Messages }), {
  MessageDelRequest,
})(MessageBox);
