import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  changePasswordSuccess,
  changePasswordError,
  contactSuccess,
} from "../actions/App";
import {
  CHANGE_PASSWORD_REQUEST,
  CONTACT_REQUEST,
} from "../configurations/Types";
import {
  CHANGE_PASSWORD_ENDPOINT,
  // FAQS_ENDPOINT,
  CONTACT_ENDPOINT,
  USERS_URL,
  API_BASE_URL,
} from "../configurations/Constants";
import info from "../components/message/index";
import {
  privateAgent,
  publicAgent,
  CancelToken,
} from "../configurations/AxiosAgent";
import moment from "moment";

const changePasswordApi = (body) => {
  if (token) {
    token.cancel();
  }
  token = CancelToken.source();
  return privateAgent.post(`${API_BASE_URL}${CHANGE_PASSWORD_ENDPOINT}`, {
    ...body,
  });
};

const contactUsApi = (body, token) => {
  //console.log("contactUsApi", body, token);
  return publicAgent.post(`${USERS_URL}${CONTACT_ENDPOINT}`, body, {
    cancelToken: token.token,
  });
};

// ...

// function* test() {
//   yield 10;
//   return 99;
// }

let token;

// Our worker Saga: will perform the async increment task
export function* changePassword({ body, cb }) {
  const token = JSON.parse(localStorage.getItem("revicebeat_adminauth")).token;
  let res;
  try {
    const properBody = {
      oldPassword: body.password,
      newPassword: body.new_password,
    };
    res = yield changePasswordApi(properBody, token);
    info("success", res?.data?.data?.message);
    yield put(changePasswordSuccess(res));
    cb() && cb();
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(changePasswordError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

export function* contactEmail(action) {
  // console.log("CONTACT_US", action);
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    let res = yield contactUsApi({ ...action.body }, token);
    yield put(contactSuccess());
    // console.log("res: ", res);
    info("success", `${res.data.data.message}`);
    if (action.cb) {
      action.cb();
    }
  } catch (err) {
    if (err.response) {
      //console.log("ERROR", err);
      info("error", `${err.response.data.error.message}`);
    }
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchAppActions() {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeLatest(CONTACT_REQUEST, contactEmail);
}
