import React from "react";
import styled, { css } from "styled-components";
//import {primaryColor} from "../configurations/theme"
import { Button } from "antd";
// import propTypes from "prop-types";

const StyledButton = styled(Button).attrs({ className: "button" })`
  color: ${(props) =>
    props.outlined
      ? props.theme[props.theme.mode].primary.main
      : props.isDark
      ? props.theme[props.theme.mode].common.white
      : props.simple
      ? props.theme[props.theme.mode].primary.main
      : props.theme[props.theme.mode].primary.main
    };
  background: ${(props) => 
     props.outlined
      ? `transparent`
      : props.isGreen
      ? props.theme[props.theme.mode].background.green
      : props.isDark
      ? props.theme[props.theme.mode].primary.main
      : props.theme[props.theme.mode].background.main
  };
  //box-shadow: 0 2px 0 ${(props) =>
    props.theme[props.theme.mode].primary.main};
  box-shadow: ${(props) =>
    props.outlined
      ? css`inset 0 0 0 2px ${(props) =>
          props.theme[props.theme.mode].primary.main}`
      : `none`};
  height: auto;
  font-weight: 700;

  line-height: 1.3;

  border-radius: ${(props) => (props.sharpBorder ? "5px" : "10px")};

  //padding: ${(props) =>
    props.outlined ? `calc(.596vw - 2px)` : `0.596vw`} ${(props) =>
    !props.autoWidthSubmit && !props.block ? `0.5vw` : 0};
  padding: calc(0.7vw + 1px)
    ${(props) => (!props.autoWidthSubmit && !props.block ? `0.5vw` : 0)};
  min-width: ${(props) =>
    !props.autoWidthSubmit && !props.block ? `8rem` : "auto"};
  ${(props) => (props.autoWidthSubmit ? `width: 50%` : null)}
  outline: none;
  border: none;
  //border: ${(props) =>
    props.outlined
      ? `2px solid ${props.theme[props.theme.mode].primary.main}66`
      : `none`};
  text-transform: "";
  // font-style: italic;
  &.iconBtn {
    min-width: auto;
    padding: 1rem;
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.subHeading};
    display: inline-flex;
    justify-content: center;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
  }
  &.dangerBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1rem !important;
    //font-size: 1.313rem !important;

    color: ${(props) => props.theme[props.theme.mode].primary.main};
    background: ${(props) => props.theme[props.theme.mode].common.white};
    box-shadow: inset 0px 0px 0px 2px
      ${(props) => props.theme[props.theme.mode].primary.main};
  }
  &.dangerBtn&:hover,
  &.dangerBtn&:active,
  &.dangerBtn&:focus {
    color: ${(props) => props.theme[props.theme.mode].textColor.inverted};
    background: ${(props) => props.theme[props.theme.mode].primary.main};
  }
  &:hover,
  &:active,
  &:focus {
    color: ${(props) =>
      props.outlined
        ? props.theme[props.theme.mode].primary.main
        : props.isGreen
        ? props.theme[props.theme.mode].primary.main
        : props.simple
        ? props.theme[props.theme.mode].common.white
        : props.theme[props.theme.mode].common.white};
    background: ${(props) => 
       props.outlined
        ? `transparent`
        : props.isGreen
        ? props.theme[props.theme.mode].background.green
        : props.isDark
        ? props.theme[props.theme.mode].primary.main
        :''
    };
    //background: ${(props) =>
      props.outlined
        ? `transparent`
        : props.theme[props.theme.mode].primary.main};
    //linear-gradient(90deg, ${(props) =>
      props.theme[props.theme.mode].primary.main} 0%,
    //${(props) => props.theme[props.theme.mode].secondary.main} 100%);
    //border: ${(props) =>
      props.outlined
        ? `2px solid ${props.theme[props.theme.mode].primary.main} !important`
        : `none`};
    //padding: ${(props) =>
      props.outlined ? `calc(.596vw - 1px)` : `0.596vw`} ${(props) =>
      !props.autoWidthSubmit && !props.block ? `0.5vw` : 0};
    box-shadow: ${(props) =>
      props.outlined
        ? css`inset 0 0 0 2px ${(props) =>
            props.theme[props.theme.mode].primary.main}`
        : `none`};

    outline: none;
    border: none;
  }
  @media screen and (max-width: 991px) {
    padding: calc(0.5rem + 1px) ${(props) => (!props.block ? "0.5rem" : 0)};
    //padding: .5rem 0;
    &.iconBtn {
      font-size: calc(
        ${(props) =>
            props.theme[props.theme.mode].textSizes.relative.subHeading} - 4px
      );
    }
    /* &:hover, &:active, &:focus {
      padding: .5rem ${(props) => (!props.block ? "4rem" : 0)};
    } */
  }
  @media screen and (max-width: 767px) {
    &.iconBtn {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.subHeading};
    }
  }
`;

const ButtonWrapper = (props) => {
  return <StyledButton {...props}></StyledButton>;
};

// ButtonWrapper.propTypes = {
//   children: propTypes.string.isRequired,
//   block: propTypes.bool.isRequired,
//   onClick: propTypes.func
// };

export default ButtonWrapper;
