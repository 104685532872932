import {
  USERS_SUCCESS,
  USER_REQUEST,
  USER_SUCCESS,
  USER_ERROR,
  CLEAR_USER,
  //INSTA_USER_SUCCESS,
  USERS_REQUEST,
  USERS_ERROR,
  GET_USER_EMAIL_SUCCESS
} from "../configurations/Types";

const INITIAL_STATE = {
  totalCount: 0,
  users: [],
  profileDetails: null,
  profileLoading: false,
  count: 0,
  userEmails:[]
};

export default (state = INITIAL_STATE, action) => {
  console.log(action.userData, "actionpayload");
  switch (action.type) {
    case USERS_SUCCESS: {
      const {users, count} = action?.userData
      // let arr = userData;
      // if (action.ofPage) {
      //   let { page, pageSize } = action.ofPage;
      //   let newUsers = [...state.users];
      //   newUsers.splice(
      //     page * pageSize,
      //     newUsers.length - page * pageSize,
      //     ...arr
      //   );

      //   arr = newUsers;
      // }

      return Object.assign({}, state, {
        totalCount: count,
        // count: count,
        users,
        profileLoading: false,
      });
    }

    case GET_USER_EMAIL_SUCCESS: {
      const{data} = action.payload
      return Object.assign({}, state, {
        userEmails:data?.data
      });
    }

    case USERS_REQUEST: {
      return Object.assign({}, state, { profileLoading: true });
    }
    // case USERS_SUCCESS:{
    //   return Object.assign({}, state, { users: action.payload, profileLoading: false });
    // }
    // case INSTA_USER_SUCCESS: {
    //   console.log("INSTA DETAILS", action.payload)
    //   return Object.assign({}, state, {instaDetails: action.payload})
    // }
    case USER_REQUEST: {
      return Object.assign({}, state, { profileLoading: true });
    }
    case USER_SUCCESS: {
      return Object.assign({}, state, {
        profileDetails: action.payload,
        profileLoading: false,
      });
    }
    case USER_ERROR: {
      return Object.assign({}, state, { profileLoading: false });
    }
    case CLEAR_USER: {
      return Object.assign({}, state, { profileDetails: null });
    }
    case USERS_ERROR: {
      console.log("Error");
      return Object.assign({}, state, { totalCount: 0, users: [] });
    }

    default: {
      return state;
    }
  }
};
