import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must contains @ and .")
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Email is not valid!"
    )
    .required("Email Required"),

  // password: Yup.string()
  //   // .min(6, "Password is Too Short! it must be between 6 and 16 characters")
  //   // .max(16, "Password is Too Long! it must be between 6 and 16 characters")
  //   //  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase and one lowercase.')
  //   .matches(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,"Password must contain at least 8 characters, one uppercase, one lower case, and one number")
  //   .required("Password Required")
});
