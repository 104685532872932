import React from "react";
import { FiAlertTriangle } from "react-icons/fi";
import Image from "../../../../components/image";
import { Row, Col, Skeleton } from "antd";
import CardWrapper from "../../../../components/card";
import SwitchWrapper from "../../../../components/switch";
import PopconfirmWrapper from "../../../../components/pop-confirm";
import TagWrapper from "../../../../components/tag";
import Blip from "../../../shares/blip";
import styled, { withTheme } from "styled-components";

import EmptyProfilePic from "../../../../assets/images/empty-profile.png";

const StyledCardWrapper = styled(CardWrapper)`
  .pictureDiv {
    height: 10rem;
  }

  @media screen and (max-width: 767px) {
    .pictureDiv {
      height: 30vw;
    }
  }
`;

const TravellerCard = (props) => {
  return (
    <StyledCardWrapper>
      <Skeleton active avatar paragraph={{ rows: 3 }} loading={props.loading}>
        <Row
          //style={{ marginTop: 12 }}
          gutter={10}
          type="flex"
          align="bottom"
        >
          <Col span={9} className="pictureDiv">
            {/* <div
            className="imgSection"
              style={{
                display:"inline-block",
                //height: 130,
                width: "100%"
              }}
            > */}
            <Image
              className="borderRound fitImage"
              src={props.imageURL || EmptyProfilePic}
              alt="Profile Picture"
            />
            {/* </div> */}
          </Col>

          <Col span={15}>
            <Row type="flex" align="middle">
              <Col className="infoDiv" span={20}>
                <div
                  style={{
                    //display: "inline-flex",
                    flexWrap: "wrap",
                    //whiteSpace: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ display: "inline-block" }} className="no-mr">
                    {props.name}&nbsp;
                  </h4>
                </div>

                <a
                  href={`mailto:${props.email}`}
                  className="hintColor"
                  style={{ wordBreak: "break-word", fontWeight: "600" }}
                >
                  {props.email}
                </a>
              </Col>

              <Col span={4} className="textRight">
                <p className="appColor fontBold">${props.amount || "0"}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Skeleton>
    </StyledCardWrapper>
  );
};

export default withTheme(TravellerCard);
