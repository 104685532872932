import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading";
import { Row, Col, Table,Avatar } from "antd";
import RowCard from "../../../appcomponents/shares/organization/RowCard";
import SubscriptionUpdate from "../../../appcomponents/shares/organization/SubscriptionUpdate";
import Icon from "../../../components/Icon";
import { openModal, closeModal, closeAllModal } from "../../../actions/Modal";
import Input from "../../../components/input";
import useFormik from "../../../components/formik/useFormik";
import { changeUserStatusRequest } from "../../../actions/Users";
import { clearRequests } from "../../../actions/Requests";
import { organizationDetailRequest } from "../../../actions/Organization";
import TableWrapper from "../../../components/react-table";
import EmptyProfilePic from "../../../assets/images/empty-profile.png";
import AvatarWrapper from "../../../components/avatar/index";
import { FiEdit2 } from "react-icons/fi";
import { formattedDate } from "../../../configurations/Config";
import moment from 'moment'
import '../../../index.css'

const OrganizationDetails = (props) => {
  console.log('OrganizationDetails_props: ', props);
  const [current, setCurrent] = useState(1)
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  });

  const dispatch = useDispatch();
  const data = useSelector(state =>{
    return state
  })
  console.log('OrganizationDetails_data: ', data);

  const formik = useFormik({
    //validationSchema: LawyerSchema,
    initialValues: {
      lawyer_title: "",
      lawyer_name: "",
      lawyer_email: "",
    },
    onSubmit: (values, { ...rest }) => {
      const { enable, ...data } = values;
      // if (location.pathname === "/dashboard/lawyers/add-lawyer") {
      //   dispatch(
      //     addLawyerRequest(data, () => {
      //       history.push("/dashboard/lawyers");
      //     })
      //   );
      // } else {
      //   dispatch(
      //     updateLawyerRequest(data, () => {
      //       history.push("/dashboard/lawyers");
      //     })
      //   );
      // }
      // dispatch(fetchLawyersRequest());
    },
  });

  const field = useCallback(
    (placeholder, name, value, customProps) => (
      <>
        <Input
          style={{ marginBottom: "1%", marginTop: "5px" }}
          placeholder={placeholder}
          value={value}
          name={name}
          disabled
          onChange={(e) => {
            //console.log(e.target.value, 'inside onChange func of ', name)
            formik.customHandleChange(e, name);
          }}
          onBlur={(e) => formik.customHandleBlur(e, name)}
          {...customProps}
        />
        <span className="dangerColor caption textCenter">
          {formik.touched[name] && formik.errors[name]}
        </span>
      </>
    ),
    [formik]
  );

  useEffect(() => {
    let organizationId = props?.location?.state?.organizationId;
    if (organizationId) {
      dispatch(organizationDetailRequest(organizationId))
      // props.organizationDetailRequest(organizationId);
    } else {
      props.history.push("/organization");
    }
  }, []);

  // const organization = props?.organizationState?.organizationDetail;
  const organization = data?.Organization?.organizationDetail
  console.log(organization, "oooooooooooooooooooo");

  const {orgSubscription,organizationMembers} = organization
  console.log('organizationMembers: ', organizationMembers);
  console.log('orgSubscription: ', orgSubscription);

  const loading = props?.organizationState?.loading;

  // let firstDate = moment(orgSubscription?.startDate, 'YYYY-MM-DD'); //Create date using string-format constructor
  // let secondDate = moment(orgSubscription?.endDate, 'YYYY-MM-DD');
  // let duration = moment.duration(secondDate.diff(firstDate));
  // let year= duration.asYears();
  // let yearDiff = Math.round(year)
  // console.log('DateDifference',yearDiff > 1? true:false)


  let firstDate = moment(
    orgSubscription?.startDate,); //Create date using string-format constructor
  let secondDate = moment(
    orgSubscription?.endDate);
  
      console.log('Date===>secondDate: ', secondDate);
      console.log('Date===>firstDate: ', firstDate);


  let yearDiff = secondDate.diff(firstDate, 'years');
  console.log('duration: ', yearDiff);



  const usersList = organizationMembers?.length

  const columns = [
    {
      title: "",
      dataIndex: "imgThumbUrl",
      key: "imgThumbUrl",
      render: (text, record) => (
        <Row>
          <Avatar
              src={
                record?.user?.imgThumbUrl
              }
              status={record.status}
            />
        </Row>
      )
    },
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",
      render: (text, record) => (
        <Row>
          <span>{record?.user?.userName}</span>
        </Row>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <Row>
          <span>{record?.user?.email}</span>
        </Row>
      )
    }
  ];

const numberOfUser = organization?.organizationMembers?.filter(item => {
  return  item?.isDeleted != true
})

  return true ? (
    organization ? (
      <>
        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <Link to="/organization">
              <Icon
                type="arrow-left"
                style={{ fontSize: "15px", color: "#C3F64D" }}
              />
            </Link>
            <h4 style={{ marginLeft: 10, marginTop: 5 }}> Profile Details</h4>
          </div>
        </Col>

        <Col
          style={{
            paddingLeft: "8%",
            paddingRight: "8%",
            paddingTop: 30,
            paddingBottom: 50,
          }}
        >
          <div>
            <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {" "}
              Details{" "}
            </h3>
            <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
              <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                <Col
                  style={{ marginBottom: "15px" }}
                  xl={12}
                  lg={12}
                  sm={24}
                  xs={24}
                  span={12}
                >
                  <Col span={24}>
                    <label className="mr-b-sm">Full Name</label>
                    {field(
                      organization?.name||'-',
                      "user name",
                      formik.values[organization?.name]
                    )}
                  </Col>
                </Col>

                <Col
                  style={{ marginBottom: "15px" }}
                  xl={12}
                  lg={12}
                  sm={24}
                  xs={24}
                  span={12}
                >
                  <Col span={24}>
                    <label>Email</label>
                    {field(
                      organization?.email||'-',
                      "user email",
                      formik.values[organization?.email]
                    )}
                  </Col>
                </Col>
              </Row>

              <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                <Col
                  style={{ marginBottom: "15px" }}
                  xl={12}
                  lg={12}
                  sm={24}
                  xs={24}
                  span={12}
                >
                  <Col span={24}>
                    <label>Phone Number</label>
                    {field(
                      organization?.phone||"-",
                      "phone",
                      formik.values[organization?.phone],
                      {
                        onChange: (e) => {
                          formik.customHandleChange(e, "email");
                          // here you dispatch your api for checking the email
                        },
                      }
                    )}
                  </Col>
                </Col>

                <Col
                  style={{ marginBottom: "15px" }}
                  xl={12}
                  lg={12}
                  sm={24}
                  xs={24}
                  span={12}
                >
                  <Col span={24}>
                    <label>Location</label>
                    {field(
                      organization?.location||"-",
                      "location",
                      formik.values[organization?.location],
                      {
                        onChange: (e) => formik.customHandleChange(e, "email"),
                      }
                    )}
                  </Col>
                </Col>
              </Row>

              <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>

                <Col
                  style={{ marginBottom: "15px" }}
                  xl={12}
                  lg={12}
                  sm={24}
                  xs={24}
                  span={12}
                >
                  <Col span={24}>
                    <label>Subscription Status</label>
                    {field(
                      organization?.status||"-",
                      "user_status",
                      formik.values[organization?.status],
                      {
                        onChange: (e) => {
                          formik.customHandleChange(e, "email");
                          // here you dispatch your api for checking the email
                        },
                      }
                    )}
                  </Col>
                </Col>
                <Col
                  style={{ marginBottom: "15px" }}
                  xl={12}
                  lg={12}
                  sm={24}
                  xs={24}
                  span={12}
                >
                  <Col span={24}>
                    <label>Organization Name</label>
                    {field(
                      organization?.orgName||"-",
                      "organizationName",
                      formik.values[organization?.orgName],
                      {
                        onChange: (e) => {
                          formik.customHandleChange(e, "email");
                          // here you dispatch your api for checking the email
                        },
                      }
                    )}
                  </Col>
                </Col>

              </Row>

            </Row>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Subscription Details
              <FiEdit2
                onClick={() =>
                  props.openModal(
                    "",

                    <SubscriptionUpdate
                      //  closeSelf={}
                      organizationId={organization.id}
                      pricePerUser={organization.pricePerUser}
                      allowedUsers={organization.usersLimit}
                      subscriptionPlanId={organization.subscriptionPackage}
                      subscription={organization?.status}
                      organization={organization?.orgName}
                    />,
                    null,
                    null,
                    { footer: null }
                  )
                }
                fill
                size={14}
                style={{ marginLeft: 10, cursor: "pointer" }}
              />
            </h3>

            <div style={{ marginTop: "20px" }}>
              <Row
                style={{ height: 60 }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <RowCard
                  loading={loading}
                  title={"Plan"}
                  value=
                  {
                  //   `${organization?.subscriptionPackage} - £ ${
                  //   organization?.usersLimit * organization?.pricePerUser
                  // } / ${yearDiff} ${yearDiff > 1 ? `years` : `year`} `

                    `${organization?.subscriptionPackage || ''}
                    - £
                    ${organization?.usersLimit * organization?.pricePerUser || ''}  
                    ${yearDiff <! 0?'/':'/'}                            
                ${yearDiff} ${yearDiff > 1 ? `years` : `year`} `}
    
                  style={{ length: "20px" }}

                />
              </Row>
              <br />
              <Row
                style={{ height: 60 }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <RowCard
                  loading={loading}
                  title={"Expiring"}
                  value={
                    `${orgSubscription?.endDate?
                      moment(orgSubscription?.endDate?orgSubscription?.endDate:'').format("DD-MMM-YYYY")
                      :'-'
                    }`
                  }
                  style={{ length: "20px" }}
                />
              </Row>
              <br />
              <Row
                style={{ height: 60 }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <RowCard
                  loading={loading}
                  title={"Billed On"}
                  value={
                    `${orgSubscription?.startDate?
                      moment(orgSubscription?.startDate).format(
                      "DD-MMM-YYYY"
                    ):'-'
                  } `
                  }
                  style={{ length: "20px" }}
                />
              </Row>
              <br />
              <Row
                style={{ height: 60 }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <RowCard
                  loading={loading}
                  title={"Numbers of Users"}
                  value={
                    organization?.usersLimit
                      ? `${numberOfUser.length||'0'}/${organization?.usersLimit || '0'} `
                      : "-"
                  }
                  style={{ length: "20px" }}
                />
              </Row>
              <br/>
              <Row
                style={{ height: 60 }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <RowCard
                  loading={loading}
                  title={"Price per user"}
                  value={
                       `${organization?.pricePerUser||'0'} £`
                  }
                  style={{ length: "20px" }}
                />
              </Row>
            </div>
          </div>

          <div style={{ marginTop: "30px" }}>
            <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {`Member List: ${usersList || '0'}`}
            </h3>
            <Col style={{ marginTop: "12px" }} span={24}>

            <Table
            className="orgDetails_table"
            // rowKey="id"
            dataSource={organization?.organizationMembers}
            columns={columns}
            pagination={false}
            loading={loading&&loading}
          />





              {/* Old Code */}
              {/* <TableWrapper
                loading={props?.organizationState?.loading}
                tableData={organization?.organizationMembers}
                pageSize={usersList}
                dataCount={usersList}
                getTrProps={(state, rowInfo, column) => ({
                  //   onClick: () => props.history.push({
                  //     pathname: `/users/${rowInfo.original.user_id}`,
                  //     state: {
                  //       referrer: "/users",
                  //       user: rowInfo.original,
                  //     },
                  //   }),
                  //   style: { cursor: "pointer" },
                })}
                getTdProps={(state, rowInfo, column, c, d) => {
                  return column.className === "clickable"
                    ? {
                        onClick: (e) => e.stopPropagation(),
                        style: { cursor: "default", overFlow: "hidden" },
                      }
                    : {};
                }}
                content={[
                  {
                    name: "",
                    id: "imgThumbUrl",
                    centered: true,
                    small: true,
                    sortable: false,
                    render: (r) => {
                      console.log('r.original',r);
                      return (
                        <AvatarWrapper
                          shape="circle"
                          size="middle"
                          src={r?.original?.user["imgThumbUrl"] || EmptyProfilePic}
                        ></AvatarWrapper>
                      );
                    },
                  },
                  {
                    name: "Name",
                    id: "fullName",
                    render: (r) => {
                      return <span>{r?.original?.user?.userName}</span>;
                    },
                  },
                  { name: "Email", id: "email", sortable: false, render: (r) => {
                    return <span>{r?.original?.user?.email}</span>;
                  }, }
                ]}
              /> */}
              {/* Old Code */}
            </Col>
          </div>
          {/* <Col span={24} style={{display:'flex', justifyContent:'center'}}>
          <Pagination
          responsive="true"
          showLessItems="true"
          className="tbl-pagination"
          current={current}
          pageSize={10}
          total={organizationMembers?.length || 0}
          onChange={(e) => {
            console.log("Page number is:", e);
            handlePages(e);
          }}
        />
          </Col> */}
        </Col>
      </>
    ) : (
      <Redirect to="/" />
      // <div style={{ margin: "30vh 0", textAlign: "center" }}>
      //   <Empty description="User not found" />
      // </div>
    )
  ) : (
    <Loading />
  );
};

const mapStateToProps = (state) => ({ organizationState: state.Organization });
export default connect(mapStateToProps, {
  changeUserStatusRequest,
  clearRequests,
  organizationDetailRequest,
  openModal,
  closeModal,
})(OrganizationDetails);
