import React from "react";
import { connect } from "react-redux";
import { Row, Col, Progress } from "antd";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import { Link } from "react-router-dom";
import Button from "../../components/button/index";
import CodeWrapper from "../../components/input/Code";
import { withTheme } from "styled-components";
import { verificationCodeRequest, resendCodeRequest } from "../../actions/Auth";

class VerificationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props?.location?.state?.email,
      code: "",
      error: "",
      timer: false,
      countDown: 60,
      timerId: null,
      renderCodeInput: false,
    };
  }
  // ***------- temporary comment just because server is not working --------****
  // componentDidMount() {
  //   if (this.props.history.location.state != undefined) {
  //     if (this.props.history.location.state.email != undefined) {
  //       this.setState({
  //         email: this.props.history.location.state.email,
  //       });
  //     } else {
  //       this.props.history.push({
  //         pathname: "/login",
  //       });
  //     }
  //   } else {
  //     this.props.history.push({
  //       pathname: "/login",
  //     });
  //   }
  // }
  componentDidMount(){
    this.setState({timer:true})
    var id = setInterval(() => {
      if (this.state.countDown === 1) {
        this.setState({ timer: false, countDown: 60 });
        clearInterval(this.state.timerId);
      } else {
        this.setState({ countDown: this.state.countDown - 1 });
      }
    }, 1000);

    this.setState({ timerId: id, timer: true });
  }
  handleErrors = () => {
    let error = "";
    if (this.state.code.length == 0) {
      error = "Please enter the code ";
    } else if (this.state.code.length < 4 && this.state.code.length > 0) {
      error = "Incomplete Code ";
    } else {
      error = "";
    }
    this.setState({ error: error });
    return error;
  };
  handleChanged = (val) => {
    this.setState({
      code: val,
    });
  };
  redirectNext = () => {
    this.props.history.push({
      pathname: "/reset-password",
      state: {
        email: this.state.email,
      },
    });
  };
  apiCall = () => {
    const email = this.props?.location?.state?.email
    const isValid = this.handleErrors();
    if (isValid == "") {
      this.props.dispatch(
        verificationCodeRequest(
          {
            email,
            otp: this.state.code,
          },
          this.redirectNext
        )
      );
    } else {
      console.log("Error");
    }
  };
  reRenderCodeInput = () => {
    if (this.state.renderCodeInput) {
      return (
        <React.Fragment>
          <CodeWrapper
            key="1"
            value=""
            renderCodeInput={this.state.renderCodeInput}
            className="mr-b"
            type="tel"
            fields="4"
            onChange={this.handleChanged}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <CodeWrapper
            key="2"
            value=""
            renderCodeInput={this.state.renderCodeInput}
            className="mr-b"
            type="tel"
            fields="4"
            onChange={this.handleChanged}
          />
        </React.Fragment>
      );
    }
  };
  resendApiCall = () => {
    const email = this?.props?.location?.state?.email
    this.setState({ code: "", renderCodeInput: !this.state.renderCodeInput });
    var id = setInterval(() => {
      if (this.state.countDown === 1) {
        this.setState({ timer: false, countDown: 60 });
        clearInterval(this.state.timerId);
      } else {
        this.setState({ countDown: this.state.countDown - 1 });
      }
    }, 1000);

    this.setState({ timerId: id, timer: true });
    this.props.dispatch(
      resendCodeRequest({ email }, () => {
        console.log("Reset Fields");
      })
    );
  };

  render() {
  console.log('forget_props', this.props)
    console.log("Verification Code State", this.state);
    console.log("Verification Code State", this.state.code.length);
    console.log("Verification Code Props", this.props);
    const bottomLink = {
      text: `Didn't receive a code? Resend`,
      linkTo: "login",
      onClick: this.resendApiCall,
      isHidden: this.state.timer?true:false
    };
    return (
      <AuthContainer bootomLink={bottomLink}>
        <Row type="flex" justify="center" align="middle" gutter={[10, 30]}>
          <Col span={24}>
            <h3 className="mr-t mr-b fontBold">Reset Your Password</h3>
            <p>
            Please enter the verification code that has been sent to your email.
            </p>
          </Col>
          <Col span={20}>
            {this.reRenderCodeInput()}
            <span
              className="small fontBold"
              style={{ display: "block", color: "#f44336" }}
            >
              {this.state.error}
            </span>
          </Col>
          {/* <Col span={18}>
            <span style={{ color: "#f44336" }}>{this.state.error}</span>
          </Col> */}
          <Col span={13}>
            <Button
              loading={this.props.Auth.forgotPasswordLoading}
              onClick={() => {
                this.apiCall();
              }}
              className="mr-t-lg mr-b-md bg-gradient"
              block
            >
              Verify
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
          {
          this.state.timer == 
          true && (<>
            <p>You can resend code after</p>
            <Progress
              width="50px"
              format={(per) => this.state.countDown}
              type="circle"
              strokeColor={{
                // "0%": this.props.theme[this.props.theme.mode].background.green,
                "100%": this.props.theme[this.props.theme.mode].primary.main,
              }}
              percent={(this.state.countDown / 60) * 100}
              />
              </>
          )
          // : (
          //   <Link onClick={() => this.resendApiCall()} className="card-link-out">
          //     <p
          //       className="small"
          //       style={{
          //         textDecoration: "none",
          //         display: "inline",
          //         cursor: "default",
          //         // color: this.props.theme[this.props.theme.mode].textColor.primary
          //         background: "#AD1E23",
          //       }}
          //       onClick={(e) => {
          //         e.preventDefault();
          //         e.stopPropagation();
          //       }}
          //     >
          //       Didn't receive a code?&nbsp;
          //     </p>
          //     <span style={{ textDecoration: "underline" }}>Resend Code</span>
          //   </Link>
          // )
        }
          </Col>
        </Row>
        
      </AuthContainer>
    );
  }
}

export default withTheme(
  connect((storeState) => ({ Auth: storeState.Auth }))(VerificationCode)
);
