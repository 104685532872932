import {
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  ORGANIZATION_ERROR,
  ADD_ORGANIZATION_REQUEST,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_ERROR,
  ORGANIZATION_DETAIL_REQUEST,
  ORGANIZATION_DETAIL_SUCCESS,
  ORGANIZATION_DETAIL_ERROR,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_ERROR,

UPDATE_ORGANIZATION_SUBSCRIPTION_REQUEST,
UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
UPDATE_ORGANIZATION_SUBSCRIPTION_ERROR,

  // FLAGGED_DEL_ERROR,
  // FLAGGED_DEL_SUCCESS,
  // FLAGGED_DEL_REQUEST,
  // FLAGGED_BAN_REQUEST,
  // FLAGGED_BAN_SUCCESS,
  // FLAGGED_BAN_ERROR,
} from "../configurations/Types";

export function organizationRequest(payload,callback) {
  return {
    type: ORGANIZATION_REQUEST,
    payload,
    callback,
  };
}

export function organizationReceived(payload) {
  return {
    type: ORGANIZATION_SUCCESS,
    payload,
  };
}

export function organizationError(errorInfo) {
  return {
    type: ORGANIZATION_ERROR,
    payload: errorInfo,
  };
}

export function addOrganizationRequest(body, cb) {
  console.log('addOrganizationRequest_action: ', body);
  return {
    type: ADD_ORGANIZATION_REQUEST,
    body,
    callback: cb,
  };
}

export function addOrganizationSuccess(organizationData) {
  return {
    type: ADD_ORGANIZATION_SUCCESS,
    payload: organizationData,
  };
}

export function addOrganizationError(errorInfo) {
  return {
    type: ADD_ORGANIZATION_ERROR,
    payload: errorInfo,
  };
}

export function organizationDetailRequest(param, cb) {
  return {
    type: ORGANIZATION_DETAIL_REQUEST,
    param,
    callback: cb,
  };
}

export function organizationDetailRecieved(organizationData) {
  return {
    type: ORGANIZATION_DETAIL_SUCCESS,
    payload: organizationData,
  };
}

export function organizationDetailError(errorInfo) {
  return {
    type: ORGANIZATION_DETAIL_ERROR,
    payload: errorInfo,
  };
}

export function userDetailRequest(param, cb) {
  return {
    type: USER_DETAIL_REQUEST,
    param,
    callback: cb,
  };
}

export function userDetailRecieved(userData) {
  return {
    type: USER_DETAIL_SUCCESS,
    payload: userData,
  };
}

export function userDetailError(errorInfo) {
  return {
    type: USER_DETAIL_ERROR,
    payload: errorInfo,
  };
}

export function updateOrganizationRequest(param, cb) {
  return {
    type: UPDATE_ORGANIZATION_REQUEST,
    param,
    callback: cb,
  };
}

export function updateOrganizationSuccess(organizationData) {
  return {
    type: UPDATE_ORGANIZATION_SUCCESS,
    payload: organizationData,
  };
}

export function updateOrganizationError(errorInfo) {
  return {
    type: UPDATE_ORGANIZATION_ERROR,
    payload: errorInfo,
  };
}

export function updateOrgSubscription(body, cb) {
  return {
    type: UPDATE_ORGANIZATION_SUBSCRIPTION_REQUEST,
    // body,
    body,
    cb,
  };
}

export function updateOrgSubscription_Success(organizationData) {
  return {
    type: UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
    payload: organizationData,
  };
}

export function updateOrgSubscription_Error(errorInfo) {
  return {
    type: UPDATE_ORGANIZATION_SUBSCRIPTION_ERROR,
    payload: errorInfo,
  };
}

// export function deleteFlaggedRequest(id, cb) {
//   return {
//     type: FLAGGED_DEL_REQUEST,
//     id: id,
//     callback: cb
//   }
// }
// export function deleteFlaggedReceived(deleteMsg) {
//   return {
//     type: FLAGGED_DEL_SUCCESS,
//     payload: deleteMsg,
//   }
// }
// export function deleteFlaggedError(errorInfo) {
//   return {
//     type: FLAGGED_DEL_ERROR,
//     payload: errorInfo
//   }
// }

// export function banFlaggedRequest(id, cb) {
//   return {
//     type: FLAGGED_BAN_REQUEST,
//     id: id,
//     callback: cb
//   }
// }
// export function banFlaggedReceived(deleteMsg) {
//   return {
//     type: FLAGGED_BAN_SUCCESS,
//     payload: deleteMsg,
//   }
// }
// export function banFlaggedError(errorInfo) {
//   return {
//     type: FLAGGED_BAN_ERROR,
//     payload: errorInfo
//   }
// }
