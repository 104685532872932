import React from "react";
import { connect } from "react-redux";
import Input from "../../../components/input/index";
import { Link } from "react-router-dom";
// import RootForm from "../../components/form/index";
import RootForm from "../../../components/form/index";
// import { resetPassword } from "../../configurations/Schemas/index";
import { changePassword } from "../../../configurations/Schemas/index";
// import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import AuthContainer from "../../../appcomponents/shares/auth/AuthContainer";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../../actions/App";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      new_password: "",
      confirm_password: "",
    };
  }
  componentDidMount() {
    let user = this?.props?.Auth?.profileDetails;
    this.setState({ email: user.email });
    // if (this.props.history.location.state != undefined) {
    //   console.log("State location history",this.props.history.location.state);
    //   if (this.props.history.location.state.email != undefined) {
    //     console.log("Email Not Undefined");
    //     this.setState({
    //       email: this.props.history.location.state.email
    //     });
    //   }
    //   else{
    //     this.props.history.push({
    //       pathname: "/login",
    //     });
    //   }
    // }
    // else{
    //   this.props.history.push({
    //     pathname: "/login",
    //   });
    // }
  }
  handleChanged = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <AuthContainer>
        <Row type="flex" justify="center" align="middle" gutter={[10, 30]}>
          <Col span={24}>
            <h3 className="mr-b mr-t appColor">Change Your Password</h3>
            <p className="caption">
              Choose a strong new password. Make sure it's unique.
            </p>
          </Col>
          <Col xs={24} sm={22}>
            <RootForm
              schemaName={changePassword}
              initialValues={this.state}
              apiCall={changePasswordRequest}
              extras={this.state.email}
              loading={this.props.Auth.forgotPasswordLoading}
              cb={() => {
                this.props.history.push({
                  pathname: "/login",
                });
              }}
              //autoWidthSubmit
              controls={[
                {
                  span: 24,
                  name: "password",
                  value: this.state.password,
                  handleChanged: this.handleChanged,
                  placeholder: "Current Password",
                  Type: Input,
                },
                {
                  span: 24,
                  name: "new_password",
                  value: this.state.new_password,
                  handleChanged: this.handleChanged,
                  placeholder: "New Password",
                  Type: Input,
                },
                {
                  span: 24,
                  name: "confirm_password",
                  value: this.state.confirm_password,
                  handleChanged: this.handleChanged,
                  placeholder: "Confirm Password",
                  Type: Input,
                },
              ]}
              submitButton={{
                span: 14,
                buttonText: "Continue",
                className: "mr-t-md bg-gradient",
              }}
            />
          </Col>
            <Col span={24}>
              <Link to="/users">
                <p>Go Back</p>
              </Link>
            </Col>
        </Row>
      </AuthContainer>
    );
  }
}

export default connect((storeState) => ({ Auth: storeState.Auth }))(
  ChangePassword
);
