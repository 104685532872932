import * as Yup from "yup";


export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
      .required("Current Password Required"),
      new_password: Yup.string()
      // .min(6, "Password is Too Short! it must be between 6 and 16 characters")
      // .max(16, "Password is Too Long! it must be between 6 and 16 characters")
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase and one lowercase.')
      .matches(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,"Password must contain at least 8 characters, one uppercase, one lower case, and one number")
      .required("New Password Required"),
  
      confirm_password: Yup.mixed().oneOf(
      [Yup.ref('new_password')], 'Password do not match!'
      )
      .required("Confirm Password Required"),
  })