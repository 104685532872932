import React from "react";
import propTypes from "prop-types";
import {
  Area,
  Bar,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
} from "recharts";
import { withTheme } from "styled-components";

class renderComposedChart extends React.Component {
  state = {
    updateXAxis: false,
  };

  // componentWillUpdate(nextProps) {

  //   if (this.props.data !== nextProps.data) {
  //     this.setState({ updateXAxis: !this.state.updateXAxis })
  //   }
  // }

  render() {
    const appDownloads=[]
    this.props.data.forEach(item=>{
      appDownloads.push({
              name: item.name,
              android: item.Price.android,
              ios: item.Price.ios
          })
  })
    // const data = [
    //   {
    //     name: "Aug",
    //     val: 4000,
    //     newLine: 1304,
    //   },
    //   {
    //     name: "Sept",
    //     val: 3000,
    //     newLine: 1404,
    //   },
    //   {
    //     name: "Oct",
    //     val: 2000,
    //     newLine: 1204,
    //   },
    //   {
    //     name: "Nov",
    //     val: 2780,
    //     newLine: 1204,
    //   },
    // ];
    // var obj;
    // if (this.props.data[0])
    //   obj = Object.keys(this.props.data[0]);

    return (
      <div>
        <ComposedChart
          width={this.props.width}
          height={this.props.height}
          data={appDownloads}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <CartesianGrid stroke="#f5f5f5" /> */}
          {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
          {/* <Bar dataKey="pv" barSize={20} fill="#413ea0" /> */}
          <Line type="monotone" dataKey="android" stroke="#151D30" />
          <Line type="monotone" dataKey="ios" stroke="#C3F64D" />

          {/* <Line type="monotone" dataKey="pv" stroke="#ff7300" /> */}
        </ComposedChart>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: 15,
              width: 15,
              borderRadius: 15,
              background: "#C3F64D",
              marginRight: 10,
            }}
          />
          <p> ios</p>
          <div
            style={{
              height: 15,
              width: 15,
              borderRadius: 15,
              background: "black",
              marginLeft: 10,
              marginRight: 10,
            }}
          />
          <p>android</p>
        </div>
      </div>
    );
  }
}

renderComposedChart.defaultProps = {
  width: "100%",
  height: 300,
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  lineType: "monotone",
  isCartReq: false,
  cartStroke: "#f0f0f0",
  cartDash: 5,
  isTooltip: true,
};

renderComposedChart.propTypes = {
  //   data: propTypes.array.isRequired,
  lineColor: propTypes.array.isRequired,
};
export default withTheme(renderComposedChart);
