import React from "react";
import { connect } from "react-redux";
import { Modal } from "antd";

import { closeModal } from "../../actions/Modal";

const BasicModal = (props) => {
  return (
    <Modal
      centered
      closable
      zIndex={1009}
      className={props.className}
      maskStyle={{ filter: "blur(100%)", background: "#ffffff80" }}
      // title={props.title}
      keyboard={true}
      visible={props.visible}
      onOk={() => props.dispatch(closeModal())}
      onCancel={() => props.dispatch(closeModal())}
      okText={props.okText}
      cancelText={props.cancelText}
      {...props}
    >
      {props.content}
    </Modal>
  );
};

export default connect()(BasicModal);
