import React from "react";
import Card from "../../components/card";
import Icon from "../../components/Icon";
import styled, { withTheme } from "styled-components";

const StyledCard = styled(Card)``;

const FloatingCard = (props) => {
  //let { children, ...rest } = props;
  return (
    <StyledCard
      {...props}
      //extra={<Icon style={{ cursor: "pointer" }} onClick={props.onClose} type="close"/>}
    >
      <div>
        <Icon
          style={{
            cursor: "pointer",
            position: "absolute",
            right: props.theme[props.theme.mode].spacing.inner,
            top: props.theme[props.theme.mode].spacing.inner,
            zIndex: 10,
          }}
          onClick={props.onClose}
          type="close"
        />
        {props.children}
      </div>
    </StyledCard>
  );
};

export default withTheme(FloatingCard);
