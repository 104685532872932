import { message } from "antd";

message.config({
  maxCount: 1,
  duration: 2,
});

const info = (action, messageText, duration) => {
  if (action === "info") {
    message.info(messageText, duration);
  } else if (action === "error") {
    message.error(messageText, duration);
  } else if (action === "success") {
    message.success(messageText, duration);
  } else if (action === "warning") {
    message.warning(messageText, duration);
  } else if (action === "warn") {
    message.warn(messageText, duration);
  } else if (action === "loading") {
    message.loading(messageText, duration);
  }
};
export default info;
