import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading";
import { Row, Col } from "antd";
import Icon from "../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Input from "../../../components/input";
import useFormik from "../../../components/formik/useFormik";
import { changeUserStatusRequest } from "../../../actions/Users";
import { clearRequests } from "../../../actions/Requests";
import RowCard from "../../../appcomponents/shares/organization/RowCard";
import { formattedDate } from "../../../configurations/Config";
import { userRequest } from "../../../actions/Users";
import moment from "moment";

const UserDetails = (props) => {
  var { user } = props.location.state ? props.location.state : {};
  const { full_name, email, status } = user ? user : {};
  const userId = props?.match?.params?.id;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const data = useSelector(state => {
    return state
  })

  const formik = useFormik({
    //validationSchema: LawyerSchema,
    initialValues: location.state || {
      lawyer_title: "",
      lawyer_name: "",
      lawyer_email: "",
    },
    onSubmit: (values, { ...rest }) => {
      console.log("Submitted: ", values, rest);
      const { enable, ...data } = values;
      // if (location.pathname === "/dashboard/lawyers/add-lawyer") {
      //   dispatch(
      //     addLawyerRequest(data, () => {
      //       history.push("/dashboard/lawyers");
      //     })
      //   );
      // } else {
      //   dispatch(
      //     updateLawyerRequest(data, () => {
      //       history.push("/dashboard/lawyers");
      //     })
      //   );
      // }
      // dispatch(fetchLawyersRequest());
    },
  });

  const field = useCallback(
    (placeholder, name, value, customProps) => (
      <>
        <Input
          style={{ marginBottom: "1%", marginTop: "5px" }}
          placeholder={placeholder}
          value={value}
          name={name}
          disabled
          onChange={(e) => {
            //console.log(e.target.value, 'inside onChange func of ', name)
            formik.customHandleChange(e, name);
          }}
          onBlur={(e) => formik.customHandleBlur(e, name)}
          {...customProps}
        />
        <span className="dangerColor caption textCenter">
          {formik.touched[name] && formik.errors[name]}
        </span>
      </>
    ),
    [formik]
  );

  useEffect(() => {
    dispatch(userRequest(userId));
  }, []);

  // user = props.userState;
  // console.log("PROPS",formattedDate(props?.userState?.organization_member?.organization?.owner?.subscriptions?.length?.start_date));
  const { profileDetails } = data?.Users;
  console.log("profileDetails: ", profileDetails);

  let firstDate = moment(
    profileDetails?.userSubscription?.startDate); //Create date using string-format constructor
  let secondDate = moment(
      profileDetails?.userSubscription?.endDate);
  
      console.log('Date===>secondDate: ', secondDate);
      console.log('Date===>firstDate: ', firstDate);


  let yearDiff = secondDate.diff(firstDate, 'years',true).toFixed(1);
  let monthDiff = secondDate.diff(firstDate, 'months',true).toFixed(1);

  console.log('duration:Year ', yearDiff);
  console.log('duration:Month ', monthDiff);


  // const dateData = showDate()
  // console.log('dateData: ', dateData);
  var a=''
  switch(profileDetails?.subscriptionPlanDetails?.duration){

    case 'M' :
      a='1 Month';
      break;
      case 'D':
        a='3 Days'
        break;
        case 'Y':
          a=yearDiff >= 1 || yearDiff == 0 ? ` year` :` years`;
          break;
          default :
          a=a


  }
  console.log('element==>',a)
  return (
    <Row>
      <Col span={24}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <Link to="/users">
            <Icon
              type="arrow-left"
              style={{ fontSize: "15px", color: "#C3F64D" }}
            />
          </Link>
          <h4 style={{ marginLeft: 10, marginTop: 5 }}> Profile Details</h4>
        </div>
      </Col>
      <Col
        style={{
          padding: "0px 50px",
          marginTop: "30px",
        }}
      >
        <Row gutter={[10, 10]} style={{ marginTop: "10px" }}>
          <Col span={24}>
            <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {" "}
              Details{" "}
            </h3>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              {profileDetails?.status !== 4 && (
                <>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label className="mr-b-sm">Full Name</label>
                    {field(
                      profileDetails?.fullName || "-",
                      "user name",
                      formik.values[profileDetails?.fullName]
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label className="mr-b-sm">Email</label>
                    {field(
                      profileDetails?.email || "-",
                      "user email",
                      formik.values[profileDetails?.email]
                    )}
                  </Col>
                </>
              )}
              {profileDetails?.type == "ORGANIZATION" ? 
                <>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label className="mr-b-sm">Phone Number</label>
                    {field(
                      profileDetails?.organization?.phone || "-",
                      "phone",
                      formik.values[profileDetails?.organization?.phone]
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label className="mr-b-sm">Location</label>
                    {field(
                      profileDetails?.organization?.location || "-",
                      "location",
                      formik.values[profileDetails?.organization?.location]
                    )}
                  </Col>
                </>: ''
              }
              <Col xs={24} sm={24} md={12} lg={12}>
                <label className="mr-b-sm">User Type</label>
                {field(
                  profileDetails?.type || "-",
                  "types",
                  formik.values[profileDetails?.type]
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <label className="mr-b-sm">User Status</label>
                {field(
                  //  profileDetails?.status||'-',
                  // profileDetails?.status == 1 ? "Subscribed" : "free" && "-",
                  profileDetails?.status == 1
                    ? "Active"
                    : profileDetails?.status == 4
                    ? "Deleted"
                    : "In-Active" && "-",

                  // profileDetails?.isSubscribed == false && profileDetails?.freeTrial == 0
                  // ? 'Pending'
                  // :profileDetails?.freeTrial == 1 && profileDetails?.isSubscribed == false
                  // ?'Free Trial'
                  // : profileDetails?.isSubscribed == true
                  // ?'Subscribed'
                  // :'-',
                  "status",
                  formik.values[profileDetails?.status && "-"]
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        { profileDetails?.status !== 4 &&
        <Row gutter={[10, 10]} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Subscription Details
            </h3>
          </Col>
          <Col span={24}>
            <RowCard
              // loading={loading}
              title={"Expiring"}
              value={`
              ${profileDetails?.userSubscription?.endDate?
                moment(profileDetails?.userSubscription?.endDate).format(
                  "DD-MMM-YYYY"
                ) :'-'
              }`
            }
              style={{ length: "20px" }}
            />
          </Col>
          <Col span={24}>
            <RowCard
              // loading={loading}
              title={"Billed On"}
              value={
                `${
                profileDetails?.userSubscription?.startDate?
                moment(profileDetails?.userSubscription?.startDate).format(
                  "DD-MMM-YYYY"
                ) : "-"
              }`}
              style={{ length: "20px" }}
            />
          </Col>
          <Col span={24}>
            <RowCard
              // loading={loading}
              title={"Plans"}
              // value={
              //   `${profileDetails?.subscriptionPlanDetails?.name || '-'}`
              // }
              value={
                `${profileDetails?.subscriptionPlanDetails?.name || ''}
                - 
                 ${profileDetails?.subscriptionPlanDetails?.price || ''}  
                 /
                 ${a}
                 `
                }
                // ${yearDiff <! 0?yearDiff:''} ${yearDiff && yearDiff >= 1 || yearDiff == 0 ? ` year` :` years`} 
                 
                //  ${yearDiff <! 0?'/':'/'}                            
              style={{ length: "20px" }}
            />
          </Col>
          
        </Row>
        }
      </Col>
    </Row>
  );
  // true ? (
  //   user ? (
  //     <>
  //       <Col>
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignItems: "center",
  //             justifyItems: "center",
  //           }}
  //         >
  //           <Link to="/users">
  //             <Icon
  //               type="arrow-left"
  //               style={{ fontSize: "15px", color: "#AD1E23" }}
  //             />
  //           </Link>
  //           <h4 style={{ marginLeft: 10, marginTop: 5 }}> Profile Details</h4>
  //         </div>
  //       </Col>

  //       <Col
  //         style={{
  //           paddingLeft: "8%",
  //           paddingRight: "8%",
  //           paddingTop: 30,
  //           paddingBottom: 50,
  //         }}
  //       >
  //         <div>
  //           <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
  //             {" "}
  //             User Details{" "}
  //           </h3>
  //           <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
  //             <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
  //               <Col
  //                 style={{ marginBottom: "15px" }}
  //                 xl={12}
  //                 lg={12}
  //                 sm={24}
  //                 xs={24}
  //                 span={12}
  //               >
  //                 <Col span={24}>
  //                   <label className="mr-b-sm">Name</label>
  //                   {field(
  //                     user.user_name,
  //                     "user name",
  //                     formik.values[user.user_name]
  //                   )}
  //                 </Col>
  //               </Col>

  //               <Col
  //                 style={{ marginBottom: "15px" }}
  //                 xl={12}
  //                 lg={12}
  //                 sm={24}
  //                 xs={24}
  //                 span={12}
  //               >
  //                 <Col span={24}>
  //                   <label>Email</label>
  //                   {field(user.email, "user email", formik.values[user.email])}
  //                 </Col>
  //               </Col>
  //             </Row>

  //             {user.type === "organization" ? (
  //               <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
  //                 <Col
  //                   style={{ marginBottom: "15px" }}
  //                   xl={12}
  //                   lg={12}
  //                   sm={24}
  //                   xs={24}
  //                   span={12}
  //                 >
  //                   <Col span={24}>
  //                     <label>Phone Number</label>
  //                     {field(user.phone, "phone", formik.values[user.phone], {
  //                       onChange: (e) => {
  //                         formik.customHandleChange(e, "email");
  //                         // here you dispatch your api for checking the email
  //                       },
  //                     })}
  //                   </Col>
  //                 </Col>

  //                 <Col
  //                   style={{ marginBottom: "15px" }}
  //                   xl={12}
  //                   lg={12}
  //                   sm={24}
  //                   xs={24}
  //                   span={12}
  //                 >
  //                   <Col span={24}>
  //                     <label>Location</label>
  //                     {field(
  //                       user.location,
  //                       "location",
  //                       formik.values[user.location],
  //                       {
  //                         onChange: (e) => {
  //                           formik.customHandleChange(e, "email");
  //                           // here you dispatch your api for checking the email
  //                         },
  //                       }
  //                     )}
  //                   </Col>
  //                 </Col>
  //               </Row>
  //             ) : null}

  //             <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
  //               <Col
  //                 style={{ marginBottom: "15px" }}
  //                 xl={12}
  //                 lg={12}
  //                 sm={24}
  //                 xs={24}
  //                 span={12}
  //               >
  //                 <Col span={24}>
  //                   <label>User Type</label>
  //                   {field(user.type, "user_type", formik.values[user.type], {
  //                     onChange: (e) => {
  //                       formik.customHandleChange(e, "email");
  //                       // here you dispatch your api for checking the email
  //                     },
  //                   })}
  //                 </Col>
  //               </Col>

  //               <Col
  //                 style={{ marginBottom: "15px" }}
  //                 xl={12}
  //                 lg={12}
  //                 sm={24}
  //                 xs={24}
  //                 span={12}
  //               >
  //                 <Col span={24}>
  //                   <label>User Status</label>
  //                   {field(
  //                     user.status,
  //                     "use r_status",
  //                     formik.values[user.status],
  //                     {
  //                       onChange: (e) => {
  //                         formik.customHandleChange(e, "email");
  //                         // here you dispatch your api for checking the email
  //                       },
  //                     }
  //                   )}
  //                 </Col>
  //               </Col>
  //             </Row>
  //           </Row>
  //         </div>

  //         <div style={{ marginTop: "20px" }}>
  //           <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
  //             Subscription Details
  //           </h3>

  //           <div style={{ marginTop: "20px" }}>
  //             <Row
  //               style={{ height: 60 }}
  //               xs={12}
  //               sm={12}
  //               md={12}
  //               lg={12}
  //               xl={12}
  //             >
  //               <RowCard
  //                 // loading={loading}
  //                 title={"Plan"}
  //                 value={
  //                   props.userState.type === "organization member"
  //                     ? props.userState?.organization_member?.organization
  //                         ?.owner?.subscriptions[0].product_name
  //                     : props?.userState?.subscriptions.length
  //                     ? props?.userState?.subscriptions[0]?.product_name
  //                     : "No Plan"
  //                 }
  //                 organizationName={
  //                   props?.userState?.organization_member?.organization?.name
  //                 }
  //                 style={{ length: "20px" }}
  //               />
  //             </Row>
  //             <br />
  //             <Row
  //               style={{ height: 60 }}
  //               xs={12}
  //               sm={12}
  //               md={12}
  //               lg={12}
  //               xl={12}
  //             >
  //               <RowCard
  //                 // loading={loading}
  //                 title={"Expiring"}
  //                 value={
  //                   props.userState.type === "organization member"
  //                     ? formattedDate(
  //                         props?.userState?.organization_member?.organization
  //                           ?.owner?.subscriptions[0].end_date
  //                       )
  //                     : formattedDate(
  //                         props?.userState?.subscriptions[0]?.end_date
  //                       )
  //                 }
  //                 style={{ length: "20px" }}
  //               />
  //             </Row>
  //             <br />
  //             <Row
  //               style={{ height: 60 }}
  //               xs={12}
  //               sm={12}
  //               md={12}
  //               lg={12}
  //               xl={12}
  //             >
  //               <RowCard
  //                 // loading={loading}
  //                 title={"Billed On"}
  //                 // value={props?.userState?.subscriptions.length ? formattedDate(user?.subscription?.end_date) : ""}
  //                 value={
  //                   props.userState.type === "organization member"
  //                     ? formattedDate(
  //                         props?.userState?.organization_member?.organization
  //                           ?.owner?.subscriptions[0].start_date
  //                       )
  //                     : formattedDate(
  //                         props?.userState?.subscriptions[0]?.start_date
  //                       )
  //                 }
  //                 style={{ length: "20px" }}
  //               />
  //             </Row>
  //             <br />
  //           </div>
  //         </div>
  //       </Col>
  //     </>
  //   ) : (
  //     <Redirect to="/" />
  //   )
  // ) : (
  //   <Loading />
  // );
};
export default UserDetails;
// const mapStateToProps = (state) => ({
//   userState: state.Organization.userDetail,
// });

// export default connect(mapStateToProps, {
//   changeUserStatusRequest,
//   clearRequests,
//   userDetailRequest,
// })(UserDetails);

// export default connect(mapStateToProps, {
//   changeUserStatusRequest,
//   clearRequests,
//   organizationDetailRequest,
//   openModal,
//   closeModal,
// })(OrganizationDetails);
