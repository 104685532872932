import {
  BUY_SUBSCRIPTION_REQUEST,
  BUY_SUBSCRIPTION_SUCCESS,
  BUY_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  SET_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
} from "../configurations/Types";

//Buy subscription
export const buySubscriptionRequest = (
  generatePaymentMethod,
  params,
  callback
) => {
  return {
    type: BUY_SUBSCRIPTION_REQUEST,
    generatePaymentMethod,
    params,
    callback,
  };
};

export const buySubscriptionSuccess = () => {
  return {
    type: BUY_SUBSCRIPTION_SUCCESS,
  };
};

export const buySubscriptionError = () => {
  return {
    type: BUY_SUBSCRIPTION_ERROR,
  };
};

export const getSubscriptionStatus = () => {
  return {
    type: GET_SUBSCRIPTION_REQUEST,
  };
};

export const getSubscriptionStatusSuccess = () => {
  return {
    type: GET_SUBSCRIPTION_SUCCESS,
  };
};

export const getSubscriptionStatusError = () => {
  return {
    type: GET_SUBSCRIPTION_ERROR,
  };
};

export const setSubscriptionStatus = (status) => {
  return {
    type: SET_SUBSCRIPTION,
    status,
  };
};

export const cancelSubscriptionRequest = () => {
  return {
    type: CANCEL_SUBSCRIPTION_REQUEST,
  };
};

export const cancelSubscriptionSuccess = () => {
  return {
    type: CANCEL_SUBSCRIPTION_SUCCESS,
  };
};

export const cancelSubscriptionError = () => {
  return {
    type: CANCEL_SUBSCRIPTION_ERROR,
  };
};
