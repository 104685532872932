import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const StyledInput = styled(Input).attrs({ className: "inputField" })`

&[type], input {
  
    background-color: ${(props) =>
      props?.backgroundColor ||
      props.theme[props.theme.mode].background.primary};
    color:  ${(props) =>
      props?.isSearch?
      props.theme[props.theme.mode].textColor.secondary:
      props.theme[props.theme.mode].textColor.primary
    };
    border:${(props) => (props.border ? props.border : `1px solid #717070`)};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : `5px`}
    width:${(props) => props?.width};
    height:${(props) => props?.height};
    cursor:${(props) => props?.cursor};
    padding: ${(props) =>
      props?.padding ? props?.padding : `1vw 1vw`} !important;
    margin-bottom:${(props) => props?.marginBottom};
}
outline:none;
&[type]:hover, input:hover {
  border: ${(props) => (props.border ? props.border : undefined)};
  border-color: ${(props) =>
    props.theme[props.theme.mode].primary.main} !important;
}
&[type]:focus, input:focus {
  border: ${(props) => (props.border ? props.border : undefined)};
  border-color: ${(props) =>
    props.theme[props.theme.mode].primary.main} !important;
  box-shadow: 0 0 0 2px ${(props) =>
    props.theme[props.theme.mode].primary.main}66;
}
* {
  color: #b6dc60;
}

&.lightBg {
    background-color: rgba(255, 255, 255, 0.5);
}
input::placeholder {
  color: ${(props) => (
    props.isSearch ? '#FFFFFF': '#151D30'
    )} !important;
}
`;

const InputWrapper = (props) => {
  return props.password ? (
    <StyledInput as={Input.Password} {...props}></StyledInput>
  ) : (
    <StyledInput {...props}></StyledInput>
  );
};

export default InputWrapper;
