import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  ORGANIZATION_REQUEST,
  ADD_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_REQUEST,
  ORGANIZATION_DETAIL_REQUEST,
  USER_DETAIL_REQUEST,
  UPDATE_ORGANIZATION_SUBSCRIPTION_REQUEST
} from "../configurations/Types";
import {
  ORGANIZATION_ENDPOINT,
  UPDATE_ORGANIZATION_SUBS_ENDPOINT,
  USERS_ENDPOINT,
  USER_DETAIL_ENDPOINT,
} from "../configurations/Constants";
import {
  organizationReceived,
  organizationError,
  organizationRequest,
  addOrganizationRequest,
  addOrganizationSuccess,
  addOrganizationError,
  organizationDetailRecieved,
  organizationDetailError,
  updateOrganizationSuccess,
  updateOrganizationError,
  userDetailRecieved,
  userDetailError,
} from "../actions/Organization";
import info from "../components/message/index";
import moment from "moment";
// import { tableData } from "../mocks/Users"
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
// const flaggedAPI = (ms) => new Promise((res, rej) => setTimeout(() => res(tableData), ms))
const organizationAPI = (params, token) =>
  privateAgent.get(ORGANIZATION_ENDPOINT, {
    cancelToken: token.token,
    params: { ...params },
  });
const addOrganizationAPI = (body) => {
  return privateAgent.post(`${ORGANIZATION_ENDPOINT}`, {
    ...body,
  });
};
const organizationDetailAPI = (params) =>
  privateAgent.get(`${ORGANIZATION_ENDPOINT}/${params}`);

const userDetailAPI = (params) =>
  privateAgent.get(`${USER_DETAIL_ENDPOINT}/${params}`);

const updateOrganizationAPI = (param, body) => {
  console.log("updateOrganizationAPI: ", param, body);
  return privateAgent.patch(`${ORGANIZATION_ENDPOINT}/${param}`, {
    isActive: body,
  });
};

const updateOrgSubscriptionApi = ({values, id}) => {
  return privateAgent.post(`${UPDATE_ORGANIZATION_SUBS_ENDPOINT}/${id}`, {
    pricePerUser:values?.pricePerUser,
    subscriptionPackage:values?.subscriptionPlanId,
    usersLimit:values?.allowedUsers
  });
};
let token;

// Our worker Saga: will perform the async flaggedRequest task
function* organizationGetRequest({ payload, callback }) {
  let res;
  try {
    token && token.cancel();
    token = CancelToken.source();
    res = yield organizationAPI(payload, token);
    if (res.status == 200) {
      yield put(organizationReceived(res.data.data));
    }
  } catch (e) {
    console.log("ERROR_org", e);
    try {
      info(
        "error",
        `${e.response ? e.response.data?.error?.message : "Network error"}`
      );
    } catch (e) {}
    yield put(organizationError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  callback && callback(res);
}

function* organizationAddRequest(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield addOrganizationAPI(action.body);
    info("success", `Organization has been added successfully`);
    yield put(addOrganizationSuccess(res));
  } catch (e) {
    console.log("ERROR", e.response);
    try {
      info(
        "error",
        `${e.response ? e.response.data.error.message : "Network error"}`
      );
    } catch (e) {}
    yield put(addOrganizationError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

function* organizationDetailRequest({param, callback}) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield organizationDetailAPI(param, token);
    yield put(organizationDetailRecieved(res.data.data));
  } catch (e) {
    console.log("ERROR", e);
    try {
      info(
        "error",
        `${e.response ? e.response.data.message : "Network error"}`
      );
    } catch (e) {}
    yield put(organizationDetailError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (callback) {
    callback(res);
  }
}

function* userDetailRequest(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield userDetailAPI(action.param, token);
    yield put(userDetailRecieved(res.data.data));
  } catch (e) {
    console.log("ERROR", e);
    try {
      info(
        "error",
        `${e.response ? e.response.data.message : "Network error"}`
      );
    } catch (e) {}
    yield put(userDetailError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

function* organizationUpdateRequest({ param, callback }) {
  const { isActive, userId } = param;
  let res;
  try {
    token && token.cancel();
    token = CancelToken.source();

    res = yield updateOrganizationAPI(userId, isActive);
    if (res.status === 200) {
      info("success", res?.data?.message);
      yield put(updateOrganizationSuccess(res.data.data));
      if(callback){callback()}
    }
  } catch (e) {
    console.log("ERROR", e.response);
    try {
      info(
        "error",
        `${e.response ? e.response.data.message : "Network error"}`
      );
    } catch (e) {}
    yield put(updateOrganizationError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  console.log("In saga", res);
  // if (body.callback) {
  //   body.callback(res);
  // }
}

function* updateOrgSubscription({body, cb}) {
  console.log('updateOrgSubscription_param: ', body);
  let res;
  try {
    token && token.cancel();
    token = CancelToken.source();

    res = yield updateOrgSubscriptionApi(body);
    console.log('updateOrgSubscription_res: ', res);
    if (res.status === 200) {
      info("success", res?.data?.message);
      yield put(updateOrganizationSuccess(res.data.data));
      if(cb){
        cb(res)
      }
    }
  } catch (e) {
    console.log("ERROR", e.response);
    try {
      info(
        "error",
        `${e.response ? e.response.data.message : "Network error"}`
      );
    } catch (e) {}
    yield put(updateOrganizationError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  console.log("In saga", res);
  if (body.callback) {
    body.callback(res);
  }
}

// Our watcher Saga: spawn a new flaggedRequest task on each FLAGGED_REQUEST
export function* watchGetOrganization() {
  yield takeLatest(ORGANIZATION_REQUEST, organizationGetRequest);
  yield takeLatest(ADD_ORGANIZATION_REQUEST, organizationAddRequest);
  yield takeLatest(ORGANIZATION_DETAIL_REQUEST, organizationDetailRequest);
  yield takeLatest(USER_DETAIL_REQUEST, userDetailRequest);
  yield takeLatest(UPDATE_ORGANIZATION_REQUEST, organizationUpdateRequest);
  yield takeLatest(UPDATE_ORGANIZATION_SUBSCRIPTION_REQUEST, updateOrgSubscription);
}
