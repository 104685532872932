import React from "react";
import { Popconfirm } from "antd";
import styled, { css } from "styled-components";

const popConfirmStyles = css`
  &.ant-popover {
    width: 24%;
    z-index: 2001;
  }
  & .ant-popover-buttons .ant-btn-sm {
    //font-style: italic;
    color: ${(props) => props.theme[props.theme.mode].primary.main};
    box-shadow: inset 0px 0px 0px 2px
      ${(props) => props.theme[props.theme.mode].primary.main};
    height: auto;
    padding: 0.3vw 2vw;
    border: none;
  }
  & .ant-popover-buttons .ant-btn-sm.ant-btn-primary {
    //font-style: italic;
    color: ${(props) => props.theme[props.theme.mode].common.dark};
    background: ${(props) => props.theme[props.theme.mode].background.green};
    box-shadow: 0 10px 5px -6px ${(props) => props.theme[props.theme.mode].primary.main}66;
    height: auto;
    padding: 0.3vw 2vw;
    border: none;
  }
  @media screen and (max-width: 1200px) {
    &.ant-popover {
      width: 26%;
    }
  }
  @media screen and (max-width: 991px) {
    &.ant-popover {
      width: 40%;
    }
  }
  @media screen and (max-width: 576px) {
    .ant-popover {
      width: 75%;
    }
  }
`;
const PopconfirmWrapper = (props) => {
  // console.log(props);
  return (
    <Popconfirm
      placement="bottom"
      overlayClassName={props.className}
      {...props}
    >
      {props.children}
    </Popconfirm>
  );
};

PopconfirmWrapper.defaultProps = {
  placement: "bottom",
};
export default styled(PopconfirmWrapper)`
  ${popConfirmStyles}
`;
