import {
  GET_CHARTS_ERROR,
  GET_CHARTS_REQUEST,
  GET_CHARTS_SUCCESS,
  CLEAR_CHARTS,
} from "../configurations/Types";

export function getChartsRequest(
  month,
  year,
  startDay,
  startMonth,
  startYear,
  endDay,
  endMonth,
  endYear,
  startDate,
  endDate,
  dateType,
  bankId,
  lawyerId,
  accountId,
  customerId,
  jobId,
  cb
) {
  return {
    type: GET_CHARTS_REQUEST,
    body: {
      date_type: "monthly",
      month,
      year,
      start_day: startDay,
      start_month: startMonth,
      start_year: startYear,
      end_day: endDay,
      end_month: endMonth,
      end_year: endYear,
      start_date: startDate,
      end_date: endDate,
      date_type: dateType,
      bank_account_id: bankId && parseInt(bankId),
      lawyer_id: lawyerId && parseInt(lawyerId),
      account_head_id: accountId && parseInt(accountId),
      customer_id: customerId && parseInt(customerId),
      job_id: jobId && parseInt(jobId),
    },
    cb,
  };
}

export function getChartsSuccess(payload, date_type, cb) {
  return {
    type: GET_CHARTS_SUCCESS,
    payload,
    date_type,
    cb,
  };
}

export function getChartsError(cb) {
  return {
    type: GET_CHARTS_ERROR,
    cb,
  };
}
export function clearCharts(cb) {
  return {
    type: CLEAR_CHARTS,
    cb,
  };
}
