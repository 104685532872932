import React from "react";
import { Popover } from "antd";
import styled, { css } from "styled-components";

const PopoverStyle = css`
color: ${(props) => props.theme[props.theme.mode].textColor.primary};
font-size: ${(props) => props.theme[props.theme.mode].textSizes.relative.body};
z-index: 2001;
letter-spacing: 0.021vw;
&.ant-popover {
${(props) =>
  props.fixed
    ? `
    position: fixed;
`
    : null};
}
&  .ant-popover-inner-content{
  padding:0
}
& .name {
  font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.relative.body};
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.021vw;
}
& .role {
  font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.relative.captions};
  font-weight: 400;
  letter-spacing: 0.0185vw;
}
& .white {
  color: ${(props) => props.theme[props.theme.mode].common.white};
}
& .ant-popover-arrow {
  display: none;
}
&.notificationsPopover .ant-popover-inner-content { 
  padding: 0 !important;
}
&.flaggedPopover .ant-popover-inner-content { 
  padding: 0 !important;
}
&.flaggedPopover .popoverItem {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 10px;
}
&.flaggedPopover .popoverItem:hover {
  background-color: ${(props) => props.theme[props.theme.mode].primary.light};
}
&.flaggedPopover .popoverItem:not(:last-child) {
  border: 1px solid ${(props) => props.theme[props.theme.mode].border};
}
& svg {
  font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.relative.generalHeading};
  color: ${(props) => props.theme[props.theme.mode].primary.main};
}
& svg.deleteIcon {
  font-size: 1.1rem;
}
& svg.SVGlarge {
  font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.relative.pageHeading};
}
/* .bottomBorder {
    border-bottom: 1px solid ${(props) =>
      props.theme[props.theme.mode].border}; 
} */

  & .profileDropdownItem {
    cursor: pointer;
    font-weight: 600;
    display: flex; 
    align-items: center; 
    padding: 10px 10px; 
  }

  & .logout, & .logout svg {
    color: ${(props) => props.theme[props.theme.mode].error.main};
  }

  .profileDropdownItem:not(:last-child) {
    border-bottom: 1px solid ${(props) =>
      props.theme[props.theme.mode].border}; 
  }

.ant-popover-title {
  color: ${(props) => props.theme[props.theme.mode].textColor.inverted};
  background-color: ${(props) => props.theme[props.theme.mode].primary.main};

  padding-left: 12px;
  padding-right: 12px;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

/* .ant-popover-inner-content {
  border-radius: 5px;
} */
@media screen and (max-width: 991px) {
  font-size: calc(${(props) =>
    props.theme[props.theme.mode].textSizes.relative.body} - 4px);
  letter-spacing: 0.4px;
  .name {
    font-size: calc(${(props) =>
      props.theme[props.theme.mode].textSizes.relative.body} - 4px);
    font-weight: 700;
    letter-spacing: 0.4px;
  }
  .role {
    font-size: calc(${(props) =>
      props.theme[props.theme.mode].textSizes.relative.captions} - 4px);
    font-weight: 400;
    letter-spacing: 0.38px;
  }
  & svg {
  font-size: calc(${(props) =>
    props.theme[props.theme.mode].textSizes.relative.generalHeading} - 4px);
}
& svg.deleteIcon {
  font-size: calc(${(props) =>
    props.theme[props.theme.mode].textSizes.relative.generalHeading} - 2px);
}
& svg.SVGlarge {
  font-size: calc(${(props) =>
    props.theme[props.theme.mode].textSizes.relative.pageHeading} - 4px);
}
@media screen and (max-width: 767px) {
  font-size: ${(props) => props.theme[props.theme.mode].textSizes.actual.body};
  .name {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.actual.body};
  }
  .role {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.actual.captions};
  }
  & svg {
  font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.actual.generalHeading};
}
& svg.deleteIcon {
  font-size: 1.1rem;
}
& svg.SVGlarge {
  font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.actual.pageHeading};
}
}
`;
const PopoverWrapper = (props) => {
  // console.log(props);
  return (
    <Popover
      overlayClassName={props.className}
      placement="bottomRight"
      trigger="hover"
      {...props}
      // trigger="click"
    >
      {props.children}
    </Popover>
  );
};
export default styled(PopoverWrapper)`
  ${PopoverStyle}
`;
