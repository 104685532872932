import {
  GET_USERS_SETTINGS_REQUEST,
  GET_USERS_SETTINGS_SUCCESS,
  GET_USERS_SETTINGS_ERROR,
  UPDATE_USERS_SETTINGS_REQUEST,
  UPDATE_USERS_SETTINGS_SUCCESS,
  UPDATE_USERS_SETTINGS_ERROR,
} from "../configurations/Types";

export const getUserSettingRequest = () => {
  return {
    type: GET_USERS_SETTINGS_REQUEST,
  };
};

export const getUsersSettingSuccess = (body, cb) => {
  return {
    type: GET_USERS_SETTINGS_SUCCESS,
    body,
    cb,
  };
};

export const getUsersSettingsError = (error) => {
  return {
    type: GET_USERS_SETTINGS_ERROR,
    error,
  };
};

export const updateUsersSettingsRequest = (body, cb) => {
  console.log("updateUsersSettingsRequest", body);
  return {
    type: UPDATE_USERS_SETTINGS_REQUEST,
    body,
    cb,
  };
};

export const updateUsersSettingsSuccess = () => {
  return {
    type: UPDATE_USERS_SETTINGS_SUCCESS,
  };
};
export const updateUsersSettingsError = () => {
  return {
    type: UPDATE_USERS_SETTINGS_ERROR,
  };
};
