import {
  ADD_PROMOCODE_REQUEST,
  ADD_PROMOCODE_SUCCESS,
  ADD_PROMOCODE_ERROR,
  GET_PROMOCODE_REQUEST,
  GET_PROMOCODE_ERROR,
  GET_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_ERROR,
  DELETE_PROMOCODE_REQUEST,
  EDIT_PROMOCODE_REQUEST,
  EDIT_PROMOCODE_SUCCESS,
  EDIT_PROMOCODE_ERROR,
  ACTIVE_INACTIVE_PROMOCODE_SUCCESS,
  ACTIVE_INACTIVE_PROMOCODE_ERROR,
  ACTIVE_INACTIVE_PROMOCODE_REQUEST,
  GET_PROMOCODE_USERS_REQUEST,
  GET_PROMOCODE_USERS_SUCCESS,
  GET_PROMOCODE_USERS_ERROR,
  GET_PROMOCODE_DETAILS_REQUEST,
GET_PROMOCODE_DETAILS_SUCCESS,
GET_PROMOCODE_DETAILS_ERROR,
} from "../configurations/Types";

export function getPromosRequest(params,cb) {
  return {
    type: GET_PROMOCODE_REQUEST,
    params,
    cb,
  };
}

export function getPromosSuccess(payload) {
  return {
    type: GET_PROMOCODE_SUCCESS,
    payload
  };
}

export function getPromosError(errorInfo) {
  return {
    type: GET_PROMOCODE_ERROR,
    payload: errorInfo,
  };
}

// GET PROMO CODE DETAILS ACTIONS STARTS
export function getPromosDetails(id,payload) {
  console.log('getPromosDetails_Action: ', payload);
  return {
    type: GET_PROMOCODE_DETAILS_REQUEST,
    id,
    payload,
  };
}
export function getPromosDetails_success(payload) {
  return {
    type: GET_PROMOCODE_DETAILS_SUCCESS,
    payload,
  };
}
export function getPromosDetails_error(payload) {
  return {
    type: GET_PROMOCODE_DETAILS_ERROR,
    payload,
  };
}
// GET PROMO CODE DETAILS ACTIONS ENDS

export function addPromoCodeRequest(body, cb) {
  return {
    type: ADD_PROMOCODE_REQUEST,
    body,
    callback: cb,
  };
}

export function addPromoCodeSuccess(payload) {
  return {
    type: ADD_PROMOCODE_SUCCESS
  };
}

export function addPromoCodeError(errorInfo) {
  return {
    type: ADD_PROMOCODE_ERROR,
    payload: errorInfo,
  };
}

export function updatePromoRequest(id, body, cb) {
  console.log("payload in saga action id ",id);
  console.log("payload in saga action body ",body);
  return {
    type: EDIT_PROMOCODE_REQUEST,
    body,
    id,
    cb,
  };
}

export function updatePromoSuccess(PromoData) {
  return {
    type: EDIT_PROMOCODE_SUCCESS
  };
}

export function updatePromoError(errorInfo) {
  return {
    type: EDIT_PROMOCODE_ERROR,
    payload: errorInfo,
  };
}

export function deletePromoRequest(id, cb) {
  return {
    type: DELETE_PROMOCODE_REQUEST,
    id,
    callback: cb,
  };
}

export function deletePromoSuccess() {
  return {
    type: DELETE_PROMOCODE_SUCCESS
  };
}

export function deletePromoError(errorInfo) {
  return {
    type: DELETE_PROMOCODE_ERROR,
    payload: errorInfo,
  };
}

export function toggleActivatePromoRequest(id,status, cb) {
  return {
    type: ACTIVE_INACTIVE_PROMOCODE_REQUEST,
    id,
    status,
    cb,
  };
}

export function toggleActivatePromoSuccess() {
  return {
    type: ACTIVE_INACTIVE_PROMOCODE_SUCCESS
  };
}

export function toggleActivatePromoError(errorInfo) {
  return {
    type: ACTIVE_INACTIVE_PROMOCODE_ERROR,
    payload: errorInfo,
  };
}

export function getPromoUsersRequest(id, promoPackage, status, dateFrom, dateTo, limit, offset, cb) {
  return {
    type: GET_PROMOCODE_USERS_REQUEST,
    id,
    params:  {
      package: promoPackage,
        status,
        dateFrom,
        dateTo,
        limit,
        offset
    },
    callback: cb,
  };
}

export function getPromocodeUsersSuccess(payload) {
  console.log("payload ",payload);
  return {
    type: GET_PROMOCODE_USERS_SUCCESS,
    payload
  };
}

export function getPromoUsersError(errorInfo) {
  return {
    type: GET_PROMOCODE_USERS_ERROR,
    payload: errorInfo,
  };
}