import React from "react";

import {
  put,
  take,
  takeLatest,
  fork,
  cancel,
  cancelled,
} from "redux-saga/effects";
import {
  REGISTER_REQUEST,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGOUT,
  PASSWORD_RESET_REQUEST,
  RESEND_CODE_REQUEST,
  VERIFY_TOKEN_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESEND_EMAIL,
  SIGNUP_VALIDATION_REQUEST,
  CHANGE_USER_PASSWORD_REQUEST,
CHANGE_USER_PASSWORD_SUCCESS,
CHANGE_USER_PASSWORD_ERROR,
} from "../configurations/Types";
import {
  registerSuccess,
  registerError,
  loginError,
  passwordResetSuccess,
  passwordResetError,
  resendCodeSuccess,
  resendCodeError,
  verificationCodeSuccess,
  verificationCodeError,
  changePasswordSuccess,
  changePasswordError,
  resendEmailRequest,
  signupValidationError,
  signupValidationSuccess,
  loginSuccess,
  changeUserPasswordRequest,
  changeUserPasswordSuccess,
  changeUserPasswordError
} from "../actions/Auth";
import info from "../components/message/index";
import VerifyAccountMessage from "../appcomponents/shares/auth/VerifyAccountMessage";
import { login } from "../utils/index";
import { store } from "../ConfigureStore";
import {
  REGISTER_ENDPOINT,
  LOGIN_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  VERIFY_CODE_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  VERIFY_EMAIL_ENDPOINT,
  // REGISTER_ENDPOINT,
  SIGNUP_VALIDATION_REQUEST_ENDPOINT,
  CHANGE_USER_PASSWORD,
} from "../configurations/Constants";

import {
  USERS_URL,
  API_BASE_URL_USERS,
  API_BASE_URL_USER,
  API_BASE_URL,
} from "../configurations/Constants";

import moment from "moment";

import {
  publicAgent,
  CancelToken,
  privateAgent,
} from "../configurations/AxiosAgent";

import { message } from "antd";

// const delay = ms => new Promise((res, rej) => setTimeout(() => res(5), ms));

const registerApi = (body) => {
  //console.log("registerApi", body);
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  return publicAgent.post(`${USERS_URL}`, {
    ...body,
    user_type: "user",
    // current_time:"2019-12-06 03:05:16"
    current_time,
  });
};

const signupValidationApi = (body) => {
  // console.log("signupValidationApi", body);
  return publicAgent.post(`${USERS_URL}${SIGNUP_VALIDATION_REQUEST_ENDPOINT}`, {
    email: body.email,
  });
};
const loginApi = ({ email, password }) => {
  const data = {
    email,
    password,
    // role: "admin",
  };

  return publicAgent.post(`${LOGIN_ENDPOINT}`, data, {
    baseURL: API_BASE_URL,
    headers: { "Content-Type": "application/json" },
  });
};

const resendEmailApi = (email) => {
  return publicAgent.post(`${API_BASE_URL}${VERIFY_EMAIL_ENDPOINT}`, {
    email,
  });
};
const passwordResetApi = (email) => {
  return publicAgent.post(
    `${FORGOT_PASSWORD_ENDPOINT}`,
    {
      email,
    },
    {
      baseURL: API_BASE_URL,
    }
  );
};
const resendCodeApi = (body) => {
  return publicAgent.post(
    `${FORGOT_PASSWORD_ENDPOINT}`,
    {
      ...body,
    },
    {
      baseURL: API_BASE_URL,
    }
  );
};
const verifyCodeApi = (body) => {
  // console.log("verifyCodeApi: ", body);
  return publicAgent.post(
    `${VERIFY_CODE_ENDPOINT}`,
    {
      ...body,
    },
    {
      baseURL: API_BASE_URL,
    }
  );
};
const resetPasswordApi = (body) => {
  return publicAgent.post(
    `${RESET_PASSWORD_ENDPOINT}`,
    {
      ...body,
    },
    {
      baseURL: API_BASE_URL,
    }
  );
};
// CHANGE USER PASSWORD START
const changeUserPasswordApi = (body) => {
  return publicAgent.post(
    `${CHANGE_USER_PASSWORD}`,
    {
      ...body,
    },
    {
      baseURL: API_BASE_URL,
    }
  );
};
// CHANGE USER PASSWORD ENDS
export function* register(action) {
  let res;
  try {
    //   console.log("FROM SAGA", action);
    let { errors, termsAndConditions, confirm_password, ...properBody } =
      action.body;
    // properBody.dob = moment(properBody.dob).format("YYYY-MM-DD");
    // console.log("EXTRACTED BODY", properBody);
    res = yield registerApi({ ...properBody, ...action.extras });
    // console.log("FROM SAGA 2: ", res);
    info("info", `Please verify your email so we can continue login`);
    yield put(registerSuccess(res));
    if (action.cb) action.cb();
  } catch (e) {
    // console.log("IN CATCH");
    try {
      // console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    } catch (e) {}
    yield put(registerError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
//signup validation saga
export function* signUpValidation(action) {
  let res;
  try {
    // console.log("From Saga signUpValidation");
    res = yield signupValidationApi(action.body);
    //console.log("signUpValidation", res);
    if (res.data.error.message === "Email already exists.") {
      info("error", `${res.data.error.message}`);
    }

    yield put(signupValidationSuccess(res));
  } catch (e) {
    yield put(signupValidationError(e));
    // info("warning", `${res.data.data.message}`);
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

export function* authorize(body, cb, extras) {
  //console.log(cb , "cb insagas hannan")
  // console.log('running')
  // console.log(body , "body hunain")
  // //  cb();

  let res;
  try {
    res = yield loginApi(body);
    if (res.status == 200) {
      login(res.data.data, cb);
      yield put(loginSuccess(res.data.data));
      message.success(res.data.data.message);
    }
  } catch (e) {
    const res = e.response;
    if (
      res.status === 400 &&
      res.data.message === "Please verify your email."
    ) {
      info(
        "info",
        <VerifyAccountMessage
          email={body.email}
          verifyRedirect={() => store.dispatch(resendEmailRequest(body.email))}
        />,
        10
      );
    } else {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(loginError(e));
  }
  // finally {
  //   if (yield cancelled()) {
  //     // console.log("CANCELLED");
  //   }
  // }
}

export function* resendEmail(action) {
  let res;
  try {
    res = yield resendEmailApi(action.payload);
    info("success", `Verification email has been resent`);
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
  }
}

export function* watchAuthentication() {
  while (true) {
    const { body, cb, extras } = yield take(LOGIN_REQUEST);
    //console.log("TEST", email, password)
    // fork return a Task object
    //test (uncommit later)
    const task = yield fork(authorize, body, cb, extras);
    //test end
    const action = yield take([LOGOUT, LOGIN_ERROR]);
    if (action.type === LOGOUT) yield cancel(task);
    //Clear local storage here or run callback
    //yield call(Api.clearItem, 'token')
  }
}

export function* passwordResetRequest({ body, cb, resend }) {
  let res;
  try {
    res = yield passwordResetApi(body?.email);
    if (res.status == 200) {
      info("success", res.data.data.message);
      yield put(passwordResetSuccess(res));
      cb() && cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(passwordResetError(e));
  } finally {
    if (yield cancelled()) {
      //console.log("CANCELLED");
    }
  }
}
export function* resendCodeRequest(action) {
  let res;
  try {
    res = yield resendCodeApi(action.body);
    //  console.log("FROM SAGA", res.data.data.message);
    info(
      "success",
      `Please check your inbox, Email has been sent with verification code`
    );
    yield put(resendCodeSuccess(res));
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      // console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    }
    yield put(resendCodeError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
export function* verificationCode(action) {
  let res;
  try {
    res = yield verifyCodeApi(action.body);
    yield put(verificationCodeSuccess(res));
    info("success", res?.data?.data?.message);
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response?.data?.error?.message}`);
    }
    yield put(verificationCodeError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
export function* changePassword({ body, cb }) {
  let res;
  try {
    res = yield resetPasswordApi(body);
    if (res.status == 200) {
      info("success", res?.data?.data?.message);
      cb() && cb();
      yield put(changePasswordSuccess(res));
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(changePasswordError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
// CHANGE USER PASSWORD START
export function* changeUserPassword( body, cb ) {
  const {data} = body
  const {email,new_password,password,otp} = data
  const objPayload = {
    email,newPassword:new_password, oldPassword:password,otp
  }
  let res;
  try {
    res = yield changeUserPasswordApi(objPayload);
    if (res.status == 200) {
      info("success", res?.data?.data?.message);
      cb() && cb();
      yield put(changeUserPasswordSuccess(res));
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(changeUserPasswordError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
// CHAGNE USER PASSWORD END

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchAuthActions() {
  yield fork(watchAuthentication);
  yield takeLatest(REGISTER_REQUEST, register);
  yield takeLatest(PASSWORD_RESET_REQUEST, passwordResetRequest);
  yield takeLatest(RESEND_CODE_REQUEST, resendCodeRequest);
  yield takeLatest(VERIFY_TOKEN_REQUEST, verificationCode);
  yield takeLatest(RESET_PASSWORD_REQUEST, changePassword);
  yield takeLatest(CHANGE_USER_PASSWORD_REQUEST, changeUserPassword);
  yield takeLatest(RESEND_EMAIL, resendEmail);
  yield takeLatest(SIGNUP_VALIDATION_REQUEST, signUpValidation);
  // yield takeLatest(LOGIN_REQUEST, authorize);
}
