//Left side bar menu content
import React from "react";
import { Menu } from "antd";
import { withRouter, Link } from "react-router-dom";
import MenuWrapper from "../menu";
import Image from "../../components/image";
import Logo from "../../assets/images/Vector-Smart-Object.png";

import HomeIcon from "../../assets/icons/navbar/dashboard-line2.svg";
import HomeIconLight from "../../assets/icons/navbar/dashboard-line-light.svg";

import UsersIcon from "../../assets/icons/navbar/group-line.svg";
import UsersIconLight from "../../assets/icons/navbar/group-line-light.svg";

import MessagesIcon from "../../assets/icons/navbar/subscription.svg";
import MessagesIconLight from "../../assets/icons/navbar/subscription-light.svg";

import FlagsIcon from "../../assets/icons/navbar/affiliate-marketing.svg";
import FlagsIconLight from "../../assets/icons/navbar/affiliate-marketing-light.svg";

import DiscountIcon from "../../assets/icons/navbar/discount.svg";
import DiscountIconLight from "../../assets/icons/navbar/discount-light.svg";

const LeftSideBar = (props) => {
  const path = props?.location?.pathname;

  return (
    <React.Fragment>
      <div style={{ textAlign: "center", padding: "0.64rem" }}>
        <Image src={Logo} style={{ width: "100%" }} />
      </div>

      <MenuWrapper
        style={{ marginTop: "7rem" }}
        defaultSelectedKeys={[props.location.pathname]}
      >
        <Menu.Item title="Home" className="sideBarItem" key="/">
          <Link to="/">
            {path === "/" ? (
              <Image width={20} src={HomeIcon} />
            ) : (
              <Image width={20} src={HomeIconLight} />
            )}
          </Link>
        </Menu.Item>
        <Menu.Item title="Users" className="sideBarItem" key="/users">
          <Link to="/users">
            {path === "/users" ? (
              <Image width={20} src={UsersIcon} />
            ) : (
              <Image width={20} src={UsersIconLight} />
            )}
          </Link>
        </Menu.Item>
        <Menu.Item title="Messages" className="sideBarItem" key="/messages">
          <Link to="/messages">
            {path === "/messages" ? (
              <Image width={20} src={MessagesIcon} />
            ) : (
              <Image width={20} src={MessagesIconLight} />
            )}
          </Link>
        </Menu.Item>
        <Menu.Item
          title="Organization"
          className="sideBarItem"
          key="/organization"
        >
          <Link to="/organization">
            {path === "/organization" ? (
              <Image width={20} src={FlagsIcon} />
            ) : (
              <Image width={20} src={FlagsIconLight} />
            )}
          </Link>
        </Menu.Item>
        <Menu.Item title="Promo Code" className="sideBarItem" key="/promocode">
          <Link to="/promocode">
            {path === "/promocode" ? (
              <Image width={20} src={DiscountIcon} />
            ) : (
              <Image width={20} src={DiscountIconLight} />
            )}
          </Link>
        </Menu.Item>
      </MenuWrapper>
    </React.Fragment>
  );
};
export default withRouter(LeftSideBar);
