import {
  MESSAGES_REQUEST,
  MESSAGES_SUCCESS,
  MESSAGES_ERROR,
  MESSAGE_DEL_REQUEST,
  MESSAGE_DEL_SUCCESS,
  MESSAGE_DEL_ERROR,
  MESSAGE_RESPONSE_REQUEST,
  MESSAGE_RESPONSE_SUCCESS,
  MESSAGE_RESPONSE_ERROR,   
} from "../configurations/Types";

export function MessagesRequest({limit, offset, search},cb) {
  return {
    type: MESSAGES_REQUEST,
    params: {
      limit,
      offset,
      search,
    },
    cb,
  };
}

export function MessagesSuccess(messagesData) {
  return {
    type: MESSAGES_SUCCESS,
    payload: messagesData,
  };
}

export function MessagesError(errorInfo) {
  return {
    type: MESSAGES_ERROR,
    payload: errorInfo,
  };
}
export function MessageDelRequest(id, cb) {
  return {
    type: MESSAGE_DEL_REQUEST,
    msgID: id,
    callback: cb,
  };
}

export function MessagesDelSuccess(messagesDelData) {
  return {
    type: MESSAGE_DEL_SUCCESS,
    payload: messagesDelData,
  };
}

export function MessageDelError(errorInfo) {
  return {
    type: MESSAGE_DEL_ERROR,
    payload: errorInfo,
  };
}
export function MessageResponseRequest(params, cb) {
  return {
    type: MESSAGE_RESPONSE_REQUEST,
    body: { ...params },
    callback: cb,
  };
}

export function MessageResponseSuccess(messageResponseData) {
  return {
    type: MESSAGE_RESPONSE_SUCCESS,
    payload: messageResponseData,
  };
}

export function MessageResponseError(errorInfo) {
  return {
    type: MESSAGE_RESPONSE_ERROR,
    payload: errorInfo,
  };
}
