import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  SIGNUP_VALIDATION_REQUEST,
  SIGNUP_VALIDATION_SUCCESS,
  SIGNUP_VALIDATION_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RESEND_EMAIL,
  LOGOUT,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  RESEND_CODE_REQUEST,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_HEADER_PROFILE_PICTURE,
  CHANGE_USER_PASSWORD_REQUEST,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
} from "../configurations/Types";

export function registerRequest(body, cb, extras) {
  return {
    type: REGISTER_REQUEST,
    body,
    cb,
    extras,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function registerSuccess(cb) {
  return {
    type: REGISTER_SUCCESS,
    cb,
  };
}

export function registerError() {
  return {
    type: REGISTER_ERROR,
  };
}

export function loginRequest(credentials, cb, extras) {
  var body = {
    email: credentials.email,
    password: credentials.password,
  };

  //cb();
  return {
    type: LOGIN_REQUEST,
    body,
    cb,
    extras,
  };
}
//Signup validation Request action
export function signupValidationRquest(body) {
  return {
    type: SIGNUP_VALIDATION_REQUEST,
    body,
  };
}

//signup email successfully validate
export function signupValidationSuccess(res) {
  return {
    type: SIGNUP_VALIDATION_SUCCESS,
    payload: res,
  };
}
////signup email error in validation
export function signupValidationError(res) {
  return {
    type: SIGNUP_VALIDATION_ERROR,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function loginSuccess(profileDetails) {
  return {
    type: LOGIN_SUCCESS,
    payload: profileDetails,
  };
}

export function loginError(errorInfo) {
  return {
    type: LOGIN_ERROR,
    payload: errorInfo,
  };
}

export function resendEmailRequest(email) {
  return {
    type: RESEND_EMAIL,
    payload: email,
  };
}

export function logoutRequest() {
  return {
    type: LOGOUT,
  };
}

//// Password Reset Step 1 Actions
export function passwordResetRequest(values, cb, resend) {
  return {
    type: PASSWORD_RESET_REQUEST,
    body: values,
    cb,
    resend,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function passwordResetSuccess(code) {
  return {
    type: PASSWORD_RESET_SUCCESS,
    payload: code,
  };
}

export function passwordResetError(errorInfo) {
  return {
    type: PASSWORD_RESET_ERROR,
    payload: errorInfo,
  };
}
//// Password Reset Step 2 Actions Resend Code
export function resendCodeRequest(values, cb) {
  return {
    type: RESEND_CODE_REQUEST,
    body: values,
    cb,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function resendCodeSuccess(code) {
  return {
    type: RESEND_CODE_SUCCESS,
    payload: code,
  };
}

export function resendCodeError(errorInfo) {
  return {
    type: RESEND_CODE_ERROR,
    payload: errorInfo,
  };
}
//// Password Reset Step 2 Actions Verfify Code
export function verificationCodeRequest(code, cb) {
  return {
    type: VERIFY_TOKEN_REQUEST,
    body: code,
    cb,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function verificationCodeSuccess(code) {
  return {
    type: VERIFY_TOKEN_SUCCESS,
    payload: code,
  };
}

export function verificationCodeError(errorInfo) {
  return {
    type: VERIFY_TOKEN_ERROR,
    payload: errorInfo,
  };
}

//// Password Reset Step 3 Actions
export function changePasswordRequest(values, cb, extras) {
  let newData = { password: values.password, email: extras };
  return {
    type: RESET_PASSWORD_REQUEST,
    body: newData,
    cb,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function changePasswordSuccess(code) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: code,
  };
}

export function changePasswordError(errorInfo) {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: errorInfo,
  };
}

export function changeHeaderProfilePicture(payload) {
  return {
    type: CHANGE_HEADER_PROFILE_PICTURE,
    payload,
  };
}

// Change user Password Astions start
export function changeUserPasswordRequest(data, cb) {
  return {
    type: CHANGE_USER_PASSWORD_REQUEST,
    data,
    cb,
  };
}

export function changeUserPasswordSuccess(payload) {
  return {
    type: CHANGE_USER_PASSWORD_SUCCESS,
    payload: payload,
  };
}

export function changeUserPasswordError(errorInfo) {
  return {
    type: CHANGE_USER_PASSWORD_ERROR,
    payload: errorInfo,
  };
}
// Change user Password Astions end