import React, { useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import Input from "../../../components/input";
import useFormik from "../../../components/formik/useFormik";
import OptionsWrapper from "../../../components/input/SelectOptions";
import SelectWrapper from "../../../components/input/Select";
import Button from "../../../components/button";
import { updateOrganizationSchema } from "../../../configurations/Schemas/Organization";
import {
  updateOrgSubscription,
  organizationDetailRequest,
} from "../../../actions/Organization";
import Icon from "../../../components/Icon";
import { openModal, closeModal, closeAllModal } from "../../../actions/Modal";

const SubscriptionUpdate = (props) => {
  console.log('SubscriptionUpdate_props: ', props);
  const dispatch = useDispatch();
  const formik = useFormik({
    validationSchema: updateOrganizationSchema,
    initialValues: {
      pricePerUser: props.pricePerUser,
      subscriptionPlanId: props.subscriptionPlanId,
      allowedUsers: props.allowedUsers,
    },
    onSubmit: (values, { ...rest }) => {
      dispatch(
        updateOrgSubscription({ id: props?.organizationId, values }, (res) => {
          if (res.status == 200) {
            props.organizationDetailRequest(props.organizationId);
            props.closeModal();
          }
        })
      );
      // props.updateOrgSubscription({ id: props?.organizationId, values }, (res) => {
      //   if (res.status == 200) {
      //     props.organizationDetailRequest(props.organizationId);
      //     props.closeModal();
      //   }
      // });
    },
  });

  const subscriptionPackages = [
    { key: 1, value: "Standard Package" },
    { key: 2, value: "Premium Package" },
  ];

  function onSelectSubscription(packageId) {
    formik.setFieldValue("subscriptionPlanId", packageId == 0 ? 'Standard': 'Premium');
  }

  const field = useCallback(
    (placeholder, name, value, customProps) => (
      <>
        <Input
          style={{ marginBottom: "1%", marginTop: "5px" }}
          placeholder={placeholder}
          value={value}
          name={name}
          // disabled
          onChange={(e) => {
            //console.log(e.target.value, 'inside onChange func of ', name)
            formik.customHandleChange(e, name);
          }}
          onBlur={(e) => formik.customHandleBlur(e, name)}
          {...customProps}
        />
        <span className="dangerColor caption textCenter">
          {formik.touched[name] && formik.errors[name]}
        </span>
      </>
    ),
    [formik]
  );
  const { subscription } = props;

  return (
    <>
      <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
        {/* <Col span={24}> */}
        <h4 style={{ marginLeft: 12 }}>Subscription Details</h4>

        <Row
          style={{ marginTop: "15px" }}
          type={"flex"}
          gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}
        >
          <Col
            style={{ marginBottom: "15px" }}
            xl={12}
            lg={12}
            sm={24}
            xs={24}
            span={12}
          >
            <Col span={24}>
              <label style={{ fontSize: 10, fontWeight: "600" }}>
                Price per User/Year [GBP]
              </label>
              {field("", "pricePerUser", formik.values["pricePerUser"], {
                disabled: subscription == "Subscribed" ? true : false,
              })}
            </Col>
          </Col>

          <Col
            style={{ marginBottom: "15px" }}
            xl={12}
            lg={12}
            sm={24}
            xs={24}
            span={12}
          >
            <Col span={24}>
              <label style={{ fontSize: 10, fontWeight: "600" }}>
                Allowed User
              </label>
              {field("", "allowedUsers", formik.values["allowedUsers"], {
                disabled: subscription == "Subscribed" ? true : false,
              })}
            </Col>
          </Col>
        </Row>

        <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
          <Col xl={12} lg={12} sm={24} xs={24} span={12}>
            <Col span={24}>
              <label style={{ fontSize: 10, fontWeight: "600" }}>
                Subscription Package
              </label>
              <SelectWrapper
                // noBorder
                onSelect={onSelectSubscription}
                onChange={props.filterChange}
                defaultValue={props?.subscriptionPlanId}
                style={{ width: "100%" }}
              >
                {subscriptionPackages.map((val, ind) => {
                  return (
                    <OptionsWrapper value={ind} key={val.value}>
                      {val.value}
                    </OptionsWrapper>
                  );
                })}
              </SelectWrapper>
            </Col>
          </Col>
        </Row>
      </Row>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{ width: 200, boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)" }}
          sharpBorder
          size={"large"}
          isGreen={true}
          block
          //   disabled={props.organizationState.loading ? true : false}
          onClick={() => formik.submitForm()}
        >
          {props.organizationState.loading ? (
            <Icon type="loading" style={{ fontSize: "1rem" }} />
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </>
  );
};

export default connect((state) => ({ organizationState: state.Organization }), {
  updateOrgSubscription,
  closeModal,
  organizationDetailRequest,
})(SubscriptionUpdate);
