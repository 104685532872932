import {
  // USERS_SUCCESS,
  // USER_REQUEST,
  // USER_SUCCESS,
  // USER_ERROR,
  // CLEAR_USER,
  // //INSTA_USER_SUCCESS,
  // // USERS_REQUEST,
  // USERS_ERROR
  SERACH_TRANSACTIONS_REQUEST,
  SERACH_TRANSACTIONS_SUCCESS,
  SERACH_TRANSACTIONS_ERROR,
  DELETE_TRANSACTION_REQUEST,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_ERROR,
  CLEARED_TRANSACTION_REQUEST,
  CLEARED_TRANSACTION_SUCCESS,
  CLEARED_TRANSACTION_ERROR,
} from "../configurations/Types";
const INITIAL_STATE = {
  totalCount: 0,
  transactions: [],
  profileDetails: null,
  profileLoading: false,
  tableLoading: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SERACH_TRANSACTIONS_REQUEST: {
      //console.log("GOT USER", action.payload)
      return Object.assign({}, state, { profileLoading: true });
    }
    case SERACH_TRANSACTIONS_SUCCESS: {
      let arr = action.payload.all_transactions;
      let allTransactions = [];
      console.log("arr", arr);
      console.log("clearFlag", action.clearFlag);
      let transactionHolder = [];
      if (action.payload) {
        transactionHolder = Object.values(arr);

        arr = allTransactions.concat(...transactionHolder);
        console.log("all ytr arr", arr);
        //console.log([transactionHolder,])
        // for (let transactionDateWise in action.payload.all_transactions) {
        //   console.log("transacTion Date Keys", transactionDateWise);
        //   console.log(
        //     "action.payload.all_transactions",
        //     action.payload.all_transactions
        //   );
        //   transactionHolder =
        //     action.payload.all_transactions[transactionDateWise];
        //   console.log("transactionHolder", transactionHolder);
        //   for (let transaction of transactionHolder) {
        //     //console.log("transaction", transaction);
        //     state.transactions = [...state.transactions, transaction];
        //   }
        // }
        // console.log("state transactions", state.transactions);
      }
      console.log("CHeck flag Success:", action, arr);
      if (
        state.transactions.length !== 0 &&
        !action.clearFlag &&
        !action.ofPage
      ) {
        arr = [...state.transactions, ...arr];
        console.log("seted array", arr);
      }
      if (action.ofPage) {
        console.log("pahge ofSize", action.ofPage);
        let { page, pageSize } = action.ofPage;
        // if (page < (pages - 1))
        // Old approach - faulty due to missing records
        // let oldIds = state.users.slice(page * pageSize, (page * pageSize) + (pageSize + 1)).map(oldUser => oldUser.id);
        // arr = arr.filter((user) => oldIds.includes(user.id));
        // let newUsers = [...state.users];
        // newUsers.splice(page * pageSize, pageSize, ...arr);
        // arr = newUsers;
        //Another faulty approach - Missing records
        // let newPageIds = arr.map(user => user.id);
        // let nextPage = [...state.users].slice((page * pageSize) + pageSize, ((page * pageSize) + pageSize) + (pageSize + 1));
        // nextPage = nextPage.filter(user => !newPageIds.includes(user.id));
        // let newUsers = [...state.users];
        // newUsers.splice(page * pageSize, pageSize * 2, ...[...arr, ...nextPage]);
        // arr = newUsers;
        let newTransactions = [...state.transactions];
        console.log("new Transactions", newTransactions);
        newTransactions.splice(
          page * pageSize,
          newTransactions.length - page * pageSize,
          ...arr
        );
        arr = newTransactions;
        // console.log("newTransactions", newTransactions);
      }
      console.log("state.transactions", arr);
      return Object.assign({}, state, {
        totalCount: parseInt(action.payload.total_count),
        transactions: arr,
      });
    }

    case SERACH_TRANSACTIONS_ERROR: {
      console.log("Error");
      return Object.assign({}, state, { totalCount: 0, transactions: [] });
    }

    // -- Delete Transaction Reducers

    case DELETE_TRANSACTION_REQUEST: {
      console.log("D - Req Run");
      return Object.assign({}, state, { tableLoading: true });
    }

    case DELETE_TRANSACTION_SUCCESS: {
      console.log("D - Success Run");
      return Object.assign({}, state, { tableLoading: false });
    }

    case DELETE_TRANSACTION_ERROR: {
      console.log("D - Error Run");
      return Object.assign({}, state, { tableLoading: false });
    }
    case CLEARED_TRANSACTION_REQUEST: {
      console.log("Cl run req");
      return Object.assign({}, state, { tableLoading: true });
    }

    case CLEARED_TRANSACTION_SUCCESS: {
      console.log("Cl run Success");
      return Object.assign({}, state, { tableLoading: false });
    }
    case CLEARED_TRANSACTION_ERROR: {
      console.log("Cl run Error");
      return Object.assign({}, state, { tableLoading: false });
    }

    // case USERS_REQUEST: {
    // }
    // case INSTA_USER_SUCCESS: {
    //   console.log("INSTA DETAILS", action.payload)
    //   return Object.assign({}, state, {instaDetails: action.payload})
    // }

    // case USER_SUCCESS: {
    //   console.log("GOT USER", action.payload);
    //   return Object.assign({}, state, {
    //     profileDetails: action.payload,
    //     profileLoading: false
    //   });
    // }
    // case USER_ERROR: {
    //   //console.log("GOT USER", action.payload)
    //   return Object.assign({}, state, { profileLoading: false });
    // }
    // case CLEAR_USER: {
    //   return Object.assign({}, state, { profileDetails: null });
    // }

    default: {
      return state;
    }
  }
};
