import {
  SEND_REPORT_REQUEST,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_ERROR,
  CHART_OF_ACCOUNTS_REQUEST,
  CHART_OF_ACCOUNTS_SUCCESS,
  CHART_OF_ACCOUNTS_ERROR,
  BANK_ACCOUNT_LEDGER_REQUEST,
  BANK_ACCOUNT_LEDGER_SUCCESS,
  BANK_ACCOUNT_LEDGER_ERROR,
  TRIAL_BALANCE_SUMMARY_REQUEST,
  TRIAL_BALANCE_SUMMARY_SUCCESS,
  TRIAL_BALANCE_SUMMARY_ERROR,
  PROFIT_AND_LOSS_REQUEST,
  PROFIT_AND_LOSS_SUCCESS,
  PROFIT_AND_LOSS_ERROR,
  TRANSFER_JOURNAL_LEDGER_REQUEST,
  TRANSFER_JOURNAL_LEDGER_SUCCESS,
  TRANSFER_JOURNAL_LEDGER_ERROR,
  RECONCILIATION_FOR_LAWYERS_REQUEST,
  RECONCILIATION_FOR_LAWYERS_SUCCESS,
  RECONCILIATION_FOR_LAWYERS_ERROR,
  CHART_REPORT_REQUEST,
  CHART_REPORT_SUCCESS,
  CHART_REPORT_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  sendReportLoading: false,
  chartOfAccountsLoading: false,
  bankAccountLedgerLoading: false,
  trialBalanceSummaryLoading: false,
  profitAndLossLoading: false,
  transferJournalLedgerLoading: false,
  reconciliationForLawyersLoading: false,
  chartReportLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_REPORT_REQUEST: {
      return Object.assign({}, state, { sendReportLoading: true });
    }
    case SEND_REPORT_SUCCESS: {
      return Object.assign({}, state, { sendReportLoading: false });
    }
    case SEND_REPORT_ERROR: {
      return Object.assign({}, state, { sendReportLoading: false });
    }
    case CHART_OF_ACCOUNTS_REQUEST: {
      return Object.assign({}, state, { chartOfAccountsLoading: true });
    }
    case CHART_OF_ACCOUNTS_SUCCESS: {
      return Object.assign({}, state, { chartOfAccountsLoading: false });
    }
    case CHART_OF_ACCOUNTS_ERROR: {
      return Object.assign({}, state, { chartOfAccountsLoading: false });
    }
    case BANK_ACCOUNT_LEDGER_REQUEST: {
      return Object.assign({}, state, { bankAccountLedgerLoading: true });
    }
    case BANK_ACCOUNT_LEDGER_SUCCESS: {
      return Object.assign({}, state, { bankAccountLedgerLoading: false });
    }
    case BANK_ACCOUNT_LEDGER_ERROR: {
      return Object.assign({}, state, { bankAccountLedgerLoading: false });
    }
    case TRIAL_BALANCE_SUMMARY_REQUEST: {
      return Object.assign({}, state, { trialBalanceSummaryLoading: true });
    }
    case TRIAL_BALANCE_SUMMARY_SUCCESS: {
      return Object.assign({}, state, { trialBalanceSummaryLoading: false });
    }
    case TRIAL_BALANCE_SUMMARY_ERROR: {
      return Object.assign({}, state, { trialBalanceSummaryLoading: false });
    }
    case PROFIT_AND_LOSS_REQUEST: {
      return Object.assign({}, state, { profitAndLossLoading: true });
    }
    case PROFIT_AND_LOSS_SUCCESS: {
      return Object.assign({}, state, { profitAndLossLoading: false });
    }
    case PROFIT_AND_LOSS_ERROR: {
      return Object.assign({}, state, { profitAndLossLoading: false });
    }
    case TRANSFER_JOURNAL_LEDGER_REQUEST: {
      return Object.assign({}, state, { transferJournalLedgerLoading: true });
    }
    case TRANSFER_JOURNAL_LEDGER_SUCCESS: {
      return Object.assign({}, state, { transferJournalLedgerLoading: false });
    }
    case TRANSFER_JOURNAL_LEDGER_ERROR: {
      return Object.assign({}, state, { transferJournalLedgerLoading: false });
    }
    case RECONCILIATION_FOR_LAWYERS_REQUEST: {
      return Object.assign({}, state, {
        reconciliationForLawyersLoading: true,
      });
    }
    case RECONCILIATION_FOR_LAWYERS_SUCCESS: {
      return Object.assign({}, state, {
        reconciliationForLawyersLoading: false,
      });
    }
    case RECONCILIATION_FOR_LAWYERS_ERROR: {
      return Object.assign({}, state, {
        reconciliationForLawyersLoading: false,
      });
    }
    case CHART_REPORT_REQUEST: {
      return Object.assign({}, state, { chartReportLoading: true });
    }
    case CHART_REPORT_SUCCESS: {
      return Object.assign({}, state, { chartReportLoading: false });
    }
    case CHART_REPORT_ERROR: {
      return Object.assign({}, state, { chartReportLoading: false });
    }

    default: {
      return state;
    }
  }
};
