import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { emailValidations } from "../../configurations/Schemas/index";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { passwordResetRequest } from "../../actions/Auth";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }
  handleChanged = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  changeEmail = (value) => {
    this.setState({ email: value });
  };
  render() {
    const bottomLink={
      text:'BACK TO LOGIN',
      onClick:()=>{
        this.props.history.replace('login')
      }
    }
    return (
      <AuthContainer bootomLink={bottomLink}>
        <Row type="flex" justify="center" align="middle" gutter={[10, 30]}>
          <Col span={24}>
            <h3 className="mr-t mr-b fontBold">Reset Your Password</h3>
            <p>
              Enter your email address.
            </p>
          </Col>
          <Col xs={24} sm={22}>
            <RootForm
              schemaName={emailValidations}
              initialValues={this.state}
              changeEmail={this.changeEmail}
              apiCall={passwordResetRequest}
              //autoWidthSubmit
              loading={this.props.Auth.forgotPasswordLoading}
              cb={() => {
                this.props.history.push({
                  pathname: "/verify-code",
                  state: {
                    email: this.state.email,
                  },
                });
              }}
              controls={[
                {
                  span: 24,
                  name: "email",
                  value: this.state.email,
                  handleChanged: this.handleChanged,
                  placeholder: "Email",
                  Type: Input,
                  border:'1px solid #707070'
                },
              ]}
              submitButton={{
                span: 14,
                buttonText: "Continue",
                className: "mr-t-lg mr-b-md bg-gradient",
              }}
            />
            {/* <Link to="login" className="card-link-out">
              Back to Login
            </Link> */}
          </Col>
        </Row>
      </AuthContainer>
    );
  }
}

export default connect((storeState) => ({ Auth: storeState.Auth }))(
  ForgotPassword
);
