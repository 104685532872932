import React from "react";
import styled from "styled-components";
import { Tag } from "antd";
const StyledTag = styled(Tag)`
  text-align: center;
  background-color: ${(props) => {
    console.log("Tag_bg", props.children);
    return props.children == "Pending" ||  props.children == "Premium"
      ? "#151D30"
      : props.children == "Subscribed" || props.children == "Standard"
      ? props.theme[props.theme.mode].background.green
      : props.children == "Expired"
      ? "#717070"
      : props.children == 'Active'
      ? props.theme[props.theme.mode].background.green
      :props.theme[props.theme.mode].primary.main;
  }}
  color: ${(props) => props.theme["light"].textColor.inverted};
  border: none;
  margin-right: 0;
  /* padding:0.2vw; */
  padding: ${(props) => (props.defaultWidth ? "0.4rem" : "0.5vw 0.2vw")};;
  width: ${(props) => (props.defaultWidth ? "7rem" : "50%")};
  border-radius: 25px;
  font-size: inherit;
  font-weight: bold;
  &:hover {
    opacity: 1;
  }
  & .ant-tag{}
  .tagWidth {
    width: 7rem
  }
  @media screen and (max-width: 991px) {
    width: ${(props) => (props.defaultWidth ? "7rem" : "100%")};
  }
`;
const TagWrapper = (props) => {
  return <StyledTag {...props}></StyledTag>;
};
export default TagWrapper;
