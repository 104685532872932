import React from "react";
import { FiAlertTriangle } from "react-icons/fi";
import Image from "../../../../components/image";
import { Row, Col, Skeleton } from "antd";
import CardWrapper from "../../../../components/card";
import SwitchWrapper from "../../../../components/switch";
import PopconfirmWrapper from "../../../../components/pop-confirm";
import TagWrapper from "../../../../components/tag";
import Blip from "../../../shares/blip";
import styled, { withTheme } from "styled-components";

import EmptyProfilePic from "../../../../assets/images/empty-profile.png";

const StyledContainer = styled.div`
  border-radius: 10px;
  border: 1px dashed ${(props) => props.theme[props.theme.mode].primary.main};

  padding: 1rem;

  .textDiv {
    height: 10rem;
    padding: 0 3rem;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    .textDiv {
      height: 30vw;
    }
  }
`;

const TravellerCard = (props) => {
  return (
    <StyledContainer>
      <Row type="flex" align="middle" justify="center" className="textDiv">
        <Col>This order has not been confirmed yet</Col>
      </Row>
    </StyledContainer>
  );
};

export default withTheme(TravellerCard);
