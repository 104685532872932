import {
  REQUESTS_REQUEST,
  REQUESTS_SUCCESS,
  REQUESTS_ERROR,
  REQUEST_REQUEST,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
  USER_REQUESTS_REQUEST,
  USER_REQUESTS_SUCCESS,
  USER_REQUESTS_ERROR,
  CLEAR_REQUESTS,
  CLEAR_REQUEST,
  DELETE_DELIVERY_REQUEST,
  DELETE_DELIVERY_SUCCESS,
  DELETE_DELIVERY_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  requests: [],
  count: 0,
  loading: false,

  request: null,
  requestLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUESTS_REQUEST: {
      let clear = action.clearFlag;
      return Object.assign({}, state, {
        requests: clear ? [] : state.requests,
        count: clear ? 0 : state.count,
        loading: true,
      });
    }
    case REQUESTS_SUCCESS: {
      let newRequests = [...state.requests, ...action.payload.delieries];
      return Object.assign({}, state, {
        requests: newRequests,
        count: action.payload.total_count,
        loading: false,
      });
    }
    case REQUESTS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case REQUEST_REQUEST: {
      let clear = action.clearFlag;
      return Object.assign({}, state, { requestLoading: true });
    }
    case REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        request: action.payload,
        requestLoading: false,
      });
    }
    case REQUEST_ERROR: {
      return Object.assign({}, state, { requestLoading: false });
    }

    case USER_REQUESTS_REQUEST: {
      let clear = action.clearFlag;
      return Object.assign({}, state, {
        requests: clear ? [] : state.requests,
        count: clear ? 0 : state.count,
        loading: true,
      });
    }
    case USER_REQUESTS_SUCCESS: {
      let newRequests = [...state.requests, ...action.payload];
      return Object.assign({}, state, {
        requests: newRequests,
        count: action.payload.total_count,
        loading: false,
      });
    }
    case USER_REQUESTS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case CLEAR_REQUESTS: {
      return Object.assign({}, state, { requests: [] });
    }

    case CLEAR_REQUEST: {
      return Object.assign({}, state, { request: null });
    }

    case DELETE_DELIVERY_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case DELETE_DELIVERY_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }

    case DELETE_DELIVERY_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    default: {
      return state;
    }
  }
};
