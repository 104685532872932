import React from "react";
import { withTheme } from "styled-components";

const Blip = (props) => (
  <span
    style={{
      display: "inline-block",
      width: props.small ? 4 : props.large ? 12 : 7,
      height: props.small ? 4 : props.large ? 12 : 7,
      //margin: props.small ? "0px 4px" : "0px 6px",
      borderRadius: 30,
      margin: props.margin,
      backgroundColor: props.color
        ? props.color
        : props.theme[props.theme.mode].primary.main,
    }}
  />
);

export default withTheme(Blip);
