import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import Card from "../../../../components/card";
import Image from "../../../../components/image";
import Tag from "../../../../components/tag";
import Button from "../../../../components/button";
// import Info from "../../../../components/message";
import { connect } from "react-redux";
import { deleteDeliveryRequest } from "../../../../actions/Requests";
import EmptyProductPic from "../../../../assets/images/defaultProduct.jpeg";
// import PopConfirmWrapper from "../../../../components/pop-confirm";
// import { FiAlertTriangle } from "react-icons/fi";

let tagMap = {
  confirmed: "primary",
  expired: "secondary",
  pending: "third",
};

const RequestDetailsCard = (props) => {
  return (
    <Card className="mini-padding" style={{ wordBreak: "break-all" }}>
      <Row gutter={[0, 20]}>
        <Col span={24} style={{ height: "16rem" }}>
          <Image
            src={props.ImgUrl || EmptyProductPic}
            alt="No img Found"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />

          <Row
            style={{
              position: "absolute",
              bottom: "1.1rem",
              width: "100%",
              padding: "0 0.4rem",
            }}
            type="flex"
            justify="end"
            align="middle"
          >
            <Col>
              {" "}
              <Tag defaultWidth type={tagMap[props.status] || "third"}>
                {props.status
                  ? props.status[0].toUpperCase() + props.status.substr(1)
                  : "-"}
              </Tag>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row
            type="flex"
            gutter={[5, 10]}
            //justify="space-between"
            align="middle"
            style={{
              flexWrap: "nowrap",
            }}
          >
            <Col style={{ wordBreak: "normal" }}>
              <p style={{ display: "inline" }} className="appColor fontBold">
                {props.quantity || 1}x
              </p>
              {/* &nbsp; */}
            </Col>
            <Col style={{ flexGrow: 1 }}>
              <p style={{ display: "inline" }} className="fontNormal">
                {props.name}
              </p>
            </Col>
            <Col style={{ wordBreak: "normal" }}>
              <p style={{ display: "inline" }} className="appColor fontBold">
                ${props.amount || "0"}
              </p>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row type="flex" align="" justify="space-between" gutter={[0, 15]}>
            <Col span={10}>
              <p className="font500">Where to Pick?</p>
            </Col>
            <Col span={14}>
              <p className="font500 appColor">{props.whereToPick || "-"}</p>
            </Col>

            <Col span={10}>
              <p className="font500">Approx. Weight</p>
            </Col>
            <Col span={14}>
              <p className="font500 appColor">{props.weight || "0"}&nbsp;KG</p>
            </Col>

            <Col span={10}>
              <p className="font500">Deliver Before</p>
            </Col>
            <Col span={14}>
              <p className="font500 appColor">
                {props.requiredDate
                  ? moment(props.requiredDate).format("DD - MMM - YYYY")
                  : "-"}
              </p>
            </Col>

            <Col span={10}>
              <p className="font500">Delivery Address</p>
            </Col>
            <Col span={14}>
              <p className="font500 appColor">{props.address || "-"}</p>
            </Col>

            <Col span={10}>
              <p className="font500">Notes</p>
            </Col>
            <Col span={14} style={{ wordBreak: "normal" }}>
              <p className="font500 appColor">{props.notes || "-"}</p>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="mr-t">
          <Row type="flex" align="middle" justify="center">
            <Col>
              <Button
                sharpBorder
                onClick={() => props.deleteDeliveryRequest(props.id)}
              >
                Delete Request
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(null, { deleteDeliveryRequest })(RequestDetailsCard);

{
  /* <PopconfirmWrapper
                          title={
                            <h4>
                              Are you sure you want to
                              {r.original["status"] === "in-active"
                                ? " activate "
                                : " deactivate "}
                              this user?
                            </h4>
                          }
                          icon={
                            <FiAlertTriangle
                              style={{
                                position: "absolute",
                                color: "#f44336",
                                left: 0,
                                top: 0,
                                marginTop: 6,
                              }}
                            />
                          }
                          cancelText="No"
                          okText="Yes"
                          onConfirm={function () {
                            props.changeUserStatusRequest(
                              {
                                user_id: r.original["id"],
                                status: r.original["status"] === "2" ? 1 : 2,
                              },
                              (res) => setTableChanged(!tableChanged)
                            );
                          }}
                        >
                          <SwitchWrapper
                            checked={r.original && r.original.status !== "2"}
                            style={{ marginRight: 10 }}
                            //onClick={handleSwitch}
                          />
                        </PopconfirmWrapper> */
}
