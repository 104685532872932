import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import Card from "../../../../components/card";
import Image from "../../../../components/image";
import Tag from "../../../../components/tag";
import AvatarWrapper from "../../../../components/avatar";
import EmptyProfilePic from "../../../../assets/images/empty-profile.png";
import EmptyProductPic from "../../../../assets/images/defaultProduct.jpeg";

let tagMap = {
  confirmed: "primary",
  expired: "secondary",
  pending: "third",
};

const RequestCard = (props) => {
  const history = useHistory();

  return (
    <Card
      className="mini-padding"
      style={{ cursor: "pointer" }}
      onClick={() =>
        history.push({
          pathname: `/requests/${props.id}`,
          state: {
            referrer: "/requests",
          },
        })
      }
    >
      <Row gutter={[0, 10]}>
        <Col span={24} style={{ height: "14rem" }}>
          <Image
            src={props.ImgUrl || EmptyProductPic}
            alt="No img Found"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />

          <Row
            style={{
              position: "absolute",
              bottom: "0.8rem",
              width: "100%",
              padding: "0 0.4rem",
              flexWrap: "nowrap",
            }}
            type="flex"
            justify="space-between"
            align="middle"
          >
            <Col style={{ flexGrow: 1 }}>
              <Row
                type="flex"
                align="middle"
                gutter={5}
                style={{ flexWrap: "nowrap" }}
              >
                <Col>
                  <AvatarWrapper
                    className="small"
                    shape="circle"
                    // style={{ backgroundColor: "#F28787", padding: "0.5rem" }}
                    //size="small"
                    src={EmptyProfilePic}
                  ></AvatarWrapper>
                </Col>
                <Col>
                  <span className="small whiteColor">{props.userName}</span>
                </Col>
              </Row>
            </Col>

            <Col style={{ textAlign: "right" }}>
              {" "}
              <Tag defaultWidth type={tagMap[props.status] || "third"}>
                {props.status
                  ? props.status[0].toUpperCase() + props.status.substr(1)
                  : "-"}
              </Tag>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row
            type="flex"
            gutter={[5, 10]}
            //justify="space-between"
            align="middle"
            style={{
              flexWrap: "nowrap",
            }}
          >
            <Col style={{ wordBreak: "normal" }}>
              <p style={{ display: "inline" }} className="appColor fontBold">
                {props.quantity || 1}x
              </p>
              {/* &nbsp; */}
            </Col>
            <Col style={{ flexGrow: 1 }}>
              <p style={{ display: "inline" }} className="fontNormal">
                {props.name}
              </p>
            </Col>
            <Col style={{ wordBreak: "normal" }}>
              <p style={{ display: "inline" }} className="appColor fontBold">
                ${props.amount || "0"}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default RequestCard;
