import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Row, Col, Pagination } from "antd";
import Blip from "../../../appcomponents/shares/blip";
import EditContentHeader from "../../../appcomponents/shares/contentheader/editProfileHeader";
import TableWrapper from "../../../components/react-table";
import TagWrapper from "../../../components/tag";
import InputWrapper from "../../../components/input";
import { FiEdit2 } from "react-icons/fi";
import Button from "../../../components/button";
import info from "../../../components/message/index";
import "./editPromo.css";
import {
  getPromoUsersRequest,
  updatePromoRequest,
  getPromosDetails,
} from "../../../actions/PromoCode";

function EditPromoCode(props) {
  console.log("EditPromoCode_props: ", props);

  const [offSet, setOffSet] = useState(0);
  const [state, setState] = useState({
    package: "",
    status: "",
    dateFrom: props?.promoCode?.created_at,
    dateTo: new Date().toISOString(),
    limit: 5,
    offset: 0,
  });
  const [tableChanged, setTableChanged] = useState(false);
  const [modal, setModal] = useState(false);
  const [discountDropdown, setDiscountDropdown] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [promoCodeError, setPromoCodeError] = useState(true);
  const [mount, setMount] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [init, setInit] = useState(false);
  const [name, setName] = useState("");
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promocode, setPromocode] = useState("");
  const [discount, setDiscount] = useState("");
  const [discounts] = useState(["10", "20", "30"]);
  const [packages, setPackages] = useState([]);
  const [isDisable, setIsDisable] = useState(true)

  const [discountPackages] = useState([
    {
      name: "Standard Package",
      price: "£ 39.99",
      duration: "12 months",
      discounts: {
        10: "£ 35.99",
        20: "£ 31.99",
        30: "£ 27.99",
      },
    },
    {
      name: "Premium Package",
      price: "£ 49.99",
      duration: "12 months",
      discounts: {
        10: "£ 44.99",
        20: "£ 39.99",
        30: "£ 34.99",
      },
    },
  ]);

  const [filters,setFilters] = useState({
    startDate:'',
    endDate:'',
    packageName:'',
    subscriptionStatus:'',
  })

  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => {
    return state;
  });

  const { promoCodeDetails } = data?.PromoCodeReducer;

  const { id } = props?.match?.params;

  useEffect(() => {
    dispatch(getPromosDetails(id,filters));
  }, []);

  useEffect(() => {
    setName(promoCodeDetails?.name);
    setEmail(promoCodeDetails?.email);
  }, [data]);

  const handleScroll = () => {
    if (
      window.innerHeight + window.pageYOffset >=
      document.getElementById("root").clientHeight - 5
    ) {
      setHasScrolled((prev) => !prev);
    }
  };

  const handleStatusFilterChange = (e) => {
    console.log("filter_status ", e);

    const objFilter={
      ...filters,
      subscriptionStatus:e
    }
    dispatch(getPromosDetails(id,objFilter));
    setFilters({...filters,subscriptionStatus:e})
  };

  const handleFilterPackageChange = (e) => {
    console.log("filter_package ", e);
    let promoPackage = "";
    if (e === 0) {
      promoPackage = "premium";
    } else if (e === 1) {
      promoPackage = "standard";
    } else {
      promoPackage = "";
    }
    const objFilter={
      ...filters,
      packageName:promoPackage
    }
    dispatch(getPromosDetails(id,objFilter));
    setFilters({...filters,packageName:promoPackage})
  };

  const handleFilterDateChange = (sDate, eDate) => {
    let startDate = moment(sDate).format("YYYY-MM-DD")
    let endDate = moment(eDate).format("YYYY-MM-DD")
    console.log('Date==>startDate', startDate);
    console.log('Date==>endDate ', endDate);

    const objFilter={
      ...filters,
      startDate,
      endDate
    }
    dispatch(getPromosDetails(id,objFilter));
    setFilters({...filters,startDate,endDate})
  };

  const changeOffset = (abc) => {
    setState({
      ...state,
      offset: state.offset,
    });
  };

  const validateEmail = (e) => {
    setEmail(e);
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let emailValid = re.test(String(e).toLowerCase());
    setEmailError(emailValid ? "" : "Invalid Email");
  };

  const validatePromoCode = (code) => {
    setPromocode(code);
    if (code.length < 3)
      setPromoCodeError("Sorry! Promocode cannot be less than 3 characters.");
    else if (code.includes(" ")) setPromoCodeError("Spaces are not allowed.");
    else setPromoCodeError("");
  };

  const selectPackage = (item) => {
    let packagesArr = [...packages];
    let packageInd = packagesArr.findIndex((val) => val.name == item.name);
    if (packageInd > -1) packagesArr.splice(packageInd, 1);
    else packagesArr.push({ name: item.name });

    setPackages(packagesArr);
  };

  // const handlePages = (pageNumber) => {
  //   const limit = 10;
  //   let offset = (pageNumber - 1) * limit;
  //   console.log("offset ", offset);
  //   const objFilter={
  //     ...filters,
  //        offset
  //   }
  //   dispatch(getPromosDetails(id,objFilter));
  //   setFilters({...filters,offset})
  //   setCurrent(pageNumber)
  //   props.getPromoUsersRequest(
  //     props.promoCode.promo_code_id,
  //     state.package,
  //     state.status,
  //     state.dateFrom,
  //     state.dateTo,
  //     limit,
  //     offset
  //   );
  //   setState({ ...state, offset: offset });
  // };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    //document.body.addEventListener("touchmove", handleScroll);
    if (!mount) {
      props.getPromoUsersRequest(
        props?.promoCode?.promo_code_id,
        state.package,
        state.status,
        state.dateFrom,
        state.dateTo,
        state.limit,
        state.offset
      );
    }
    setMount(true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      //document.body.removeEventListener("touchmove", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (mount || init) {
      setToggle(!toggle);
    }
  }, [state.search, state.active, offSet, init]);

  useEffect(() => {
    if (mount) {
      if (
        !loading &&
        !props.promocodeState.loading &&
        props.promocodeState.promoCodeUsers >
          props.promocodeState.promoCodeUsers.length
      ) {
        setLoading(true);
        // setOffSet((prev) => prev + 8);
      }
    }
  }, [hasScrolled]);

  useEffect(() => {
    if (mount) {
      if (!props.promocodeState.loading) {
        setLoading(false);
      }
    }
  }, [props.promocodeState.loading]);

  let promoPlan = "";
  if (props?.promoCode?.valid_for === "both") {
    promoPlan = "Standard & Premium";
  } else if (props?.promoCode?.valid_for === "standard") {
    promoPlan = "Standard";
  } else {
    promoPlan = "Premium";
  }

  const handleUpdatePromo = () => {
    console.log("update promo ", name, emailError);
    if (name !== "" && emailError === "") {
      // let id = props.promoCode.promo_code_id;
      let payload = {
        email,
        name,
      };
      console.log("payload ", payload);
      //API call here
      props.updatePromoRequest(id, payload, (res) => {
        console.log("updatePromoRequest_res: ", res?.data?.status);
        if (res?.data?.status === 200) {
          // props.handleBackEdit();
          setIsDisable(true)
          // history.push("/promocode");
        }
      });
    }
  };

  const formatDate = (oldDate) => {
    let monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let newDate = new Date(oldDate);
    let date = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    return `${date}-${monthShortNames[month]}-${year}`;
  };

  const { createdAt, discountPackage, discountPercentage, promoCode,subscriptions } =
  promoCodeDetails;
  console.log('Promo_subscriptions: ', subscriptions);

  return (
    <React.Fragment>
      <Row gutter={[20, 20]} justify="space-between">
        <Col span={24}>
          <EditContentHeader
            showBackBtn
            handleBack={() => history.push("/promocode")}
            title={"Promo Code"}
            hideCount={true}
            // count={subscriptions&&subscriptions.length}
            updatePromo={handleUpdatePromo}
            isDisabled={isDisable}
          />
        </Col>
        <Col
          span={24}
          style={{
            padding: "0px 50px",
          }}
        >
          <Row gutter={[10, 10]} style={{ marginTop: "10px" }}>
            <Col span={24}>
              <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                {" "}
                Details{" "}
              </h3>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <label className="mr-b-sm">Name</label>
                  <InputWrapper
                    backgroundColor="transparent"
                    marginBottom="0px !important"
                    height="40px !important"
                    type="text"
                    maxLength={50}
                    suffix={<FiEdit2/>}
                    // onFocus={()=>setIsDisable(false)}
                    // onBlur={()=>setIsDisable(true)}
                    value={name && name}
                    onChange={(e) => {
                      console.log("value ", e.target.value);
                      setName(e.target.value);
                      setIsDisable(false)
                      // validatePromoCode(
                      //   e.target.value.replace(/ /g, "") + discount
                      // );
                    }}
                    style={{marginBottom:'0'}}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <label className="mr-b-sm">Email</label>
                  <InputWrapper
                    backgroundColor="transparent"
                    marginBottom="0px !important"
                    height="40px !important"
                    type="email"
                    suffix={<FiEdit2/>}
                    // onFocus={()=>setIsDisable(false)}
                    // onBlur={()=>setIsDisable(true)}
                    maxLength={50}
                    value={email || "-"}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validateEmail(e.target.value);
                      setIsDisable(false)
                    }}
                    style={{marginBottom:'0'}}
                  />
                  {emailError ? (
                    <div className="dangerColor small">{emailError}</div>
                  ) : null}
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <label className="mr-b-sm">Promo Code</label>
                  <InputWrapper
                    disabled={true}
                    backgroundColor="transparent"
                    marginBottom="0px !important"
                    height="40px !important"
                    type="text"
                    maxLength={15}
                    value={promoCode || "-"}
                    onChange={(e) => {
                      validatePromoCode(e.target.value.replace(/ /g, ""));
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <label className="mr-b-sm">Discount Percentage</label>
                  <InputWrapper
                    disabled={true}
                    backgroundColor="transparent"
                    marginBottom="0px !important"
                    height="40px !important"
                    type="text"
                    maxLength={15}
                    value={`${discountPercentage || `-`} %`}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <label className="mr-b-sm">Created Date</label>
                  <InputWrapper
                    disabled={true}
                    backgroundColor="transparent"
                    marginBottom="0px !important"
                    height="40px !important"
                    type="text"
                    maxLength={15}
                    value={`${moment(createdAt).format("L") || "-"}`}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <label className="mr-b-sm">Discount Package</label>
                  <InputWrapper
                    disabled={true}
                    backgroundColor="transparent"
                    marginBottom="0px !important"
                    height="40px !important"
                    type="text"
                    maxLength={15}
                    value={`${discountPackage || `-`} (12 months)`}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[20, 20]} style={{marginTop:'10px'}}>
        <Col span={24}>
          <EditContentHeader
            showPackage
            showStatus
            showDate
            title={"User Detail"}
            count={subscriptions&&subscriptions.length}
            statusFilter={handleStatusFilterChange}
            packageFilter={handleFilterPackageChange}
            dateFilter={handleFilterDateChange}
            promoCreatedDate={props?.promoCode?.created_at}
            packageOptions={[
              { label: "All Data", value: "" },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>Standard 12 month</Col>
                  </Row>
                ),
                value: 1,
              },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>Premium 12 month</Col>
                  </Row>
                ),
                value: 0,
              },
            ]}
            statusOptions={[
              { label: "View All", value: "" },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#F8604E" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Active</Col>
                  </Row>
                ),
                value: 1,
              },
              {
                label: (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Blip color="#717070" margin={"0 0.3vw 0 0"} />
                    </Col>

                    <Col>Expired</Col>
                  </Row>
                ),
                value: 0,
              },
            ]}
          />
        </Col>
      </Row>
      <Col style={{ overflow: "hidden" }} span={24}>
        <TableWrapper
          style={{ marginTop: "15px" }}
          tableData={subscriptions&&subscriptions}
          // getData={fil.apiCall}
          search={state.search}
          filters={state}
          pageSize={10}
          dataCount={props?.promocodeState?.promoCodeUsers?.length}
          loading={props?.promocodeState?.loading}
          changeOffset={changeOffset}
          getTrProps={(state, rowInfo, column) => ({
            // onClick: () =>
            //     props.history.push({
            //         pathname: `/promocode/${}`,

            //         state: {
            //             referrer: "/promocode",

            //         },
            //     }),
            style: { cursor: "pointer", overflowX: "hidden" },
          })}
          getTdProps={(state, rowInfo, column, c, d) => {
            return column.className === "clickable"
              ? {
                  onClick: (e) => e.stopPropagation(),
                  style: { cursor: "default", overFlow: "hidden" },
                }
              : {};
          }}
          //enableReInit={true}
          tableChanged={tableChanged}
          noDataText="No record found"
          content={[
            {
              name: `User List`,
              id: "userList",
              leftAligned: true,
              style: { marginLeft: 20 },
              render: (r) => {
                return (
                  <div style={{ marginLeft: 20 }}>
                    <p
                      style={{
                        justifySelf: "left",
                        textAlign: "left",
                        alignSelf: "left",
                      }}
                    >
                      {r?.original?.user?.userName || '-'}
                    </p>
                  </div>
                );
              },
            },
            {
              name: "Status",
              centered: true,
              id: "status",
              render: (r) => {
                return (
                  <p style={{ cursor: "pointer" }}>
                    {r?.original?.status === true ? "Active" : "Expired"}
                  </p>
                );
              },
            },

            {
              name: "Sub Date",
              id: "subDate",
              centered: true,
              render: (r) => {
                return <span>{
                  moment(r?.original?.startDate).format(
                    "DD-MMM-YYYY"
                  ) || "-"
                  }
                  </span>;
              },
            },
            {
              name: "Exp Date",
              id: "expDate",
              centered: true,
              render: (r) => {
                return <span>{
                  moment(r?.original?.endDate).format(
                    "DD-MMM-YYYY"
                  ) || "-"
                  }
                  </span>;
              },
            },
            {
              name: "Package",
              id: "package",
              centered: true,
              render: (r) => {
                let packageName =
                  r.original?.product_name === "Standard Package"
                    ? "Standard"
                    : "Premium";
                    let status = r.original?.productId.includes('standard')
                    console.log('contains',r.original?.productId.includes('standard'))

                return (
                  <TagWrapper
                    // type={r.original?.status == 1 ? packageName : "expired"}
                  >
                    {status ? 'Standard': 'Premium'}
                  </TagWrapper>
                );
              },
            },
          ]}
        />
      </Col>
      <Col>
      {/* <Pagination
        responsive="true"
        showLessItems="true"
        className="tbl-pagination"
        current={current}
        pageSize={10}
        total={props.promocodeState?.promoSubscriptionCount}
        onChange={(e) => {
          console.log("Page number is:", e);
          handlePages(e);
        }}
      /> */}
      </Col>
    </React.Fragment>
  );
}

const styles = {
  modal: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,0.8)",
    alignItems: "center",
    justifyContent: "center",
    overFlow: "auto",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 350,
    overflow: "auto",
    maxHeight: 650,
    zIndex: 1000,
  },
  modalHeading: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    position: "relative",
  },
  modalInputContainer: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 20,
  },
  modalInputView: {
    flexDirection: "row !important",
    width: 500,
    marginBottom: 20,
  },
  modalInputLabel: {
    fontFamily: "Montserrat",
    fontWeight: "lighter",
    fontSize: 14,
    marginBottom: 10,
  },
  modalSubHeading: {
    marginTop: 30,
    marginBottom: 30,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 14,
  },
  dropdownArrow: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdownValue: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 5,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontSize: 12,
    color: "#9F2D2A",
  },
  dropdown: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: 40,
    height: "100px",
    width: "400px",
    boxShadow: "0px 5px 20px #1E1E220C",
    zIndex: 1,
  },
  dropdownItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 40,
    width: "100%",
    borderBottom: "1px solid #FBEFF5",
    backgroundColor: "white",
    paddingLeft: 20,
    paddingRight: 10,
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontSize: 12,
  },
  modalPackagesContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  modalPackagesView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 200,
    paddingBottom: 20,
  },
  modalPackagePrice: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: 10,
    color: "#9F2D2A",
    marginLeft: 20,
  },
  modalPackagesSelect: {
    display: "flex",
  },
  modalPackagesCheckbox: {
    height: 15,
    width: 15,
    borderRadius: 2,
    border: "1px solid #9F2D2A",
    marginRight: 10,
    marginTop: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputFieldBox: {
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
  },
};

export default connect(
  (state) => ({
    promocodeState: state.PromoCodeReducer,
  }),
  {
    getPromoUsersRequest,
    updatePromoRequest,
  }
)(EditPromoCode);
