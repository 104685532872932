import React from "react";
import Styled from "styled-components";

const Img = Styled.img`
  max-width: 100%;
  height: auto;
`;

const Image = (props) => {
  return <Img {...props} />;
};

export default Image;
