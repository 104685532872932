import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./ConfigureStore";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// basename='/admin-panel'            // on Staging Enviroment
// basename='/'            // on Live Enviroment

ReactDOM.render(
  <BrowserRouter basename="/">
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'));

serviceWorker.register();
