import React, { useState } from "react";
import { Row, Col } from "antd";
import { changePassword } from "../../configurations/Schemas/index";
import { useSelector } from "react-redux";
import { changeUserPasswordRequest } from "../../actions/Auth";

import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import RootForm from "../../components/form/index";
import Input from "../../components/input/index";

const ChangeUserPass = (props) => {
  const queryParam = props?.location?.search;

  const emailCode = new URLSearchParams(queryParam);
  const email = emailCode.get("email");
  const otp = emailCode.get("otp");
  const [state, setState] = useState({
    email,
    otp,
    password: "",
    new_password: "",
    confirm_password: "",
  });
  
  const data = useSelector((state) => {
    return state;
  });

  const handleChanged = (e) => {
    const { name, value } = e.target;
    setState({
      [name]: value,
    });
  };
  return (
    <AuthContainer>
      <Row>
        <Col span={24}>
          <h3 className="mr-b mr-t">Change Your Password</h3>
          <p style={{ fontWeight: "600" }}>Choose a new Password</p>
        </Col>
        <Col span={24}>
          <Col xs={24} sm={22}>
            {/* <Input 
            // span: 24,
                  name= "password"
                  value= {state.password}
                  // handleChanged: handleChanged,
                  placeholder= "Current Password"
                  type= 'input'
                  /> */}
            <RootForm
              schemaName={changePassword}
              initialValues={state}
              apiCall={changeUserPasswordRequest}
              extras={state?.email}
              loading={data.Auth.forgotPasswordLoading}
              cb={() => {
                props.history.push({
                  pathname: "/login",
                });
              }}
              //autoWidthSubmit
              controls={[
                {
                  span: 24,
                  name: "password",
                  value: state.password,
                  handleChanged: handleChanged,
                  placeholder: "Current Password",
                  Type: Input,
                },
                {
                  span: 24,
                  name: "new_password",
                  value: state.new_password,
                  handleChanged: handleChanged,
                  placeholder: "New Password",
                  Type: Input,
                },
                {
                  span: 24,
                  name: "confirm_password",
                  value: state.confirm_password,
                  handleChanged: handleChanged,
                  placeholder: "Confirm Password",
                  Type: Input,
                },
              ]}
              submitButton={{
                span: 14,
                buttonText: "Continue",
                className: "mr-t-md mr-b-md bg-gradient",
              }}
            />
          </Col>
        </Col>
      </Row>
    </AuthContainer>
  );
};
export default ChangeUserPass;
