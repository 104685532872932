import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { RequestCard } from "../../../appcomponents/Traer/requests/cards";
import ContentHeader from "../../../appcomponents/shares/contentheader";
import RemoteImg from "../../../assets/images/game-remote.png";
//import ContentHeader from "../../../appcomponents/shares/contentheader";
import Blip from "../../../appcomponents/shares/blip";
import Card from "../../../components/card";
import useScroll from "../../../components/hooks/useScrollToBottom";
import { Row, Col, Skeleton } from "antd";
import { FiChevronsDown } from "react-icons/fi";
import styled from "styled-components";

import {
  requestsRequest,
  userRequestsRequest,
} from "../../../actions/Requests";

const ScrollDown = styled(FiChevronsDown)`
  position: absolute;
  top: auto;
  bottom: auto;
  //bottom: 80px;
  left: 0;
  right: 0;
  //margin: -40px auto 0;
  margin: 0 auto 0;
  font-size: 1.5vw;
  animation: move 3s ease-out infinite;
  color: ${(props) => props.theme[props.theme.mode].primary.main};
  @keyframes move {
    25% {
      opacity: 1;
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }
  @media screen and (max-width: 991px) {
    font-size: 1.8rem;
  }
`;

const Request = (props) => {
  let { requests, count, loading } = props.requests;
  let { userId } = props;
  const [Id, setId] = useState(userId);
  const [like, setLike] = useState("");
  const [status, setStatus] = useState("");

  console.log("REQUEST PROPS", props);
  // const [filters, setFilters] = useState({
  //   like: "",
  //   status: "",
  // });

  // const [mount, setMount] = useState(false)
  // const [hasScrolled, setHasScrolled] = useState(false)

  const scrolled = useScroll();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(4);

  // const [hasChanged, setHasChanged] = useState(false);

  // const apiCall = (limit, offset) => {
  //   props.requestsRequest({ limit, offset }, () => {
  //     //setOffset(offset + limit);
  //   });
  // };

  // User ID supplied
  // useEffect(() => {
  //   props.userRequestsRequest({ limit, offset, like, status }, !offset);
  // }, [offset, like, status, userId]);

  // No user ID supplied
  useEffect(() => {
    if (Id)
      props.userRequestsRequest({ limit, offset, like, status }, Id, !offset);
    else props.requestsRequest({ limit, offset, like, status }, !offset);
  }, [offset, like, status, Id]);

  //On scroll effect
  useEffect(() => {
    if (requests.length !== count && !loading) {
      setOffset(offset + limit);
    }
  }, [scrolled]);

  useEffect(() => {
    setId(userId);
    setOffset(0);
    setLike("");
    setStatus("");
  }, [userId]);

  // const refreshRecords = () => {
  //   apiCall(limit, 0)
  // }

  // const [state] = useState({
  //   apiCall: (...params) => props.usersRequest(...params),
  // });
  const searchChange = (e) => {
    setOffset(0);
    setLike(e);
  };

  const filterChange = (e) => {
    setOffset(0);
    setStatus(e);
  };
  return (
    <Row
      type="flex"
      // gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
      gutter={[15, 20]}
      justify="space-between"
    >
      <Col span={24}>
        <ContentHeader
          title="Requests"
          count={count}
          searchChange={searchChange}
          filterChange={filterChange}
          options={[
            { label: "View All", value: 0 },

            {
              label: (
                <Row
                  type="flex"
                  align="middle"
                  justify="center"
                  gutter={[5, 0]}
                >
                  <Col>
                    <Blip color="#F3C24D" margin={"0 0.25vw 0 0"} />
                  </Col>
                  <Col>Pending</Col>
                </Row>
              ),
              value: 3,
            },
            {
              label: (
                <Row
                  type="flex"
                  align="middle"
                  justify="center"
                  gutter={[5, 0]}
                >
                  <Col>
                    <Blip color="#22E6AA" margin={"0 0.25vw 0 0"} />
                  </Col>
                  <Col>Confirm</Col>
                </Row>
              ),
              value: 1,
            },
            {
              label: (
                <Row
                  type="flex"
                  align="middle"
                  justify="center"
                  gutter={[5, 0]}
                >
                  <Col>
                    <Blip color="#FF4235" margin={"0 0.25vw 0 0"} />
                  </Col>
                  <Col>Expired</Col>
                </Row>
              ),
              value: 2,
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <Row type="flex" gutter={[20, 20]}>
          {requests.map((item) => (
            <Col lg={6} md={12} sm={24} xs={24}>
              <RequestCard
                //name={item.name}
                id={item.id}
                quantity={item.quantity}
                userName={item.user_name}
                name={item.name}
                amount={item.reward}
                status={item.status}
                ImgUrl={item.imageUrl}
              />
            </Col>
          ))}
          {loading ? (
            Array(limit)
              .fill()
              .map(() => (
                <Col lg={6} md={12} sm={24} xs={24}>
                  <Card>
                    <Skeleton
                      loading={true}
                      avatar
                      active
                      paragraph={{ rows: 3 }}
                    ></Skeleton>
                  </Card>
                </Col>
              ))
          ) : !requests.length ? (
            <Col span={24}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "32vh 0",
                  textAlign: "center",
                }}
              >
                <h3>No Requests</h3>
              </div>
            </Col>
          ) : null}
        </Row>
        {/* {requests.length !== parseInt(count) ? <ScrollDown /> : null} */}
      </Col>
    </Row>
  );
};

export default connect((state) => ({ requests: state.Requests }), {
  requestsRequest,
  userRequestsRequest,
})(Request);
