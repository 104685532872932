import React from "react";
import { Row, Col } from "antd";
import moment from 'moment'
import InputWrapper from "../../../components/input";
import SelectWrapper from "../../../components/input/Select";
import OptionsWrapper from "../../../components/input/SelectOptions";
import Blip from "../../../appcomponents/shares/blip";
// import DatePicker from "../../../components/datepickers/DatePicker";
import RangePicker from "../../../components/datepickers/RangePicker";
import { FiArrowLeft } from "react-icons/fi";
import Button from "../../../components/button/index";

//Need to make this responsive
const EditContentHeader = (props) => {
  console.log(props, "users COMPONENT");
  return (
    <Row type="flex" align="middle" justify="space-between" gutter={[0, 10]}>
      <Row type="flex" align="middle" justify="space-between" gutter={[0, 10]}>
        {props.showBackBtn && (
          <Col style={{ marginRight: "15px" }}>
            <FiArrowLeft
              onClick={props.handleBack}
              style={{ color: "rgb(195 246 77)", fontSize: "20px", cursor: "pointer" }}
            />
          </Col>
        )}
        <Col>
          <h3
            className="no-mr"
            style={{ fontWeight: "bold", cursor: "pointer !important" }}
          >
            {props.title}
          </h3>
        </Col>
        <Col>
          &nbsp;&nbsp;
          <Blip />
          &nbsp;&nbsp;
          {!props.hideCount && (
            <span className="small" style={{ fontWeight: "bold" }}>
              {props.count || "0"}
            </span>
          )}
        </Col>
      </Row>
      {props.showPackage ? (
        <Row type="flex" align="middle">
          <Col
          //style={{ paddingLeft: 15 }}
          >
            <SelectWrapper
              id={"edit-package"}
              noBorder
              onChange={props.packageFilter}
              defaultValue={
                props.packageOptions ? props.packageOptions[0].label : "-"
              }
              style={{ width: "165px !important" }}
            >
              {props.packageOptions
                ? props.packageOptions.map((val) => {
                    return (
                      <OptionsWrapper value={val.value} key={val.value}>
                        {val.label}
                      </OptionsWrapper>
                    );
                  })
                : null}
            </SelectWrapper>
          </Col>
          <Col>
            <SelectWrapper
              noBorder
              onChange={props.statusFilter}
              defaultValue={
                props.statusOptions ? props.statusOptions[0].label : "-"
              }
              style={{ width: "100%" }}
            >
              {props.statusOptions
                ? props.statusOptions.map((val) => {
                    return (
                      <OptionsWrapper value={val.value} key={val.value}>
                        {val.label}
                      </OptionsWrapper>
                    );
                  })
                : null}
            </SelectWrapper>
          </Col>
          <Col>
            <RangePicker
              format="YYYY-MM"
              defaultValue={[moment(props.promoCreatedDate), moment(new Date())]}
              onChange={(e)=>{
                if(e.length>0){
                  props.dateFilter(e[0]._d, e[1]._d)
                }
              }}
              // value={value}
              // mode={mode}
              // onChange={this.handleChange}
              // onPanelChange={this.handlePanelChange}
            />
          </Col>
        </Row>
      ) : (
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          gutter={[0, 10]}
        >
          <Col>
            <span>
              <Button
                style={{ padding: "10px" }}
                sharpBorder
                isGreen
                size={"large"}
                block
                onClick={props.updatePromo}
                disabled={props.isDisabled}
              >
                {"Update Details"}
              </Button>
            </span>
          </Col>
        </Row>
      )}
    </Row>
  );
};

export default EditContentHeader;
