import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Empty } from "antd";
import RemoteImg from "../../../assets/images/game-remote.png";
import { RequestDetailCard } from "../../../appcomponents/Traer/requests/cards";
import {
  UserCard,
  TravellerCard,
  EmptyTravellerCard,
} from "../../../appcomponents/Traer/users/cards";
import ContentHeader from "../../../appcomponents/shares/contentheader";
import Loading from "../../../components/loading";

import { requestRequest, clearRequest } from "../../../actions/Requests";

const RequestDetails = (props) => {
  const { request, requestLoading: loading } = props.requests;
  const { data, traveller, travellerRequests } = request || {};

  const [requestInfo] = data || [];
  const [travellerInfo] = traveller || [];

  console.log("TY", request);

  useEffect(() => {
    props.requestRequest(props.match.params.id);
    return () => {
      props.clearRequest();
    };
  }, [props.match.params.id]);

  return !loading ? (
    request ? (
      <Row type="flex" gutter={[15, 15]} align="top">
        <Col xs={24} sm={24} md={12} lg={7}>
          <RequestDetailCard
            id={requestInfo.id}
            quantity={requestInfo.quantity}
            name={requestInfo.delivery_name}
            amount={requestInfo.reward}
            status={requestInfo.status}
            whereToPick={requestInfo.where_to_pick}
            address={requestInfo.address}
            weight={requestInfo.weight}
            requiredDate={requestInfo.require_date}
            notes={requestInfo.notes}
            ImgUrl={requestInfo.imageUrl}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={15}>
          <Row type="flex" gutter={[0, 35]}>
            <Col span={24}>
              <Row
                type="flex"
                justify="space-between"
                gutter={[{ xs: 0, lg: 15 }, 35]}
              >
                <Col xs={24} lg={11}>
                  <Row type="flex" gutter={[0, 35]}>
                    <Col span={24}>
                      <h3 className="no-mr fontBold">Requested By</h3>
                    </Col>
                    <Col span={24}>
                      <UserCard
                        name={requestInfo.name}
                        email={requestInfo.email}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={11}>
                  <Row type="flex" gutter={[0, 35]}>
                    <Col span={24}>
                      <h3 className="no-mr fontBold">Confirmed By</h3>
                    </Col>
                    <Col span={24}>
                      {!travellerInfo ? (
                        <EmptyTravellerCard />
                      ) : (
                        <TravellerCard
                          name={travellerInfo.fullname}
                          email={travellerInfo.email}
                          amount={travellerInfo.requested_reward}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row type="flex" gutter={[15, 35]}>
                <Col span={24}>
                  <ContentHeader
                    title="Traveler Requests"
                    count={travellerRequests.length}
                    hideFilters
                  />
                </Col>
                {travellerRequests.length ? (
                  travellerRequests.map((item) => (
                    <Col xs={24} lg={11}>
                      <TravellerCard
                        name={item.fullname}
                        email={item.email}
                        amount={item.requested_reward}
                      />
                    </Col>
                  ))
                ) : (
                  <Col span={24}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "10vh 0",
                        textAlign: "center",
                      }}
                    >
                      <h3>No Requests</h3>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    ) : (
      <div style={{ textAlign: "center", margin: "30vh 0" }}>
        <Empty description="Request not found" />
      </div>
    )
  ) : (
    <Loading />
  );
};

export default connect((state) => ({ requests: state.Requests }), {
  requestRequest,
  clearRequest,
})(RequestDetails);
