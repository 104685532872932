import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  GET_TRANSFER_TRANSACTIONS_REQUEST,
  DELETE_TRANSACTION_REQUEST,
  CLEARED_TRANSACTION_REQUEST,
} from "../configurations/Types";
import {
  GET_TRANSFER_JOURNAL,
  BANK_ACCOUNT_ENDPOINT,
  DELETE_TRANSACTIONS_ENDPOINT,
  CLEARED_TRANSACTIONS_ENDPOINT,
} from "../configurations/Constants";

import {
  deleteTransactionSuccess,
  deleteTransactionError,
  ClearedTransactionsSuccess,
  ClearedTransactionsError,
} from "../actions/SearchTransactions";

import {
  getTransferError,
  getTransferSuccess,
} from "../actions/TransferDetails";

import moment from "moment";
import info from "../components/message/index";
// import { tableData } from "../mocks/Users"
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";

let token;

const transferDetailsAPI = (params, token) =>
  privateAgent.get(`${BANK_ACCOUNT_ENDPOINT}${GET_TRANSFER_JOURNAL}`, {
    cancelToken: token.token,
    params: params,
  });

function* transferDetails(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield transferDetailsAPI(action.params, token);
    console.log("USER API GET", res.data);
    yield put(
      getTransferSuccess(res.data.data, action.clearFlag, action.ofPage)
    );
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    console.log("ERROR", e);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(getTransferError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

// Our watcher Saga: spawn a new Search Transactions task on each USERS_REQUEST
export function* watchTransferDetails() {
  yield takeLatest(GET_TRANSFER_TRANSACTIONS_REQUEST, transferDetails);
  //   yield takeLatest(DELETE_TRANSACTION_REQUEST, deleteTransactionRequest);
  //   yield takeLatest(CLEARED_TRANSACTION_REQUEST, clearedTransaction);
}
