import {
  EARNING_STATS_REQUEST,
  EARNING_STATS_SUCCESS,
  EARNING_STATS_ERROR,
  WAGERS_STATS_REQUEST,
  WAGERS_STATS_SUCCESS,
  WAGERS_STATS_ERROR,
  GAME_STATS_REQUEST,
  GAME_STATS_SUCCESS,
  GAME_STATS_ERROR,
  USER_STATS_REQUEST,
  USER_STATS_ERROR,
  MONTHLY_USER_STATS_REQUEST,
  APP_DOWNLOADS_REQUEST,
  USER_STATS_SUCCESS,
  MONTHLY_USER_STATS_SUCCESS,
  APP_DOWNLOADS_SUCCESS,
  MONTHLY_USER_STATS_ERROR,
  // ========= NEW TYPES ==========
  GET_USERS_STATS,
  GET_USERS_STATS_SUCCESS,
  GET_USERS_STATS_ERROR,
} from "../configurations/Types";

//User stats actions

export function getSubscribedUsers(cb) {
  return {
    type: GET_USERS_STATS,
    params: {}, //Can send params to query here
    callback: cb,
  };
}

export function getSubscribedUsersSuccess(payload) {
  return {
    type: GET_USERS_STATS_SUCCESS,
    payload,
  };
}

export function getSubscribedUsersError(errorInfo) {
  return {
    type: GET_USERS_STATS_ERROR,
    payload: errorInfo,
  };
}

export function userStatsResquest(cb) {
  return {
    type: USER_STATS_REQUEST,
    params: {}, //Can send params to query here
    callback: cb,
  };
}
export function userStatsSuccess(userData) {
  return {
    type: USER_STATS_SUCCESS,
    payload: userData,
  };
}
export function userStatsError(errorInfo) {
  return {
    type: USER_STATS_ERROR,
    payload: errorInfo,
  };
}

//Game Stats Actions:
export function gameStatsResquest(cb) {
  return {
    type: GAME_STATS_REQUEST,
    params: {}, //Can send params to query here
    callback: cb,
  };
}
export function gameStatsSuccess(gameData) {
  return {
    type: GAME_STATS_SUCCESS,
    payload: gameData,
  };
}
export function gameStatsError(errorInfo) {
  return {
    type: GAME_STATS_ERROR,
    payload: errorInfo,
  };
}

//Earning stats actions
export function earningStatsResquest(duration, cb) {
  return {
    type: EARNING_STATS_REQUEST,
    duration, //Can send params to query here
    callback: cb,
  };
}
export function earningStatsSuccess(earningData) {
  return {
    type: EARNING_STATS_SUCCESS,
    payload: earningData,
  };
}
export function earningStatsError(errorInfo) {
  return {
    type: EARNING_STATS_ERROR,
    payload: errorInfo,
  };
}

//Wagers stats actions
export function wagersStatsResquest(duration, cb) {
  return {
    type: WAGERS_STATS_REQUEST,
    duration, //Can send params to query here
    callback: cb,
  };
}
export function wagersStatsSuccess(wagersData) {
  return {
    type: WAGERS_STATS_SUCCESS,
    payload: wagersData,
  };
}
export function wagersStatsError(errorInfo) {
  return {
    type: WAGERS_STATS_ERROR,
    payload: errorInfo,
  };
}

//Monthly user stats actions
export function monthlyUserStatsRequest(month, year, cb) {
  return {
    type: MONTHLY_USER_STATS_REQUEST,
    params: { month, year }, //Can send params to query here
    callback: cb,
  };
}

export function monthlyUserStatsSuccess(monthlyUserStats) {
  return {
    type: MONTHLY_USER_STATS_SUCCESS,
    payload: monthlyUserStats,
  };
}

export function monthlyUserStatsError(errorInfo) {
  return {
    type: MONTHLY_USER_STATS_ERROR,
    payload: errorInfo,
  };
}

//App downloads actions
export function appDownloadsRequest(startDate, endDate, cb) {
  return {
    type: APP_DOWNLOADS_REQUEST,
    params: { startDate, endDate }, //Can send params to query here
    callback: cb,
  };
}

export function appDownloadsSuccess(appDownloads) {
  return {
    type: APP_DOWNLOADS_SUCCESS,
    payload: appDownloads,
  };
}
export function appDownloadsError(errorInfo) {
  return {
    type: APP_DOWNLOADS_SUCCESS,
    payload: errorInfo,
  };
}
