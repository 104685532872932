import * as Yup from "yup";

export const deposit = Yup.object().shape({
  bankName: Yup.string()
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your bank account name"),

  lawyerName: Yup.string()
    // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
    // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your lawyer name"),

  customerName: Yup.string()
    // .min(
    //   2,
    //   "customerName   is Too Short! it must be between 2 to 50 characters"
    // )
    // .max(
    //   50,
    //   "customerName   is Too Long! it must be between 2 to 50 characters"
    // )
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your customer name"),

  amount: Yup.string()
    // .typeError("Please provide a valid amount")
    //.min(1, "Please input a valid balance")
    .matches(
      /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/,
      "Please provide a valid amount"
    )
    .required("Please provide an amount")
    .notOneOf(["0"], "Please provide an amount"),

  accountName: Yup.string()
    // .min(
    //   2,
    //   "customerName   is Too Short! it must be between 2 to 50 characters"
    // )
    // .max(
    //   50,
    //   "customerName   is Too Long! it must be between 2 to 50 characters"
    // )
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your account name"),

  jobName: Yup.string()
    // .min(2, "jobName is Too Short! it must be between 2 to 50 characters")
    // .max(50, "jobName is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your job name")
    ,

  payor: Yup.string()
    // .min(2, "payor is Too Short! it must be between 2 to 50 characters")
    // .max(50, "payor is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    //.required("Please provide your payor")
    ,

  dateOfTransaction: Yup.date().required("Please provide transaction date").nullable(),
  //.required("Date of Transaction Required"),

  checkNumber: Yup.number().typeError("Check number must be a number")
  //.required("Check Number is Required")
  ,

  cleared: Yup.string()
  //.required("Cleared is Required")
  ,

  additionalNote: Yup.string(),

  image: Yup.string()
});
