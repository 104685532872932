import React from "react";

export const theme = {
  light: {
    common: {
      black: "#0C1330",
      white: "#fff",
    },
    background: {
      primary: "#ffffff",
      content: "#F8F8F8",
      secondary: "#F8F8F8",
      tertiary: "#f0f3f5",
      green: "#C3F64D",
    },
    primary: {
      // "light": "#e6f7ff",
      // "main": "#1890ff",
      light: "#F287871A",
      main: "#151D30",
      dark: "#202024",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      light: "#F3CB7E1A",
      main: "#F3CB7E",
      dark: "#c51162",
      contrastText: "#ffffff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#ffffff",
    },
    textColor: {
      primary: "#000000",
      secondary: "#FFFFFF",
      disabled: "rgba(12, 19, 48, 1)",
      hint: "#3F3F41",
      inverted: "rgba(255, 255, 255, 1)",
    },
    textSizes: {
      //tablets
      //mobiles
      actual: {
        banner: "2rem",
        heading: "1.5rem", // 48px : h1
        subHeading: "1.3rem", // 42px : h2
        pageHeading: "1.134rem", // 25px : h3
        generalHeading: "0.913rem", //20px : h4
        smallHeading: "0.895rem", // 19.7616px : h5 - *** Not being currently used ***
        bodyLarge: "0.82rem", // 18.1056px : h6 - *** Not being currently used ***
        body: "0.817rem", //22px : p
        captions: "0.785rem", // 18.1056px
        links: "0.765rem", //16.8912px;
        btn: "0.785rem", // 18.0394px;
      },
      // For Desktop
      relative: {
        banner: "5.217rem", // 115px : banner
        heading: "2.173rem", // 48px : h1
        subHeading: "1.913rem", // 42px : h2
        pageHeading: "1.2rem", //// "1.134rem" 25px : h3
        generalHeading: "1rem", // ".913rem" //20px : h4
        smallHeading: "0.895rem", // 19.7616px : h5 - *** Not being currently used ***
        bodyLarge: "0.82rem", // 18.1056px : h6 - *** Not being currently used ***
        body: "0.9rem", //"1rem" //22px : p
        captions: "0.817rem", // 18.1056px
        links: "0.765rem", //16.8912px;
        btn: "0.9rem", // 18.0394px;
      },
    },
    tag: {
      colors: {
        primary: "#22E6AA",
        secondary: "#FF4235",
        third: "#F3C24D",
        fourth: "#7787FF",
        fifth: "#22E6AA",
        sixth: "#FFBD30",
        seventh: "#FF4235",
      },
    },
    card: {
      borderRadius: "0.453rem",
      colors: {
        primary: "#3D31E9",
        secondary: "#31E99E",
        third: "#E9BE31",
        fourth: "#B84EE8",
      },
    },
    border: "#f8f8f8",
    spacing: {
      sidebar: "11.9rem",
      mainscreen: "3.65rem",
      outer: "1.59rem",
      inner: "1rem",
    },
    ratio: 2,
  },
  dark: {
    common: {
      black: "#fff",
      white: "#0C1330",
    },
    background: {
      primary: "#3a4149",
      secondary: "#2f353a",
      tertiary: "#343b41",
    },
    primary: {
      light: "#0f61af",
      main: "#1890ff",
      dark: "#303f9f",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      light: "#ff4081",
      main: "rgba(254, 173, 0, 1)",
      dark: "#c51162",
      contrastText: "#ffffff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#ffffff",
    },
    textColor: {
      primary: "rgba(255, 255, 255, 1)",
      secondary: "rgba(193, 29, 29, 1)",
      disabled: "rgba(12, 19, 48, 1)",
      hint: "rgba(255, 255, 255, 0.65)",
    },
    textSizes: {
      actual: {
        banner: "3.3rem",
        heading: "3rem",
        subHeading: "2.45rem",
        pageHeading: "1.57rem",
        generalHeading: "1.25rem",
        smallHeading: "1.08rem",
        bodyLarge: "1.04rem",
        body: "0.95rem",
        captions: "0.95rem",
        links: "0.95rem",
        btn: "0.95rem",
      },
      relative: {
        banner: "6vw",
        heading: "2.5vw",
        subHeading: "2.2vw",
        pageHeading: "1.305vw",
        generalHeading: "1.05vw",
        smallHeading: "1.03vw",
        bodyLarge: "1.01vw",
        body: "1.15vw",
        captions: "0.94vw",
        links: "0.94vw",
        btn: "0.94vw",
      },
    },
    tag: {
      colors: {
        primary: "#3677fd",
        secondary: "#26e5ab",
        third: "#fdbd31",
        fourth: "#fe4235",
      },
    },
    card: {
      borderRadius: 8,
    },
    border: "#23282c",
    spacing: {
      mainscreen: 100,
      outer: 35,
      inner: 14,
    },
    ratio: 2,
  },
};

export const ThemeContext = React.createContext(theme);
