import React from "react";
import { Menu } from "antd";
import styled, { css } from "styled-components";

const StyledMenu = styled(Menu)`
  display: flex;
  background: ${(props) => props.theme[props.theme.mode].background.primary};
  border: none;
  font-weight: 600;
  font-size: ${(props) => props.theme[props.theme.mode].textSizes.relative.btn};
  letter-spacing: 0.021vw;
  &.notificationsMenu {
    width: 30vw;
  }
  &.notificationsMenu .ant-row-flex {
    flex-wrap: nowrap;
  }
  .ant-menu-item-divider {
    margin: 0;
  }
  & svg {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.generalHeading};
  }
  & .navLinks {
    box-sizing: content-box;
    width: 100%;
  }
  & a,
  & li {
    color: ${(props) => props.theme[props.theme.mode].textColor.primary};
    z-index: 100;
    vertical-align: middle;
    line-height: 36px;
  }
  & .ant-menu-item a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2vw;
    font-weight: 600;
  }
  & .ant-menu-item.landingNav a {
    padding: 0 1.2vw;
  }
  & .ant-menu-item.sideBarItem a {
    padding: 0;
  }
  & .ant-menu-item {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.btn};
    top: 0 !important;
    border: none;
    border-radius: 3px;
    padding: 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.notificationsMenu .ant-menu-item {
    line-height: 12px;
    border-radius: 0;
    margin: 0;
    white-space: normal;
    padding: 0.6vw;
    height: unset;
    display: block;
  }

  & .ant-menu-item.sideBarItem {
    //margin-right: 12px;
    //margin-left: 12px;
    /* margin: 1.3rem 0.825rem;
    height: 2rem; */
    margin: 1.3rem 0.525rem;
    height: 2.5rem;
    line-height: normal;
  }
  & .ant-menu-item.sideBarItem a img {
    width: 1.5rem;
  }
  & .ant-menu-item:hover {
    border: none;
    // background-color: ${(props) =>props.theme[props.theme.mode].primary.light} !important;
    color: initial;
  }
  .ant-menu-item-selected {
    // color: ${(props) => props.theme[props.theme.mode].primary.main} !important;
    background:linear-gradient(98deg, rgba(195,246,77,1) 0%, rgba(180,216,98,1) 100%);
    // background-color: ${(props) => props.theme[props.theme.mode].primary.light};
  }
  .ant-menu-item-selected a {
    color: ${(props) => props.theme[props.theme.mode].primary.main} !important;
  }
  .ant-menu-item:not(.ant-menu-item-selected) a:hover {
    color: ${(props) =>
      props.theme[props.theme.mode].textColor.primary} !important;
  }

  ${(props) =>
    props.mode === "horizontal"
      ? null
      : css`
          & {
            display: block;
          }
          & .ant-menu-item-selected {
            background-color: ${(props) =>
              props.theme[props.theme.mode].primary.light} !important;
            color: ${(props) =>
              props.theme[props.theme.mode].textColor.primary};
          }
          ${(props) =>
            props.theme.mode === "light"
              ? null
              : css`
                  .ant-menu-item:active {
                    background-color: ${(props) =>
                      props.theme[props.theme.mode].primary.light};
                  }
                `}
          & .ant-menu-item {
            margin-right: 0;
          }
        `}

  ${(props) =>
    props.align === "right"
      ? css`
          justify-content: flex-end;
        `
      : null}
  ${(props) =>
    props.align === "center"
      ? css`
          justify-content: center;
        `
      : null}
  ${(props) =>
    props.align === "left"
      ? css`
          justify-content: flex-start;
        `
      : null}
  ${(props) =>
    props.background === "transparent"
      ? css`
          & .ant-menu-item-selected,
          & .ant-menu-item,
          & .ant-menu-item:hover,
          & .ant-menu-item:focus,
          & .ant-menu-item:active {
            background-color: transparent !important;
          }
        `
      : null}
  @media screen and (max-width: 991px) {
    font-size: calc(
      ${(props) => props.theme[props.theme.mode].textSizes.actual.btn} - 4px
    );
    letter-spacing: 0.45px;
    & svg {
      font-size: calc(
        ${(props) =>
            props.theme[props.theme.mode].textSizes.actual.generalHeading} - 4px
      );
    }
  }

  .flexFill {
    flex-grow: 1;
  }

  .zeroLineHeight {
    line-height: 0;
  }

  .small {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.links};
    line-height: 1.46;
    letter-spacing: 0.0185vw;
  }
`;

const MenuWrapper = (props) => {
  return <StyledMenu {...props}></StyledMenu>;
};

export default MenuWrapper;
