import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  USERS_REQUEST,
  USER_REQUEST,
  CHANGE_USER_STATUS_REQUEST,
  GET_USER_EMAIL_REQUEST
} from "../configurations/Types";
import {
  USERS_ENDPOINT,
  USER_ENDPOINT,
  CHANGE_USER_STATUS_ENDPOINT,
  GET_USER_EMAIL
} from "../configurations/Constants";
import {
  receivedUsers,
  usersError,
  receivedUser,
  userError,
  changeUserStatusSuccess,
  changeUserStatusError,
  getUserEmailSuccess,
  getUserEmailError
} from "../actions/Users";
import info from "../components/message/index";
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
import moment from "moment";
//import { tableDataGenerator } from '../mocks/users';
//const usersAPI = (ms) => new Promise((res, rej) => setTimeout(() => res(tableDataGenerator(12)), ms))
const usersAPI = (params, token) =>
  privateAgent.get(USERS_ENDPOINT, {
    cancelToken: token.token,
    params: params,
  });
const userAPI = (userId) => privateAgent.get(`${USER_ENDPOINT}/${userId}`);

const userStatusAPI = ({user_id, status}) => {
  let res = privateAgent.patch(
    `${CHANGE_USER_STATUS_ENDPOINT}/${user_id}`,
    {
      status,
    }
  );

  return res;
};
const userEmailApi = (params, token) =>
  privateAgent.get(GET_USER_EMAIL, {
    cancelToken: token.token,
    params: { ...params },
  });
let token;

// Our worker Saga: will perform the async getUsers task
function* usersRequest({params, callback}) {
  let res;
  try {
    token && token.cancel();
    token = CancelToken.source();
    res = yield usersAPI(params, token);
    if(res.status == 200){
      yield put(
        receivedUsers({
         users: res.data.data.rows,
          count:res.data.data.count
        }
        )
      );
    }
  } catch (e) {
    console.log("ERROR", e);
    yield put(usersError(e));
    // try {
      info(
        "error",
        `${e.response ? e.response.data.error.message : "Network Error"}`
      );
    // } catch (e) {
    //   console.log('Error: ', e);
    // }
  }
}

function* userRequest(action) {
  console.log("USERSAGARUN", action);
  let res;
  try {
    res = yield userAPI(action.id);
    yield put(receivedUser(res.data.data));
  } catch (e) {
    console.log("ERROR", e);
    yield put(userError(e));
    try {
      info(
        "error",
        `${e.response ? e.response.data.error.message : "Network Error"}`
      );
    } catch (e) {}
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
  //yield put(receivedUsers(res.data.data))
}

function* userStatusRequest({params,cb}) {
  console.log("userStatus_payload", params,cb);
  let res;
  try {
    res = yield userStatusAPI(params);
    if(res.status == 200){
      info(
        "success",`${ res?.data?.message}`
      );
      yield put(changeUserStatusSuccess(res));
      if (cb) {
        cb(res);
      }
    }
  } catch (e) {
    console.log("ERROR", e);
    yield put(changeUserStatusError(e));
    info(
      "error",
      `${e.response ? e.response.data.error.message : "Network Error"}`
    );
    // if (action.cb) {
    //   action.cb();
    // }
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

function* getUserEmail({email}) {
  console.log("getUserEmail", email);
  const userEmailObj={
    search:email.email,
  }
  let res;
  try {
    token && token.cancel();
    token = CancelToken.source();
    res = yield userEmailApi(userEmailObj,token);
    console.log("getUserEmail_res", res);
    yield put(getUserEmailSuccess(res));
  } catch (e) {
    console.log("getUserEmilERROR", e.response);
    yield put(getUserEmailError(e));
    info(
      "error",
      `${e.response ? e.response.data.error.message : "Network Error"}`
    );
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

// Our watcher Saga: spawn a new getUsers task on each USERS_REQUEST
export function* watchGetUsers() {
  yield takeLatest(USERS_REQUEST, usersRequest);
  yield takeLatest(USER_REQUEST, userRequest);
  yield takeLatest(CHANGE_USER_STATUS_REQUEST, userStatusRequest);
  yield takeLatest(GET_USER_EMAIL_REQUEST, getUserEmail);
}
