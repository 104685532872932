import {
  GET_BANK_TRANSACTION_REQUEST,
  GET_BANK_TRANSACTION_SUCCESS,
  GET_BANK_TRANSACTION_ERROR,
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERROR,
  CLEAR_TRANSACTION,
} from "../../configurations/Types";

//Get Bank Transaction
export const getBankTransactionsRequest = (id, filter, cb) => {
  return {
    type: GET_BANK_TRANSACTION_REQUEST,
    id,
    filter,
    cb,
  };
};
export const getBankTransactionsSuccess = (payload, clearFlag, ofPage) => {
  console.log("Transaction Success Action: ", payload, clearFlag, ofPage);
  return {
    type: GET_BANK_TRANSACTION_SUCCESS,
    payload,
    clearFlag,
    ofPage,
  };
};
export const getBankTransactionsError = (cb) => {
  return {
    type: GET_BANK_TRANSACTION_ERROR,
    cb,
  };
};

//Get Single Transaction
export const getTransactionRequest = (id, cb) => {
  return {
    type: GET_TRANSACTION_REQUEST,
    id,
    cb,
  };
};
export const getTransactionSuccess = (payload, cb) => {
  console.log("Transaction Success Action: ", payload);
  return {
    type: GET_TRANSACTION_SUCCESS,
    payload,
    cb,
  };
};
export const getTransactionError = (cb) => {
  return {
    type: GET_TRANSACTION_ERROR,
    cb,
  };
};

export const clearTransaction = () => {
  return {
    type: CLEAR_TRANSACTION,
  };
};
