import React, { useEffect, useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading";
import { Row, Col } from "antd";
import Icon from "../../../components/Icon";
import { useHistory, useLocation } from "react-router-dom";
import Input from "../../../components/input";
import Button from "../../../components/button";
import useFormik from "../../../components/formik/useFormik";
import SelectWrapper from "../../../components/input/Select";
import AutoCompleteWrapper from "../../../components/input/AutoComplete";

import { changeUserStatusRequest, getUserEmailRequest } from "../../../actions/Users";
import { addOrganizationRequest } from "../../../actions/Organization";
import { clearRequests } from "../../../actions/Requests";
import OptionsWrapper from "../../../components/input/SelectOptions";
import { addOrganizationSchema } from "../../../configurations/Schemas/Organization";
import { findRenderedDOMComponentWithClass } from "react-dom/test-utils";
import { Select, AutoComplete } from "antd";

const AddOrganization = (props) => {
  console.log("AddOrganization_props: ", props);
  const { user } = props.location.state ? props.location.state : {};
  const { full_name, email, status } = user ? user : {};

  const location = useLocation();
  const dispatch = useDispatch()

  const [state, setState] = useState({
    email: "",
  });


  const formik = useFormik({
    validationSchema: addOrganizationSchema,
    // initialValues: location.state || {
    initialValues:{
      // userId: "",
      name: "",
      email: "",
      phone: "",
      location: "",
      orgName: "",
      ownerName: "",
      usersLimit: "",
      pricePerUser: "",
      subscriptionPackage: "",
    },
    onSubmit: (values, { ...rest }) => {
      console.log('formik_values', formik.values, values);
      dispatch(addOrganizationRequest(values, (res) => {
        if (res?.status == 200) {
          props.history.push("/organization");
        }
      }));
    },
  });

  const subscriptionPackages = [
    { key: 'standard', value: "Standard Package" },
    { key: 'premium', value: "Premium Package" },
  ];

  function onChange(email) {
    console.log(email, "email");
    setState({ ...state, email: email });
    formik.setFieldValue("email", email);
    // formik.setFieldValue("userId", "");
  }

  function onSelectSubscription(packageId) {
    formik.setFieldValue("subscriptionPackage", packageId);
  }

  function onSelect(email) {
    console.log("onSelect_email: ", email);
    let userObj = props?.userData?.userEmails?.find((val) => val.email == email);
    console.log(userObj, "aaaaaaa");
    if (userObj) {
      formik.setFieldValue("name", userObj.fullName);
      formik.setFieldValue("email", userObj.email);
      // formik.setFieldValue("userId", userObj.user_id);
    } 
    // else {
      // formik.setFieldValue("email", userObj.email);
    // }
  }

  useEffect(() => {
    if(state.email) {props.getUserEmailRequest({ ...state });}
  }, [state]);

  const field = useCallback(
    (placeholder, name, value, customProps) => (
      <>
        <Input
          style={{ marginBottom: "1%", marginTop: "5px" }}
          placeholder={placeholder}
          value={value}
          name={name}
          //   disabled
          onChange={(e) => {
            //console.log(e.target.value, 'inside onChange func of ', name)
            formik.customHandleChange(e, name);
          }}
          onBlur={(e) => formik.customHandleBlur(e, name)}
          {...customProps}
        />
        <span className="dangerColor textCenter">
          {formik.touched[name] && formik.errors[name]}
        </span>
      </>
    ),
    [formik]
  );

  return true ? (
    <>
      <Col>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <Link to="/organization">
            <Icon
              type="arrow-left"
              style={{ fontSize: "15px", color: "#C3F64D" }}
            />
          </Link>
          <h4 style={{ marginLeft: 10, marginTop: 5 }}> Add Organization</h4>
        </div>
      </Col>

      <Col
        style={{
          paddingLeft: "8%",
          paddingRight: "8%",
          paddingTop: 30,
          paddingBottom: 50,
        }}
      >
        <div>
          <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
            {" "}
            Details{" "}
          </h3>
          <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
            <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
              <Col
                style={{ marginBottom: "15px" }}
                xl={12}
                lg={24}
                sm={24}
                xs={24}
                span={24}
              >
                {/* <Col span={24}> */}

                {/* </Col>/= */}
                <Col span={24}>
                  <label className="mr-b-sm" style={{ marginBottom: "25px" }}>
                    Name
                  </label>
                  {field("", "name", formik.values["name"])}
                </Col>
              </Col>

              <Col
                style={{ marginBottom: "15px" }}
                xl={12}
                lg={12}
                sm={24}
                xs={24}
                span={12}
              >
                <label>Email</label>
                <AutoCompleteWrapper
                  // noBorder
                  showArrow={false}
                  showSearch
                  onSelect={(e) => onSelect(e)}
                  // onSelect={() => alert()}
                  onSearch={(e) => onChange(e)}
                  noBorder={true}
                  onFocus={() => {
                    console.log(formik.values["email"]);
                    formik.setFieldValue("email", formik.values["email"]);
                  }}
                  loading={props?.userData?.userEmails ? false : true}
                  // onChange={props.filterChange}
                  // value={formik.values["email"]?.length < 2 ? "" : formik.values["email"]}
                  // defaultValue={'Enter Email'}
                  placeholder={"Email"}
                  // options={props.userData.users}
                  // defaultValue={formik.values["email"]}
                  style={{ width: "100%", }}
                >
                  {props?.userData?.userEmails?.map((val, index) => {
                    return (
                      <AutoComplete.Option value={val.email} key={index}>
                        {val.email}
                      </AutoComplete.Option>
                    );
                  })}
                </AutoCompleteWrapper>

                <span
                  style={{ marginTop: "10px", marginLeft: "2px" }}
                  className="dangerColor textCenter"
                >
                  {formik.touched["email"] && formik.errors["email"]}
                </span>
              </Col>
            </Row>

            <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
              <Col
                style={{ marginBottom: "15px" }}
                xl={12}
                lg={12}
                sm={24}
                xs={24}
                span={12}
              >
                <Col span={24}>
                  <label>Organization Owner Name</label>
                  {field("", "ownerName", formik.values["ownerName"], {
                    onChange: (e) => {
                      formik.customHandleChange(e, "ownerName");
                      // here you dispatch your api for checking the email
                    },
                  })}
                </Col>
              </Col>
              <Col
                style={{ marginBottom: "15px" }}
                xl={12}
                lg={12}
                sm={24}
                xs={24}
                span={12}
              >
                <Col span={24}>
                  <label>Organization Name</label>
                  {field(
                    "",
                    "orgName",
                    formik.values["orgName"],
                    {
                      onChange: (e) => {
                        formik.customHandleChange(e, "orgName");
                        // here you dispatch your api for checking the email
                      },
                    }
                  )}
                </Col>
              </Col>
            </Row>

            <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
              <Col
                style={{ marginBottom: "15px" }}
                xl={12}
                lg={12}
                sm={24}
                xs={24}
                span={12}
              >
                <Col span={24}>
                  <label>Phone Number</label>
                  {field("", "phone", formik.values["phone"], {
                    onChange: (e) => {
                      formik.customHandleChange(e, "phone");
                      // here you dispatch your api for checking the email
                    },
                  })}
                </Col>
              </Col>

              <Col
                style={{ marginBottom: "15px" }}
                xl={12}
                lg={12}
                sm={24}
                xs={24}
                span={12}
              >
                <Col span={24}>
                  <label>Price per user/year</label>
                  {field("", "pricePerUser", formik.values["pricePerUser"], {
                    onChange: (e) => {
                      formik.customHandleChange(e, "pricePerUser");
                      // here you dispatch your api for checking the email
                    },
                  })}
                </Col>
              </Col>
            </Row>

            <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
              <Col
                style={{ marginBottom: "15px" }}
                xl={12}
                lg={12}
                sm={24}
                xs={24}
                span={12}
              >
                <Col span={24}>
                  <label>Subscription Package</label>
                  <SelectWrapper
                    // noBorder
                    onSelect={onSelectSubscription}
                    onChange={props.filterChange}
                    defaultValue={
                      props.options ? props.options[0].label : "Choose Package"
                    }
                    style={{ width: "100%", marginTop: "5px" }}
                  >
                    {subscriptionPackages.map((val) => {
                      return (
                        <Select.Option value={val.key} key={val.value}>
                          {val.value}
                        </Select.Option>
                      );
                    })}
                  </SelectWrapper>
                  <span className="dangerColor textCenter">
                    {formik.touched["subscriptionPackage"] &&
                      formik.errors["subscriptionPackage"]}
                  </span>
                </Col>
              </Col>

              <Col
                style={{ marginBottom: "15px" }}
                xl={12}
                lg={12}
                sm={24}
                xs={24}
                span={12}
              >
                <Col span={24}>
                  <label>Location</label>
                  {field("", "location", formik.values["location"], {
                    onChange: (e) => {
                      formik.customHandleChange(e, "location");
                    },
                  })}
                </Col>
              </Col>
            </Row>

            <Row type={"flex"} gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
              <Col xl={12} lg={12} sm={24} xs={24} span={12}>
                <Col span={24}>
                  <label>Allowed User</label>
                  {field("", "usersLimit", formik.values["usersLimit"], {
                    onChange: (e) => {
                      formik.customHandleChange(e, "usersLimit");
                    },
                  })}
                </Col>
              </Col>
            </Row>
          </Row>
        </div>
      </Col>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{
            width: 200,
            boxShadow: "0px 5px 5px 1px rgba(0,0,0,0.15)",
            background: "#C3F64D",
            color: "#151D30",
          }}
          sharpBorder
          size={"large"}
          block
          disabled={props.organizationState.loading ? true : false}
          onClick={() => formik.submitForm()}
        >
          {props.organizationState.loading ? "Loading.." : "Add Organization"}
        </Button>
      </div>
    </>
  ) : (
    // ) : (
    //     <Redirect to="/" />
    //     // <div style={{ margin: "30vh 0", textAlign: "center" }}>
    //     //   <Empty description="User not found" />
    //     // </div>
    //   )
    <Loading />
  );
};
const mapStateToProps = (state) => ({
  userData: state.Users,
  organizationState: state.Organization,
});
export default connect(mapStateToProps, {
  changeUserStatusRequest,
  clearRequests,
  getUserEmailRequest,
  addOrganizationRequest,
})(AddOrganization);
