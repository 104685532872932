import {
  ORGANIZATION_SUCCESS,
  ORGANIZATION_REQUEST,
  ORGANIZATION_ERROR,
  ADD_ORGANIZATION_REQUEST,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_ERROR,
  ORGANIZATION_DETAIL_REQUEST,
  ORGANIZATION_DETAIL_SUCCESS,
  ORGANIZATION_DETAIL_ERROR,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  totalCount: 0,
  organization: [],
  organizationDetail: {},
  userDetail: {
    subscriptions: [],
  },
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //For Flagged profiles requests
    case ORGANIZATION_REQUEST: {
      let newArr = [...state.organization];
      if (action.flag) {
        newArr = [];
      }
      newArr = [...newArr, 1, 2, 3, 4, 5, 6, 7, 8];
      return Object.assign({}, state, { totalCount: 0, loading: true });
    }

    case ORGANIZATION_SUCCESS: {
      const arr = action.payload;
      return Object.assign({}, state, {
        totalCount: arr.count,
        organization: arr.rows,
        loading: false,
      });
    }
    case ORGANIZATION_ERROR: {
      return Object.assign({}, state, { organization: [], loading: false });
    }

    case ADD_ORGANIZATION_REQUEST: {
      return { ...state, loading: true };
    }

    case ADD_ORGANIZATION_SUCCESS: {
      return { ...state, loading: false };
    }

    case ADD_ORGANIZATION_ERROR: {
      return { ...state, loading: false };
    }

    case ORGANIZATION_DETAIL_REQUEST: {
      return { ...state, loading: true };
    }

    case ORGANIZATION_DETAIL_SUCCESS: {
      return { ...state, loading: false, organizationDetail: action.payload };
    }

    case ORGANIZATION_DETAIL_ERROR: {
      return { ...state, loading: false };
    }

    case USER_DETAIL_REQUEST: {
      return { ...state, loading: true };
    }

    case USER_DETAIL_SUCCESS: {
      return { ...state, loading: false, userDetail: action.payload };
    }

    case USER_DETAIL_ERROR: {
      return { ...state, loading: false };
    }

    case UPDATE_ORGANIZATION_REQUEST: {
      return { ...state, loading: true };
    }

    case UPDATE_ORGANIZATION_SUCCESS: {
      return { ...state, loading: false };
    }

    case UPDATE_ORGANIZATION_ERROR: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};
