import React from "react";
import Logo from "../assets/images/Logo-Escrow-Trakker.svg";
import MaleAvatar from "../assets/images/maleavatar.png";
import FemaleAvatar from "../assets/images/femaleavatar.svg";
import Image from "../components/image/index";
// const HeaderLogo = () => {
//   return <img src={Logo} style={{ width: "128px", height: "64px" }}></img>;
// };

//For Now Both logo are same it may designer give fullLogo sepeartly
const HeaderLogo = () => {
  return <Image src={Logo} alt="EscrowTrakker" />;
};
const fullHeaderLogo = () => {
  return <Image src={Logo} alt="EscrowTrakker" />;
};
const fullFooterLogo = () => {
  return <Image src={Logo} alt="EscrowTrakker" />;
};

const numWithCommas = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const AccountingFormat = (amount, currency = "") => {
  if (amount < 0)
    return `${currency}(${numWithCommas(
      Number(Math.abs(amount)).toFixed(2).replace("-", "")
    )})`;
  else
    return `${currency}${numWithCommas(
      Number(amount).toFixed(2).replace("-", "")
    )}`;
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const formattedDate = (date = new Date()) => {
  const newDate = new Date(date); // 2009-11-10
  const month = newDate.toLocaleString("default", { month: "short" });
  return `${newDate.getDate()} - ${month} - ${newDate.getFullYear()}`;
};

const menuShow = {
  rightMenu: false,
  leftMenu: true,
};
const avatarIcons = {
  male: MaleAvatar,
  female: FemaleAvatar,
};
export {
  HeaderLogo,
  menuShow,
  avatarIcons,
  fullHeaderLogo,
  fullFooterLogo,
  AccountingFormat,
  formattedDate,
};
