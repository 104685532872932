import {
  ADD_PROMOCODE_REQUEST,
  ADD_PROMOCODE_SUCCESS,
  ADD_PROMOCODE_ERROR,
  GET_PROMOCODE_REQUEST,
  GET_PROMOCODE_SUCCESS,
  GET_PROMOCODE_ERROR,
  EDIT_PROMOCODE_REQUEST,
  EDIT_PROMOCODE_SUCCESS,
  EDIT_PROMOCODE_ERROR,
  DELETE_PROMOCODE_REQUEST,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_ERROR,
  ACTIVE_INACTIVE_PROMOCODE_REQUEST,
  ACTIVE_INACTIVE_PROMOCODE_SUCCESS,
  ACTIVE_INACTIVE_PROMOCODE_ERROR,
  GET_PROMOCODE_USERS_ERROR,
  GET_PROMOCODE_USERS_REQUEST,
  GET_PROMOCODE_USERS_SUCCESS,
  GET_PROMOCODE_DETAILS_REQUEST,
  GET_PROMOCODE_DETAILS_SUCCESS,
  GET_PROMOCODE_DETAILS_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  totalCount: 0,
  promoCodes: [],
  promoCodeDetails: {},
  promoCodeUsers: [],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PROMOCODE_REQUEST:
    case DELETE_PROMOCODE_REQUEST:
    case ACTIVE_INACTIVE_PROMOCODE_REQUEST:
    case GET_PROMOCODE_USERS_REQUEST:
    case EDIT_PROMOCODE_REQUEST: {
      return { ...state, loading: true };
    }

    case ADD_PROMOCODE_SUCCESS: {
      return { ...state, loading: false };
    }

    case ADD_PROMOCODE_ERROR:
    case DELETE_PROMOCODE_SUCCESS:
    case DELETE_PROMOCODE_ERROR:
    case ACTIVE_INACTIVE_PROMOCODE_SUCCESS:
    case ACTIVE_INACTIVE_PROMOCODE_ERROR:
    case GET_PROMOCODE_USERS_ERROR:
    case EDIT_PROMOCODE_SUCCESS:
    case EDIT_PROMOCODE_ERROR: {
      return { ...state, loading: false };
    }
    case GET_PROMOCODE_REQUEST: {
      let newArr = [...state.promoCodes];
      if (action.flag) {
        newArr = [];
      }
      newArr = [...newArr, 1, 2, 3, 4, 5, 6, 7, 8];
      return Object.assign({}, state, { totalCount: 0, loading: true });
    }

    case GET_PROMOCODE_SUCCESS: {
      let arr = action.payload;
      return Object.assign({}, state, {
        totalCount: arr.count,
        promoCodes: arr.rows,
        loading: false,
      });
    }

    case GET_PROMOCODE_USERS_SUCCESS: {
      console.log("action payload ", action.payload);
      console.log("action payload count ", action.payload.count);
      console.log("action payload subscriptionCount ", action.payload.subscriptionCount);
      return {
        ...state,
        loading: false,
        promoSubscriptionCount: action.payload.subscriptionCount,
        promoCodeUsers: action.payload.users,
        users: action.payload.count,
      };
    }

    case GET_PROMOCODE_ERROR: {
      return Object.assign({}, state, { promoCodes: [], loading: false });
    }

    // Get Promo Code Details Reducer Start
    case GET_PROMOCODE_DETAILS_SUCCESS: {
      return Object.assign({}, state, { promoCodeDetails: action?.payload, loading: false });
    }
    // Get Promo Code Details Reducer End
    default: {
      return state;
    }
  }
};
