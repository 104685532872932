import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  ADD_PROMOCODE_REQUEST,
  ADD_PROMOCODE_SUCCESS,
  ADD_PROMOCODE_ERROR,
  GET_PROMOCODE_REQUEST,
  GET_PROMOCODE_SUCCESS, 
  GET_PROMOCODE_ERROR,
  EDIT_PROMOCODE_REQUEST,
  EDIT_PROMOCODE_SUCCESS,
  EDIT_PROMOCODE_ERROR,
  DELETE_PROMOCODE_REQUEST,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_ERROR,
  ACTIVE_INACTIVE_PROMOCODE_REQUEST,
  GET_PROMOCODE_USERS_REQUEST,
  GET_PROMOCODE_DETAILS_REQUEST
} from "../configurations/Types";
import { PROMOCODE_ENDPOINT,EDIT_PROMOCODE_ENDPOINT } from "../configurations/Constants";
import { addPromoCodeSuccess, addPromoCodeError, getPromosSuccess, getPromosError, toggleActivatePromoSuccess, getPromocodeUsersSuccess, getPromoUsersError, updatePromoRequest, updatePromoError, updatePromoSuccess,getPromosDetails_success } from "../actions/PromoCode";
import info from "../components/message/index";
import moment from "moment";
// import { tableData } from "../mocks/Users"
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
let token;

const promosAPI = (params, token) =>
  privateAgent.get(PROMOCODE_ENDPOINT, {
    cancelToken: token.token,
    params: { ...params },
  });

const promoDetailsAPI = (
  id,params
  ) => privateAgent.get(`${PROMOCODE_ENDPOINT}/${id}`,{
   params
  });

function* getPromos(action) {
  console.log(action, "actionparamsss");
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield promosAPI(action.params, token);
    console.log('getPromos_res: ', res);
    
    yield put(getPromosSuccess(res.data.data));
  } catch (e) {
    console.log("ERROR", e);
    try {
      info(
        "error",
        `${e.response ? e.response.data?.error?.message : "Network error"}`
      );
    } catch (e) {}
    yield put(getPromosError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

function* deletePromoCode(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield privateAgent.delete(`${PROMOCODE_ENDPOINT}/${action.id}`);
    info("success", `${res.data.message}`);
    yield put(addPromoCodeSuccess());
    // yield put(getPromos(action))
  } catch (e) {
    console.log("ERROR", e.response);
    try {
      info(
        "error",
        `${e.response ? e.response.data.message : "Network error"}`
      );
    } catch (e) {}
    yield put(getPromosError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

function* updatePromoCode({id,body,cb}) {
  console.log('updatePromoCode_action: ', id,"-",body,"-",cb);
  
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield privateAgent.patch(`${EDIT_PROMOCODE_ENDPOINT}/${id}`, body);
    console.log('updatePromoCode_res: ', res);
    info("success", `${res.data.message}`);
    if(cb){cb(res)}
    yield put(updatePromoSuccess());
    // yield put(getPromos(action))
  } catch (e) {
    console.log("ERROR", e.response);
    try {
      info(
        "error",
        `${e.response ? e.response.data.message : "Network error"}`
      );
    } catch (e) {}
    yield put(updatePromoError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  console.log("In saga", res);
  if (cb) {
    cb(res);
  }
}

function* addPromoCode(action) {
  console.log(`action `,action);
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield privateAgent.post(`${PROMOCODE_ENDPOINT}`, action.body);
    if(res?.data?.status){
      info("success", res?.data?.message);
      yield put(addPromoCodeSuccess(res));
      if(action.callback){
        action.callback(res)
      }
    }
  } catch (e) {

    try {
      info(
        "error",
        `${e.response ? e.response.data.error.message : "Network error"}`
      );
    } catch (e) {}
    yield put(addPromoCodeError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

function* toggleActivatePromoCode({id,status,cb}) {
  // console.log('toggleActivatePromoCode: ', action);
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield privateAgent.patch(`${PROMOCODE_ENDPOINT}/${id}`, {
      status,
    });

    console.log('Toggel_res: ', res);
    info("success", `${res.data.message}`);
    yield put(toggleActivatePromoSuccess());
    // yield put(getPromos(action))
  } catch (e) {
    console.log("ERROR", e.response);
    try {
      info(
        "error",
        `${e.response ? e.response.data.message : "Network error"}`
      );
    } catch (e) {}
    yield put(getPromosError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (cb) {
    cb(res);
  }
}

// function* getPromoUsers(action) {
//   console.log("action.id ",action);
//   let res;
//   try {
//     if (token) {
//       token.cancel();
//     }
//     token = CancelToken.source();
//     res = yield privateAgent.get(`${PROMOCODE_ENDPOINT}/${action.id}/user`, {
//       params: { ...action.params },
//     });
//     console.log("🚀 ~ file: PromoCode.js ~ line 168 ~ function*getPromoUsers ~ res", res)
    
//     info("success", `${res.data.message}`);    
//     yield put(getPromocodeUsersSuccess(res.data.data));
//   } catch (e) {
//     console.log("ERROR", e);
//     try {
//       info(
//         "error",
//         `${e.response ? e.response.data.message : "Network error"}`
//       );
//     } catch (e) {}
//     yield put(getPromoUsersError(e));
//   } finally {
//     if (yield cancelled()) {
//       console.log("CANCELLED");
//     }
//   }
//   //console.log("In saga", res);
//   if (action.callback) {
//     action.callback(res);
//   }
// }

function* getPromoCode_details({id,payload}){
  console.log('getPromoCode_details_ID: ',id, payload);

  let res;
  try {
    res = yield promoDetailsAPI(id,payload);
    // res = yield privateAgent.get(`${PROMOCODE_ENDPOINT}/${payload?.id}`);
    console.log('getPromoCode_details: ', res);
    // info("success", `${res.data.message}`);
    yield put(getPromosDetails_success(res?.data?.data));
  } catch (e) {
    console.log("ERROR", e.response);
    try {
      // info(
      //   "error",
      //   `${e.response ? e.response.data.message : "Network error"}`
      // );
    } catch (e) {}
    yield put(getPromosError(e));
  }
}

export function* watchPromoCode() {
  yield takeLatest(ADD_PROMOCODE_REQUEST, addPromoCode);
  yield takeLatest(GET_PROMOCODE_REQUEST, getPromos)
  yield takeLatest(DELETE_PROMOCODE_REQUEST, deletePromoCode)
  yield takeLatest(ACTIVE_INACTIVE_PROMOCODE_REQUEST, toggleActivatePromoCode)
  // yield takeLatest(GET_PROMOCODE_USERS_REQUEST, getPromoUsers)
  yield takeLatest(EDIT_PROMOCODE_REQUEST, updatePromoCode)
  yield takeLatest(GET_PROMOCODE_DETAILS_REQUEST, getPromoCode_details)
}
