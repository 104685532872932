import React,{useState} from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { resetPassword } from "../../configurations/Schemas/index";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../actions/Auth";


const ResetScreen = props => {

  const history = useHistory()
  const location = useLocation()

  const [state,setState] = useState({
    email:location?.state?.email,
    password:'',
    confirm_password:''
  })

  const data = useSelector(state => {
    return state
  })
  
  console.log('ResetScreen_data: ', data);

  const handleChanged = (e) => {
    const { name, value } = e.target;
    setState({
      [name]: value,
    });
  };
  const {email,password,confirmPassword} = state
  return(
    <AuthContainer>
    <Row type="flex" justify="center" align="middle" gutter={[10, 30]}>
      <Col span={24}>
        <h3 className="mr-t mr-b fontBold">Reset Your Password</h3>
        <p>Choose a new password</p>
      </Col>
      <Col xs={24} sm={22}>
        <RootForm
          schemaName={resetPassword}
          initialValues={state}
          apiCall={changePasswordRequest}
          extras={email}
          loading={data.Auth.forgotPasswordLoading}
          cb={() => {
            history.push({
              pathname: "/login",
            });
          }}
          //autoWidthSubmit
          controls={[
            {
              span: 24,
              name: "password",
              value: password,
              handleChanged: handleChanged,
              placeholder: "Password",
              Type: Input,
              border: "1px solid #383083",
            },
            {
              span: 24,
              name: "confirm_password",
              value: confirmPassword,
              handleChanged: handleChanged,
              placeholder: "Confirm Password",
              Type: Input,
              border: "1px solid #383083",
            },
          ]}
          submitButton={{
            span: 14,
            buttonText: "Reset",
            className: "mr-t-md mr-b-md bg-gradient",
          }}
        />
      </Col>
    </Row>
  </AuthContainer>
  )
}

export default ResetScreen




// --------------------------------------------

// class ResetScreen extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       email: this.props.history.location.state.email,
//       password: "",
//       confirm_password: "",
//     };
//   }

//   handleChanged = (e) => {
//     const { name, value } = e.target;
//     this.setState({
//       [name]: value,
//     });
//   };

//   render() {
//     return (
//       <AuthContainer>
//         <Row type="flex" justify="center" align="middle" gutter={[10, 30]}>
//           <Col span={24}>
//             <h3 className="mr-t mr-b fontBold">Reset Your Password</h3>
//             <p>Choose a new password</p>
//           </Col>
//           <Col xs={24} sm={22}>
//             <RootForm
//               schemaName={resetPassword}
//               initialValues={this.state}
//               apiCall={changePasswordRequest}
//               extras={this.state.email}
//               loading={this.props.Auth.forgotPasswordLoading}
//               cb={() => {
//                 this.props.history.push({
//                   pathname: "/login",
//                 });
//               }}
//               //autoWidthSubmit
//               controls={[
//                 {
//                   span: 24,
//                   name: "password",
//                   value: this.state.password,
//                   handleChanged: this.handleChanged,
//                   placeholder: "Password",
//                   Type: Input,
//                   border: "1px solid #383083",
//                 },
//                 {
//                   span: 24,
//                   name: "confirm_password",
//                   value: this.state.confirmPassword,
//                   handleChanged: this.handleChanged,
//                   placeholder: "Confirm Password",
//                   Type: Input,
//                   border: "1px solid #383083",
//                 },
//               ]}
//               submitButton={{
//                 span: 14,
//                 buttonText: "Reset",
//                 className: "mr-t-md mr-b-md bg-gradient",
//               }}
//             />
//           </Col>
//         </Row>
//       </AuthContainer>
//     );
//   }
// }

// export default connect((storeState) => ({ Auth: storeState.Auth }))(
//   ResetScreen
// );
