import { resetHeaders, privateAgent } from "../configurations/AxiosAgent";
import { store } from "../ConfigureStore";
import { loginSuccess, logoutRequest } from "../actions/Auth";

const login = (auth, cb) => {
  console.log("login in auth");
  //let auth = res.data.data;
  window.localStorage.setItem("revicebeat_adminauth", JSON.stringify(auth));
  privateAgent.defaults.headers = {
    ...privateAgent.defaults.headers,
    "x-auth-token": auth.token,
    userId: auth.userId,
  };
  // console.log("privateAgent.defaults.headers::",privateAgent.defaults.headers)
  if (cb) {
    // cb();
  }

  store.dispatch(loginSuccess(auth));
};

const logout = () => {
  localStorage.removeItem("revicebeat_adminauth");
  resetHeaders(privateAgent);
  store.dispatch(logoutRequest());
};

const isLogin = () => {
  if (localStorage.getItem("revicebeat_adminauth")) {
    //private agent headers set
    return true;
  }

  return false;
};

const redirect = (...params) => {
  //let history = store.getState().App.history;
  //console.log("HISTORY", history)
  //history.push(...params);
  // store.dispatch(redirect(url))
};

export { login, logout, isLogin, redirect };
