import {
  ADD_ACCOUNT_NAME_ERROR,
  ADD_ACCOUNT_NAME_REQUEST,
  ADD_ACCOUNT_NAME_SUCCESS,
  EDIT_ACCOUNT_NAME_REQUEST,
  EDIT_ACCOUNT_NAME_SUCCESS,
  EDIT_ACCOUNT_NAME_ERROR,
  ADD_JOB_REQUEST,
  ADD_JOB_SUCCESS,
  ADD_JOB_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  addAccNameLoading: false,
  editAccNameLoading: false,
  jobLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_ACCOUNT_NAME_REQUEST: {
      return Object.assign({}, state, { addAccNameLoading: true });
    }
    case ADD_ACCOUNT_NAME_SUCCESS: {
      return Object.assign({}, state, { addAccNameLoading: false });
    }
    case ADD_ACCOUNT_NAME_ERROR: {
      return Object.assign({}, state, { addAccNameLoading: false });
    }
    case EDIT_ACCOUNT_NAME_REQUEST: {
      return Object.assign({}, state, { editAccNameLoading: true });
    }
    case EDIT_ACCOUNT_NAME_SUCCESS: {
      return Object.assign({}, state, { editAccNameLoading: false });
    }
    case EDIT_ACCOUNT_NAME_ERROR: {
      return Object.assign({}, state, { editAccNameLoading: false });
    }
    case ADD_JOB_REQUEST: {
      return Object.assign({}, state, { jobLoading: true });
    }
    case ADD_JOB_SUCCESS: {
      return Object.assign({}, state, { jobLoading: false });
    }
    case ADD_JOB_ERROR: {
      return Object.assign({}, state, { jobLoading: false });
    }
    default: {
      return state;
    }
  }
};
