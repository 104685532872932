import {
  REQUESTS_REQUEST,
  REQUESTS_SUCCESS,
  REQUESTS_ERROR,
  REQUEST_REQUEST,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
  USER_REQUESTS_REQUEST,
  USER_REQUESTS_SUCCESS,
  USER_REQUESTS_ERROR,
  CLEAR_REQUESTS,
  CLEAR_REQUEST,
  DELETE_DELIVERY_REQUEST,
  DELETE_DELIVERY_SUCCESS,
  DELETE_DELIVERY_ERROR,
} from "../configurations/Types";

export function requestsRequest(params, clearFlag, cb) {
  return {
    type: REQUESTS_REQUEST,
    params,
    clearFlag,
    cb,
  };
}

export function requestsSuccess(payload) {
  return {
    type: REQUESTS_SUCCESS,
    payload,
  };
}

export function requestsError(error) {
  return {
    type: REQUESTS_ERROR,
    error,
  };
}

export function requestRequest(id) {
  return {
    type: REQUEST_REQUEST,
    id,
  };
}

export function requestSuccess(payload) {
  return {
    type: REQUEST_SUCCESS,
    payload,
  };
}

export function requestError(error) {
  return {
    type: REQUEST_ERROR,
    error,
  };
}

export function userRequestsRequest(params, id, clearFlag, cb) {
  return {
    type: USER_REQUESTS_REQUEST,
    params,
    id,
    clearFlag,
    cb,
  };
}

export function userRequestsSuccess(payload) {
  return {
    type: USER_REQUESTS_SUCCESS,
    payload,
  };
}

export function userRequestsError(error) {
  return {
    type: USER_REQUESTS_ERROR,
    error,
  };
}

export function clearRequests() {
  return {
    type: CLEAR_REQUESTS,
  };
}

export function clearRequest() {
  return {
    type: CLEAR_REQUEST,
  };
}

export function deleteDeliveryRequest(id) {
  return {
    type: DELETE_DELIVERY_REQUEST,
    id,
  };
}

export function deleteDeliverySuccess() {
  return {
    type: DELETE_DELIVERY_SUCCESS,
  };
}

export function deleteDeliveryError() {
  return {
    type: DELETE_DELIVERY_ERROR,
  };
}
